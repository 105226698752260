import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { FaCertificate } from "react-icons/fa";
import { BiBulb } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import ImgDemoFotoPerfil from "../../assets/img/foto-perfil-placeholder.png"
import { AppContext } from "../../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/auth";
import { Link } from "react-router-dom";
import ListagemProponenteOsc from "./ListagensGestor/ListagemProponenteOsc";
import ListagemPropostasOsc from "./ListagensGestor/ListagemPropostasOsc";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";

const MinhaAreaGestor = () => {
  const { request, data } = useApiClient();
  const { setBackgroundClassList } = useContext(AppContext);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    

  }, [request]);



  return (
    <>

<Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Área do Gestor</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column mt-5">
            <Row className="g-4 g-lg-4">
              <Col md="6" className="me-auto ms-auto text-center">
                <Link to="/minha-area/usuario">
                  <img
                    src={ImgDemoFotoPerfil}
                    alt="Foto do usuário"
                    className="img rounded-pill w-25 box-shadow"
                  />
                </Link>
                <h3>{auth.user.nome}</h3>
                <p className="mt-2 fw-bolder title-uppercase text-gray">
                  {auth.user.email}
                </p>
              </Col>
            </Row>

            <Row className="g-4 g-lg-4">
              <Col md="12" className="me-auto ms-auto text-center">
                <ul id="profile-menu" className="mt-md-3 mt-lg-3">
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/minha-area/usuario"
                    >
                      <BsFillPersonFill />{" "}
                      <span className="d-md-inline">Seus dados</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/minha-area/proposta"
                    >
                      <BiBulb />{" "}
                      <span className="d-md-inline">Nova Proposta</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/gestor/vincular/proponente"
                    >
                      < FaCertificate />{" "}
                      <span className="d-md-inline">Vincular proponente a OSC</span>
                      
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/gestor/update/osc"
                    >
                      <BsFillPersonFill />{" "}
                      <span className="d-md-inline">Alterar dados da OSC</span>
                    </Link>
                  </li>
                  <li>
                    <a
                      className="btn btn-outline  btn-outline-danger  btn-sm rounded-pill"
                      href="/"
                      onClick={() => dispatch(authActions.signout())}
                    >
                      <IoMdLogOut /> Sair
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    <ListagemProponenteOsc/>
    <ListagemPropostasOsc />
    </>
  );
};

export default MinhaAreaGestor;
