import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import SelectFileInput from "../../../components/UI/SelectFileInput/SelectFileInput";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { saveAs } from "file-saver";
import appConfig from "../../../config/app.config";

const PropostaDetalheArquivos = (props) => {
  const { id, readOnly } = props;
  const [plano, setPlano] = useState(null);
  const [opcionais, setOpcionais] = useState([]);
  const [loadingQueue, setLoadingQueue] = useState([]);
  const { request, data, errors, loading, requestId, requestExtra } =
    useApiClient();

   

  const requestListaArquivos = useCallback(() => {
    if (id) {
      request(
        apiRoutes.main.propostas.listarArquivos,
        { routeParams: { id } },
        { requestId: "lista" }
      );
    }
  }, [request, id]);

  useEffect(() => {
    requestListaArquivos(id);
  }, [requestListaArquivos, id]);

  useEffect(() => {
    if (requestId === "lista" && data) {
      setPlano((data || []).filter((item) => item.fg_plano === "S")[0]);
      setOpcionais((data || []).filter((item) => item.fg_plano === "N"));
    }
  }, [data, errors, loading, requestId]);

  useEffect(() => {
    if (requestId === "download" && data) {
      var blob = new Blob([data]);
      saveAs(blob, requestExtra.fileName);
      setLoadingQueue((curr) =>
        curr.filter((idArquivo) => idArquivo !== requestExtra.id)
      );
      return;
    }

    if (requestId === "remover-plano" && data) {
      setPlano(null);
    }

    if (requestId === "remover-opcional" && data) {
      setOpcionais((curr) =>
        curr.filter((item) => item.id_proposta_arquivo !== requestExtra)
      );
    }

    setLoadingQueue((curr) =>
      curr.filter((idArquivo) => idArquivo !== requestExtra)
    );
  }, [data, errors, loading, requestId, requestExtra]);

  const downloadFileHandler = useCallback(
    (id, fileName) => {
      setLoadingQueue((curr) => [...curr, id]);
      request(
        apiRoutes.main.propostas.download,
        {
          routeParams: { id },
        },
        { requestId: "download", requestExtra: { id, fileName } }
      );
    },
    [request]
  );

  return (
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <h3 className="my-3">Plano de Trabalho</h3>
      </Col>
      <Col lg="12" md="12" sm="12" xs="12">
        <p>
          Nesta seção você deverá baixar o plano de trabalho, clicando no botão
          "Baixar plano de trabalho", após baixar você deve preencher o plano de
          trabalho e anexar o arquivo clicando no botão "Anexar plano de
          trabalho", o preenchimento do plano de trabalho é{" "}
          <strong>OBRIGATÓRIO</strong>. Você também pode anexar até 5 arquivos
          opcionais.
        </p>

        <Button variant="link" href={`${appConfig.host}/docs/plano-de-trabalho-1-3.doc`}>
          <AiOutlineDownload /> clique aqui para baixar o plano de trabalho
        </Button>
      </Col>
      {!id && (
        <Col lg="12" md="12" sm="12" xs="12">
          <br />
          <Alert variant="primary">
            Salve um rascunho da proposta antes de anexar arquivos
          </Alert>
          <br />
        </Col>
      )}
      <Col lg="6" md="6" sm="12" xs="12" className="mt-3">
        <Form.Group controlId="formFileMultiple" className="mb-3">
          {id && <Form.Label>Plano de trabalho</Form.Label>}
          <br />
          <Row>
            <Col>
              {plano && (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Arquivo</th>
                      {!readOnly && (
                        <th style={{ textAlign: "center" }}>Remover</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        downloadFileHandler(
                          plano.id_proposta_arquivo,
                          plano.nm_arquivo
                        );
                      }}
                    >
                      <td>
                        {plano.nm_arquivo} ({plano.nu_arquivo_tamanho} bytes)
                        {readOnly && loadingQueue.find(
                            (idArquivo) =>
                              idArquivo === plano.id_proposta_arquivo
                          ) && <Spinner size="sm" animation="border" />}
                      </td>
                      {!readOnly && (
                        <td style={{ textAlign: "center" }}>
                          {loadingQueue.find(
                            (idArquivo) =>
                              idArquivo === plano.id_proposta_arquivo
                          ) ? (
                            <Spinner size="sm" animation="border" />
                          ) : (
                            <RiDeleteBin5Fill
                              onClick={() => {
                                setLoadingQueue([
                                  ...loadingQueue,
                                  plano.id_proposta_arquivo,
                                ]);
                                request(
                                  apiRoutes.main.propostas.removerArquivo,
                                  {
                                    idProposta: id,
                                    idArquivo: plano.id_proposta_arquivo,
                                  },
                                  {
                                    requestId: "remover-plano",
                                    requestExtra: plano.id_proposta_arquivo,
                                  }
                                );
                              }}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
          <SelectFileInput
            disabled={loading}
            show={!readOnly && !plano && id}
            defaultPayload={{ idProposta: id, plano: true }}
            onFinishUpload={requestListaArquivos}
          />
        </Form.Group>
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="mt-3">
        <Form.Group controlId="formFileMultiple" className="mb-3">
          {id && !!opcionais.length && <Form.Label>Arquivos Opcionais (Máx. 5)</Form.Label>}
          <br />
          <Row>
            <Col>
              {!!opcionais.length && (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Arquivo</th>
                      {!readOnly && (
                        <th style={{ textAlign: "center" }}>Remover</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {opcionais.map((item) => (
                      <tr
                        key={item.id_proposta_arquivo}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFileHandler(
                            item.id_proposta_arquivo,
                            item.nm_arquivo
                          );
                        }}
                      >
                        <td>
                          {item.nm_arquivo} ({item.nu_arquivo_tamanho} bytes){" "}
                          {readOnly && loadingQueue.find(
                            (idArquivo) =>
                              idArquivo === item.id_proposta_arquivo
                          ) && <Spinner size="sm" animation="border" />}
                        </td>
                        {!readOnly && (
                          <td style={{ textAlign: "center" }}>
                            {loadingQueue.find(
                              (idArquivo) =>
                                idArquivo === item.id_proposta_arquivo
                            ) ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              <RiDeleteBin5Fill
                                onClick={() => {
                                  setLoadingQueue([
                                    ...loadingQueue,
                                    item.id_proposta_arquivo,
                                  ]);
                                  request(
                                    apiRoutes.main.propostas.removerArquivo,
                                    {
                                      idProposta: id,
                                      idArquivo: item.id_proposta_arquivo,
                                    },
                                    {
                                      requestId: "remover-opcional",
                                      requestExtra: item.id_proposta_arquivo,
                                    }
                                  );
                                }}
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
          <SelectFileInput
            disabled={loading}
            show={!readOnly && opcionais.length < 5 && id}
            defaultPayload={{ idProposta: id, plano: false }}
            onFinishUpload={requestListaArquivos}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PropostaDetalheArquivos;
