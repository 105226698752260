/**
 * Configurações do ambiente de produção
 * yarn build:prod
 */
const prodConfig = {
  env: "prod",
  host: "https://api.portalosc.sethas.rn.gov.br",

  

  
};

export default prodConfig;
