import React, { useContext, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import SpinnerLabel from "../components/UI/SpinnerLabel";

const validationSchema = yup.object().shape({
  novaSenha: yup
    .string()
    .min(6, "Informe a senha com no mínimo 6 caracteres")
    .required("Informe a nova senha"),
  confirmeSenha: yup
    .string()
    .oneOf(
      [yup.ref("novaSenha"), null],
      "As senhas não coincidem, digite novamente."
    )
    .required("Informe a confirmação de sua nova senha novamente."),
});

const initialValues = {
  novaSenha: "",
  confirmeSenha: "",
};

const RedefinirSenhaUsuario = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const params = useParams();

  const { request, data, errors, loading } = useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.usuario.redefinicao, {
        payload: params.token,
        ...values,
      });
    },
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    setBackgroundClassList(["bg-half", "background-1"]);
  });

  useEffect(() => {
    if (data) {
      alert("Senha alterada com sucesso!!!");
      history.push("/login");
    }
    if (errors) {
      alert(
        errors.message || "Oops, algo deu errado, tente novamente mais tarde."
      );
    }

    setSubmitting(false);
  }, [data, errors, setSubmitting, history]);

  const readOnly = loading;

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Redefinir Senha
            </h1>
          </Col>
        </Row>
      </Container>
      <div id="conteudo-pagina-w100">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="col-md-6 p-2">
                <Form>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      type="password"
                      field="novaSenha"
                      label="Nova Senha:"
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      type="password"
                      field="confirmeSenha"
                      label="Confirme sua nova senha:"
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
                    <Button
                      disabled={readOnly}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      <SpinnerLabel
                        loading={formik.isSubmitting}
                        label="Redefinir Senha"
                      />
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RedefinirSenhaUsuario;
