import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";

const Legislacao = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-2"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Legislação</h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row className="text-center mt-4 mb-5 g-4 g-lg-4 ">
          <Col lg="6" md="6" sm="12" xs="12">
            <div className="bg-light position-relative mt-5 p-3 h-100 ">
              <h4 className="info-title">Lei Federal N° 13.019/2014</h4>
              <h6 className="mt-3">DE 31 DE JULHO DE 2014</h6>
              <p className="description mt-3 text-justify">
                Responsável por regular as parcerias entre a Administração Pública e as Organizações da Sociedade Civil
                (OSC). Ela irá estabelecer diretrizes sobre como deve ser tal relação jurídica, principalmente, nos
                casos em que haverá a transferência de recursos para a execução de projetos de interesse público.
              </p>

              <Button
                className="btn btn-sm w-75 mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"
                target="_blank"
                href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2014/lei/L13019compilado.htm"
              >
                Veja a Lei completa{" "}
              </Button>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div className="bg-light position-relative mt-5 p-3 h-100">
              <h4 className="info-title">Decreto Federal Nº 8.726/2016</h4>
              <h6 className="mt-3">DE 27 DE ABRIL DE 2016</h6>
              <p className="description mt-3 text-justify">
                Regulamenta a Lei Federal nº 13.019, de 31 de julho de 2014, para dispor sobre regras e procedimentos do regime
                jurídico das parcerias celebradas entre a administração pública federal e as organizações da sociedade
                civil.
              </p>

              <Button
                className="btn btn-sm w-75 mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"
                target="_blank"
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/decreto/d8726.htm"
              >
                Veja o Decreto completo
              </Button>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div className="bg-light position-relative mt-5 p-3 h-100">
              <h4 className="info-title">Lei Federal N° 13.204/2015</h4>
              <h6 className="mt-3">DE 14 DE DEZEMBRO DE 2015</h6>
              <p className="description mt-3 text-justify">
                Altera a redação da Lei Federal 13.019/2014 - Marco Regulatório das Organizações da Sociedade Civil (MROSC),
                modificando a mesma e fazendo constar: a inclusão de organizações religiosas e cooperativas como OSC; a
                criação do Acordo de Cooperação para as parcerias que não envolvam recursos públicos; a composição das
                Comissões de Seleção e de Monitoramento e Avaliação; a ampliação do rol de parcerias não sujeitas às
                determinações da Lei Federal nº 13.019/2014; a modificação dos critérios para atuação em rede; a dispensa de
                chamamento público para a escolha da entidade para parcerias com recursos oriundos de emendas
                parlamentares; a dispensa de chamamento público para as parcerias objetivando atividades voltadas aos
                serviços de educação, saúde e assistência social, executados por organizações previamente credenciadas
                entre outras disposições.
              </p>

              <Button
                className="btn btn-sm w-75 mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"
                target="_blank"
                href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2015/lei/l13204.htm"
              >
                Veja a Lei completa{" "}
              </Button>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
          <div className="bg-light position-relative mt-5 p-3 h-100">
          <h4 className="info-title">Decreto Estadual Nº 31.067</h4>
            <h6 className="mt-3">
              DE 09 DE NOVEMBRO DE 2021.
            </h6>
            <p className="description mt-3 text-justify">
                Regulamenta a aplicação, no âmbito do Poder Executivo Estadual, da Lei Federal nº 13.019, de 31 de julho de 2014, dispondo sobre regras e 
                procedimentos do regime jurídico das parcerias celebradas entre a Administração Pública Estadual e as organizações da sociedade civil, institui 
                a Política Estadual de Fomento e Colaboração com as Organizações da Sociedade Civil do Rio Grande do Norte (PEFOCO/RN) e dá outras providências.
            </p> 

            <Button 
            className="btn btn-sm w-75 mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"  
            target="_blank" 
            href="http://diariooficial.rn.gov.br/dei/dorn3/docview.aspx?id_jor=00000001&data=20211110&id_doc=746095">
              Veja a Lei completa{" "}
            </Button> 
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Legislacao;
