import axios from "axios";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { RiSearch2Fill } from "react-icons/ri";

const ViaCepLoader = (props) => {
  const [buscandoEndereco, setBuscandoEndereco] = useState(false);
  const { onFound, disabled, cep } = props;

  return buscandoEndereco ? (
    <Spinner animation="border" />
  ) : (
    <Button
      disabled={disabled}
      onClick={() => {
        setBuscandoEndereco(true);
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => {
            const { uf } = response.data;

            setBuscandoEndereco(false);

            if (uf !== "RN") {
              setTimeout(
                () => alert("Apenas as OSC e responsáveis com domicílio no RN podem participar. Verifique o CEP informado."),
                500
              );
              return;
            }

            onFound(response.data);
          })
          .catch((e) => {
            console.error(e);
            setBuscandoEndereco(false);
          });
      }}
    >
      <RiSearch2Fill /> Buscar Endereço
    </Button>
  );
};

export default ViaCepLoader;
