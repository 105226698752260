import { Container } from "react-bootstrap";
import TopMenu from "./TopMenu";

const Header = (props) => {
  return (
    <Container>
      <TopMenu {...props} />
    </Container>
  );
};

export default Header;
