import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const NotFound = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1", "bg-cover"]);
  });
  return (
    <Container fluid='md'>
      <Row>
        <Col>
          <div className='card'>
            <div className='card-header'>404</div>
            <div className='card-body'>
              <h5 className='card-title'>Página em Construção</h5>
              <p className='card-text'>
                A página que você solicitou ainda não existe
              </p>
              <LinkContainer to='/'>
                <button className='btn btn-primary'>Ir para o Início</button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default NotFound