import React, { useEffect, useState  } from "react";
import { Button, Col, Container, Form, Row} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";




const validationSchema = yup.object().shape({
  nomeResponsavel: yup.string().matches(""),
  cpf: yup.string().matches("[0-9]{11}", "Somente números. 11 dígitos."),
  cnpj: yup.string().matches("[0-9]{14}", "Somente números. 14 dígitos."),
});

const initialValues = {
    nomeResponsavel: "",
    cpf: "",
    cnpj: "",
};



const ListagemCredenciamentoOsc = (props) => {
    const history = useHistory();
    const { request, data } = useApiClient();
    var  idUsuario;
    


    if(props){
      idUsuario = props.props;
    }else{
      idUsuario = null;
    }

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
       
      },
    });


    useEffect(() => {
      request(apiRoutes.main.credenciamento.listarCredenciamentos, {  routeParams:  {idUsuario}  })
    }, [request, props, idUsuario]);



  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <Row>
          <Col >
            <h2 className="mt-4">Pedidos de Credenciamento</h2>
         
          </Col>
        </Row>
       
         
            <Row>
              <Col>
                <p>
                  Aqui são listados  todos os pedidos de credenciamento das OSCs.
                </p>
              </Col>
            </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="nomeResponsavel"
                    label="Nome do Responsável"
                    
                  />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    maxLength={11} 
                  />
                </Col>
                  <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="cnpj"
                    label="CNPJ:"
                    maxLength={11} 
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link"className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table striped bordered hover className="mt-4">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome do Responsável</th>
                    <th>CPF do Responsável</th>
                    <th>Razão Social da Osc</th>
                    <th>CNPJ</th>
                    <th>Situação</th>
                    
                  </tr>
                  </thead>
                  <tbody>
                  {(data || {}).length &&
                    data.map((itemData) => (
                      <tr  
                        key={itemData.id_usuario}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                                history.push(
                                  `/coordenador/credenciamento/osc/${itemData.id_credenciamento}`
                                );
                          
                        }}>
                        <td> {itemData.id_usuario}</td>
                        <td> {itemData.tx_nome}</td>
                        <td> {itemData.tx_cpf}</td>
                        <td> {itemData.razaosocialosc} </td>
                        <td> {itemData.cnpjosc} </td>
                        <td> {itemData.status}</td>
                        <td></td>
                      </tr>))}

                  </tbody>
                  </Table>
              </Col>
            </Row>
      </Container>
    </>
  );
};

export default ListagemCredenciamentoOsc;