import appConfig from "../config/app.config";

/**
 * Rotas para os serviços das APIs consumidas pela aplicação
 */
const apiRoutes = {
  main: {
    auth: {
      __route: "/api/auth",
      signInBasic: { method: "POST", url: "/signin" },
    },
    cache: {
      __route: "/api/cache",
      areas: { method: "GET", url: "/areas" },
      orgaos: { method: "GET", url: "/orgaos" },
    },
    usuario: {
      __route: "/api/usuario",
      usuarioLogado: { method: "GET", url: "" },
      salvar: { method: "POST", url: "/salvar" },
      criar: { method: "POST", url: "/criar" },
      ativar: { method: "POST", url: "/ativar" },
      solicitar: { method: "POST", url: "/redefinicao/solicitar" },
      redefinicao: { method: "POST", url: "/redefinicao" },
    },

    admin: {
      __route: "/api/usuario",
      criarCoordenador: { method: "POST", url: "/admin/criar/coordenador" },
      listarCordenadores: { method: "GET", url: "/admin/listar/coordenadores" },
      removerCoordenador: {
        method: "POST",
        url: "/admin/remover/coordenador/:id",
      },
    },

    coordenador: {
      __route: "/api/coordenador",
      criarServidor: { method: "POST", url: "/criar/servidor" },
      listarServidor: {
        method: "GET",
        url: "/listar/servidores/:idsecretaria",
      },
      removerServidor: { method: "POST", url: "/remover/servidor/:id" },
    },

    UserGov: {
      __route: "/api/usuariogov",
      CarregarUserGovLogado: { method: "GET", url: "/logado" },
      SalvarUserGovUpdate: { method: "POST", url: "/update" },
    },

    Osc: {
      __route: "/api/osc",
      ListarOscs: { method: "GET", url: "/listar" },
    },

    revisor: {},

    gestorOSC: {
      __route: "/api/gestorosc",
      ListarProponentesDaOsc: {
        method: "POST",
        url: "/listar/proponentes/osc/:id",
      },
      ListarPropostasDaOsc: { method: "GET", url: "/listar/propostas/osc/:id" },
      CarregarOsc: { method: "GET", url: "/carregar/dados/osc" },
      UpdateOsc: { method: "POST", url: "/update/osc" },
      PesquisarProponente: { method: "POST", url: "/pesquisar/cpf" },
      VincularProponente: { method: "POST", url: "/vincular/proponente/:id" },
      DesvincularProponente: {
        method: "POST",
        url: "/desvincular/proponente/:id",
      },
    },

    credenciamento: {
      __route: "/api/credenciamento",
      AndamentoCredenciamento: { method: "POST", url: "/andamento" },
      listarCredenciamentos: {
        method: "POST",
        url: "/carregar/credenciamentos/:idUsuario",
      },
      CarregarCredenciamentoPorId: {
        method: "POST",
        url: "/carregar/credenciamento/:idCredenciamento",
      },
    },

    publicacoes: {
      __route: "/api/publicacao",
      recentes: { method: "GET", url: "/recentes" },
      pesquisar: { method: "GET", url: "/pesquisar" },
    },
    propostas: {
      __route: "/api/proposta",
      listarDoUsuario: { method: "GET", url: "/listar" },
      porIdAuth: { method: "GET", url: "/auth/:id" },
      pesquisar: { method: "GET", url: "/pesquisar" },
      listarArquivos: { method: "GET", url: "/arquivos/:id" },
      removerArquivo: { method: "POST", url: "/remover-arquivo" },
      upload: { method: "POST", url: "/upload" },
      download: {
        method: "GET",
        url: "/arquivo/download/:id",
        responseType: "blob",
      },
      salvar: { method: "POST", url: "" },
      submeter: { method: "POST", url: "/submeter" },
      propostaDarAndamento: { method: "POST", url: "proposta/dar-andamento" },
    },
    servidor: {
      __route: "/api/servidor",
      uploadTermosAcordos: { method: "POST", url: "/upload" },
      removerTermo: { method: "POST", url: "/remover/termo/:idTermo" },
      listarAndamento: { method: "GET", url: "/listar/andamento" },
      updateAndamento: { method: "POST", url: "/update/andamento"},
      listarTermosAcordosPorId: { method: "GET", url: "/listar/termoPorId/:id" },

    },
    chamamentoPublico: {
      __route: "/api/chamamentopublico",
      uploadChamamentoPublico: { method: "POST", url: "/upload" },
      listarChamamento:{method:"GET", url:"/listar/todos"},
      removerChamamento:{method:"POST",url:"/remover/chamamento/:idChamamento"},
    },

    termosAcordos: {
      __route: "/api/termosacordos",
      listarTermosAcordos: { method: "GET", url: "/listar/termos" },
      download: {
        method: "GET",
        url: "/baixar/termos/1",
        responseType: "blob",
        type: "blob",
      },
    },
  },
};

const prepare = (routes) => {
  for (let host in routes) {
    for (let controller in routes[host]) {
      for (let action in routes[host][controller]) {
        if (action === "__route") continue;

        routes[host][controller][
          action
        ].url = `${appConfig.host}${routes[host][controller].__route}${routes[host][controller][action].url}`;
      }
    }
  }

  return routes;
};

const preparedRoutes = prepare(apiRoutes);

export default preparedRoutes;
