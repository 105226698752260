import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { AppContext } from "../context/AppContext";

const Contato = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Contato
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
              <p className="text-justify mt-5">Ficou com dúvidas? Quer mandar uma questão ou simplesmente entrar em contato com a gente?</p>

              <p  className="text-justify">Envie sua mensagem abaixo e será um prazer atender-lhe:</p>
          </Col>
        </Row>
      </Container>


      <div id="conteudo-pagina-w100" className="bg-cleardark mt-5">
        <Container>
          <Row>
            <Col md="8" className="ms-auto me-auto">
              <h2 className="text-center">Envie sua mensagem</h2>
              <form className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <label>Nome</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <BsFillPersonFill />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control input-outline"
                        placeholder="Nome"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>E-mail</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MdEmail />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control input-outline"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                </div>
                <label className="mt-3">Mensagem</label>
                <textarea
                  className="form-control input-outline"
                  rows="4"
                  placeholder="Conte-nos sobre suas ideias e pensamentos..."
                ></textarea>
                <div className="row">
                  <div className="text-center mt-4">
                    <button className="btn btn-primary btn-md p-3">
                      Enviar mensagem
                    </button>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contato;
