import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { AiFillHome } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import ImgMarca from "../assets/img/marca-brasao-rn.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";



const TopMenu = () => {


  const papeis = useSelector((state) => state.auth);
  let logadoAuthenticated;
  // try{
  //   logadoAuthenticated = JSON.parse(sessionStorage.getItem("user")).isAuthenticated 
  // }catch{

  //   logadoAuthenticated = null;
  // }
  // const isAuthenticated = useSelector((state) => logadoAuthenticated==null ?  state.auth.isAuthenticated: JSON.parse(sessionStorage.getItem("user")).isAuthenticated);
  // const papeis = useSelector((state) =>  logadoAuthenticated==null ? state.auth:JSON.parse(sessionStorage.getItem("user")).user.papeis[0]); 
  const [isServidor, setIsServidor] = useState(false);
  const [isProponente, setIsProponente] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCoordenador, setIsCoordenado] = useState(false);
  const [isGestorOsc, setIsGestorOsc] = useState(false);
  
 

  useEffect(() => {
    if(papeis.isAuthenticated === true) {
      if(papeis.user.papeis[0] === "SERVIDOR"){
        setIsServidor(true);
      }
      else if(papeis.user.papeis[0] === "PROPONENTE" ){
        setIsProponente(true);
      }
      else if(papeis.user.papeis[0] === "COORDENADOR" ){
        setIsCoordenado(true);
      }
      else if(papeis.user.papeis[0] === "SU"){
        setIsAdmin(true);
      }else if(papeis.user.papeis[0] === "GESTOR-OSC" ){
        setIsGestorOsc(true);
      }
    }else{
      setIsServidor(false);
      setIsProponente(false);
      setIsAdmin(false);
      setIsCoordenado(false);
      setIsGestorOsc(false);
    }
  },[papeis, papeis.isAuthenticated ])
  
  return (
    <>
      <Navbar variant="dark" expand="lg" collapseOnSelect>
        <LinkContainer to="/">
          <Navbar.Brand>
            <>
              <div className="navbar-logo">
                <img src={ImgMarca} alt="OSC RN" /> Portal OSC RN
              </div>
            </>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-menu" />

        <Navbar.Collapse id="navbar-menu" className="justify-content-end">
          <Nav id="navbar-menu-nav">
            <LinkContainer to="/" exact>
              <Nav.Link className="text-shadow">
                <AiFillHome /> <span>Início</span>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/legislacao">
              <Nav.Link className="text-shadow">
                {" "}
                <span>Legislação</span>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/publicacoes">
              <NavDropdown
                title={
                  <>
                    {" "}
                    <span className="text-shadow">Publicações</span>
                  </>
                }
              >
                <LinkContainer to="/publicacoes/chamamentos">
                  <NavDropdown.Item>Chamamentos Públicos</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/publicacoes/interesse-social">
                  <NavDropdown.Item>Manifestacoes de Interesse Sociais</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </LinkContainer>

            <LinkContainer to="/parcerias">
              <NavDropdown
                title={
                  <>
                    {" "}
                    <span className="text-shadow">OSC e Parcerias</span>
                  </>
                }
              >
                <LinkContainer to="/parcerias/termos-e-acordos">
                  <NavDropdown.Item>Termos e Acordos</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/osc/manuntencao" exact>
                  <NavDropdown.Item>OSC Credenciadas</NavDropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to="/none2" exact>
                  <NavDropdown.Item>Parcerias Firmadas</NavDropdown.Item>
                </LinkContainer> */}
              </NavDropdown>
            </LinkContainer>

            <LinkContainer to="/perguntas-frequentes">
              <Nav.Link className="text-shadow">
                {" "}
                <span>Perguntas Frequentes</span>
              </Nav.Link>
            </LinkContainer>
            

            <LinkContainer to="/contato">
              <Nav.Link className="text-shadow">
                {" "}
                <span>Contato</span>
              </Nav.Link>
            </LinkContainer>

           
                
            {isServidor && (
              <LinkContainer to="/minha-area-servidor">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Minha Área</span>
                </Nav.Link>
              </LinkContainer>
            )}

            {isAdmin && (
              <LinkContainer to="/minha-area-admin">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Minha Área</span>
                </Nav.Link>
              </LinkContainer>
            )}

            {isProponente && (
              <LinkContainer to="/minha-area">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Minha Área</span>
                </Nav.Link>
              </LinkContainer>
            )}

            {isCoordenador && (
              <LinkContainer to="/minha-area-coordenador">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Minha Área</span>
                </Nav.Link>
              </LinkContainer>
            )}

            {isGestorOsc && (
              <LinkContainer to="/minha-area-gestor">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Minha Área</span>
                </Nav.Link>
              </LinkContainer>
            )}

          {!papeis.isAuthenticated &&(
            <LinkContainer to="/osc/manuntencao">
                <Nav.Link className="bg-primary text-white rounded-pill opacity-1 px-3">
                  <FaUser /> <span>Entrar</span>
                </Nav.Link>
            </LinkContainer>
          )}
           
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default TopMenu;
