import React, { useContext, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Accordion,
  } from "react-bootstrap";
  
import { AppContext } from "../context/AppContext";




const PaginaFaq = () =>{
    const { setBackgroundClassList } = useContext(AppContext);

    useEffect(() => {
        setBackgroundClassList(["background-1"]);
      });



    return(
        <>  
            <Container id="cabecalho-pagina">
                <Row>
                    <Col className="d-flex flex-column text-center mt-5">
                        <h1 className="text-white text-shadow-orange mt-5">
                            Perguntas   Frequentes
                        </h1>
                    </Col>
                </Row>
            </Container>


            <Container  id="conteudo-pagina-interna">
                 <Row>
                    <Col className="d-flex flex-column text-center mt-3">
                        <h3 className=" mt-5">
                            Aqui estão listadas as perguntas mais frequentes feitas no portal.
                        </h3>
                    </Col>
                </Row>



                <Row>
                    <Col className="d-flex flex-column">
                        <Accordion className="mt-5">
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="0"
                                    className="w-100 text-left"
                                >
                                    Por que foi criada a Lei nº 13.019, de 31 de julho de 2014?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        Os debates sobre a elaboração da Lei nº 13.019 de 31 de julho de 2014 se deram no contexto
                                        da agenda do Marco Regulatório das Organizações da Sociedade Civil (MROSC), coordenada
                                        pelo Governo Federal, atendendo também a demandas apresentadas por diversas organizações,
                                        coletivos, redes e movimentos sociais, que reivindicavam a valorização e reconhecimento de seu
                                        trabalho, bem como o aprimoramento da relação com a Administração Pública.      
                                    </p>

                                    <p className="text-justify  mt-1">
                                    A agenda política do MROSC é bem ampla e está estruturada em três eixos, quais sejam:
                                    contratualização com o poder público (que tem como enfoque a implementação da Lei nº 13.019,
                                    de 2014); sustentabilidade e certificação; e, por fim conhecimento e gestão de informações.
                                    </p>

                                    <p className="text-justify  mt-1">
                                    A Lei nº 13.019, de 2014, também é conhecida como “Lei de Fomento e de Colaboração”,
                                    estabelece o regime jurídico das parcerias entre a administração pública e as organizações da
                                    sociedade civil. Foi criada, especialmente, frente à necessidade de o Estado Brasileiro
                                    aperfeiçoar o ambiente jurídico e institucional relativo às suas parcerias com as organizações da
                                    sociedade civil (OSCs).
                                    </p>
                                    
                                    <p>
                                    Podemos destacar como contribuição principal do MROSC a de combinar a valorização das
                                    organizações da sociedade civil com a transparência na aplicação dos recursos públicos.
                                    </p>

                                    <p>
                                    A Lei de Fomento e de Colaboração procurou qualificar os instrumentos e práticas de celebração
                                    de acordos entre o poder público e as organizações da sociedade civil, garantindo a sua
                                    incorporação no ciclo de políticas públicas, reconhecendo o seu protagonismo ao longo da
                                    história brasileira, respeitados os princípios da democracia participativa e o dever de prestação
                                    de contas do Estado. 
                                    </p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="1"
                                    className="w-100 text-left"
                                >
                                    O novo modelo de parcerias – Termo de Fomento e de Colaboração – proposto pela Lei
                                    nº 13.019, de 2014, prejudica a continuidade de oferta dos serviços socioassistenciais?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                    A Lei de Fomento e de Colaboração institui normas gerais para as parcerias entre a
                                    Administração Pública – direta e indireta da União, dos estados, dos municípios e do Distrito
                                    Federal – e as OSCs. Embora tenha caráter geral, sua aplicação deve observar também as
                                    normas específicas das políticas públicas setoriais, a exemplo da política de assistência social,
                                    conforme prevê o art. 2º-A:{" "}
                                    </p>

                                        <p className="ms-5"> 
                                            <em>
                                                Art. 2o-A. As parcerias disciplinadas nesta Lei respeitarão, em todos os
                                                seus aspectos, <b>as normas específicas das políticas públicas
                                                setoriais relativas ao objeto da parceria e as respectivas
                                                instâncias de pactuação e deliberação.</b> {" "}
                                            </em>
                                        </p>
                                      
                                   

                                    <p className="text-justify  mt-4">
                                    Assim, o modelo de parcerias proposto pela Lei nº 13.019, de 2014, não contraria as normativas
                                    vigentes no âmbito do Sistema Único de Assistência Social – SUAS. Ao contrário, a nova lei
                                    naugura um relacionamento público-privado fundado em condições legalmente estabelecidas,
                                    pautadas em objetivos coletivos, na construção participativa e no reconhecimento do papel das
                                    organizações como parcerias complementares da atuação estatal.  
                                    </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="2"
                                    className="w-100 text-left"
                                >
                                    Qual é o papel dos conselhos (municipais e do Distrito Federal) de assistência social no âmbito da nova Lei?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        O papel dos conselhos de assistência social deve ser compreendido à luz da Lei Orgânica de
                                        Assistência Social – LOAS, Lei nº 8.742, de 7 de dezembro de 1993, combinada com a Lei nº
                                        13.019, de 2014.

                                    </p>
                                    <p className="text-justify  mt-2">
                                    A Lei nº 13.019, de 2014, inova ao trazer no parágrafo único do art. 16 a possibilidade dos
                                    conselhos de políticas públicas apresentarem propostas à administração pública para celebração
                                    de termo de colaboração com organizações da sociedade civil.
                                    </p>

                                    <p className="text-justify  mt-2">
                                    Importante, esclarecer que as propostas apresentadas pelos conselhos não vinculam a
                                    administração pública, contudo empoderam o controle social quando no exercício da
                                    competência de acompanhamento da execução da política de assistência social. Nesse sentido,
                                    os conselhos poderão provocar a Administração Pública apresentando propostas de atividades
                                    que avaliam ser relevantes para serem exercidas e executadas em regime de colaboração com
                                    as OSCs.
                                    </p>
                                    
                                    <p className="text-justify  mt-2">
                                    Na sequência, o MROSC, reafirma em seu art. 60 o papel de fiscalização e acompanhamento
                                    dos conselhos de políticas públicas que se conforma com as definições da LOAS para os
                                    conselhos de assistência social.
                                    </p>

                                    <p className="text-justify  mt-2">
                                    Além disso, faz-se necessário evidenciar que, fundado nos dispositivos 2º-A e no inciso VI do art.
                                    30 da Lei nº 13.019, de 2014, o Conselho Nacional de Assistência Social – CNAS deliberou
                                    requisitos por meio da Resolução nº 21, de 24 novembro de 2016, para serem observados pelas
                                    entidades ou organizações de assistência social quando da celebração das parcerias no SUAS,
                                    quais sejam:
                                    </p>

                                    <p  className="text-justify  mt-2 ms-5">
                                        <ol className="ms-3" style={{ listStyleType: "upper-roman" }}>
                                            <li className="mt-1">
                                               Ser constituída em conformidade com o disposto no art. 3° da Lei nº 8.742, de 7 dedezembro de 1993;
                                            </li>
                                            < li className="mt-1">
                                               estar inscrita no respectivo conselho municipal de assistência social ou no conselho de
                                                assistência social do Distrito Federal, na forma do art. 9° da Lei nº 8.742, de 1993; 
                                            </li>
                                            
                                            <li className="mt-1">
                                               estar cadastrada no Cadastro Nacional de Entidades de Assistência Social – CNEAS, na
                                                forma estabelecida pelo Ministério do Desenvolvimento Social e Agrário. 
                                            </li>
                                        </ol>
                                    </p>

                                    <p>
                                         Nesse contexto, caberá aos conselhos de assistência social, sem prejuízo demais atribuições:
                                    </p>

                                    <p  className="text-justify  mt-2 ms-5">
                                        <ol  className="ms-3" style={{ listStyleType: "upper-roman" }}>
                                            <li className="mt-1">
                                             Realizar inscrição das entidades e organizações de assistência social, bem como dos
                                                serviços, programas, projetos e benefícios socioassistenciais por elas prestados, autorizando-as
                                                a funcionar no âmbito da Política Nacional de Assistência Social e, portanto, a formalizar
                                                parcerias com a Administração Pública nessa área;
                                            </li>
                                            < li className="mt-2">
                                                Acompanhar e fiscalizar:
                                                    <ol className="mt-2 ms-5" style={{ listStyleType: "upper-roman" }}>
                                                        <li>
                                                          As entidades ou organizações de assistência social e o conjunto de ofertas inscritas, de
                                                            forma planejada, por meio de visitas, elaboração de pareceres, deliberação coletiva,
                                                            publicização de decisões, promoção de audiências públicas, dentre outras ações. 
                                                        </li>
                                                        <li className="mt-1">
                                                          a conformidade da parceria entre a entidade ou organização de assistência social e a
                                                             Administração Pública com as normativas do SUAS; 
                                                        </li>
                                                    </ol>
                                            </li>
                                        </ol>
                                    </p>
                                   
                                    <p>
                                        Por fim, é importante esclarecer que os conselhos de assistência social, diferentemente de
                                        conselhos gestores de fundos específicos, não provocam ou participam do procedimento do
                                        chamamento público que se constitui atribuição do órgão gestor da assistência social.     
                                    </p>


                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="3"
                                    className="w-100 text-left"
                                >
                                   O que é o Conselho Nacional de Fomento e Colaboração – CONFOCO e qual a sua relação com o Conselho Nacional de Assistência Social?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <p  className="text-justify  mt-2">
                                        Previsto no art. 15 da Lei nº 13.019, de 2014, e criado pelo art. 83 do Decreto nº 8.726, de 2016,
                                        o Conselho Nacional de Fomento e Colaboração-Confoco tem o potencial de se tornar o lócus
                                        institucional dessa agenda, para que sejam formuladas e divulgadas boas práticas da relação
                                        entre a administração pública e as OSCs, de maneira a gerar conhecimento e fortalecer o
                                        diálogo entre os atores envolvidos.
                                    </p>

                                    <p  className="text-justify  mt-2">
                                        De natureza consultiva e composição paritária entre órgãos da administração pública, dentre os
                                        quai so MDSA, e representantes de OSCs, redes e movimentos sociais, o Confoco é integrante
                                        da estrutura do Ministério do Planejamento, Orçamento e Gestão. Dentre suas competências,
                                        destacam-se o monitoramento, avaliação da implementação, e a proposição de diretrizes e
                                        ações para a efetivação da Lei nº 13.019, de 2014.
                                    </p>

                                    <p  className="text-justify  mt-2">
                                        Cabe destacar que a Lei nº 13.019, de 2014, prevê a criação de instâncias participativas, como o
                                        conselho de fomento e colaboração, em níveis estadual, distrital e municipal. E que estas
                                        instâncias não sobrepõem competências aos conselhos setoriais de políticas públicas, a
                                        exemplo dos conselhos de assistência social, devendo o Confoco realizar consulta a estes
                                        conselhos quanto às políticas e ações voltadas ao fortalecimento das relações de fomento e de
                                        colaboração propostas.
                                    </p>

                                    <p  className="text-justify  mt-2">
                                        Por fim, vale frisar a natureza transversal dos conselhos de fomento e colaboração que tem
                                        objetivo de apoiar políticas e ações voltadas ao fortalecimento das relações de fomento e de
                                        colaboração que perpassam as diversas políticas setorias que realizam parcerias com as OSCs.
                                    </p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="4"
                                    className="w-100 text-left"
                                >
                                        Para a aplicação da nova Lei, quais são as principais ações que o gestor municipal e
                                        estadual de assistência social deve realizar?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                
                                <p  className="text-justify  mt-2">
                                    É importante esclarecer que a Lei nº 13.019, de 2014, entrará em vigor em diferentes momentos
                                    para os entes federados. Para União, estados e Distrito Federal já esta em vigor desde
                                    23/01/2016 e para os municípios a partir de 01/01/2017.
                                </p>
                                        
                                <p  className="text-justify  mt-2">          
                                    Nessa perspectiva, o plano de ação para implementação da Lei deverá abranger medidas de
                                    curto, médio e longo prazo.
                                </p >

                                <p  className="text-justify  mt-2">
                                    A curto prazo os gestores de assistência social deverão realizar o levantamento dos convênios
                                    com entidades ou organizações de assistência social ainda vigentes; avaliar a necessidade de 
                                    prorrogação de cada parceria, conforme as regras de transição; realizar capacitação de
                                    multiplicadores acerca do MROSC para agentes públicos e integrantes de OSCs, responsáveis
                                    pela seleção, celebração, acompanhamento e prestação de contas das parcerias.
                                </p>

                                <p  className="text-justify  mt-2">
                                    A médio prazo é necessário envidar esforços para regulamentar em âmbito local a Lei nº 13.019,
                                    de 2014, e na sequência desencadear ações de: adaptação de demais normativos ou manuais
                                    existentes; elaboração de modelos dos instrumentos jurídicos de acordo com a nova legislação -
                                    editais de chamamento e termos de colaboração e fomento; elaboração de orientações objetivas
                                    e simplificadas; instituição e designação das comissões permanentes: seleção e monitoramento
                                    e avaliação.
                                </p>

                                <p  className="text-justify  mt-2">
                                    Em ato contínuo, mas na perspectiva a longo prazo, promover a adaptação ou desenvolvimento
                                    dos sistemas de gerenciamento e transparência das parcerias a fim de atender o preceito do art.
                                    65 da Lei “A prestação de contas e todos os atos que dela decorram dar-se-ão em plataforma
                                    eletrônica, permitindo a visualização por qualquer interessado”.
                                    Contudo, quanto a este último aspecto, se faz necessário destacar o inciso II do art. 81-A que
                                    excepcionaliza a utilização de plataforma eletrônica para os Municípios de até cem mil
                                    habitantes.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="5"
                                    className="w-100 text-left"
                                >
                                   Qual é o papel das entidades de assistência social no âmbito da nova Lei?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                <p className="text-justify  mt-2"> 
                                    O novo Marco Regulatório traz maior segurança jurídica para as entidades e organizações de
                                    assistência social que passam a contar com uma única norma estruturante, aplicável às suas
                                    relações de parceria com os diversos órgãos e entidades da administração pública federal,
                                    distrital, estadual e municipal.
                                </p>

                                <p className="text-justify  mt-2">
                                    No âmbito da nova Lei das parcerias entre a administração pública e OSCs algumas alterações
                                    são propostas para que as entidades ou organizações de assistência social celebrem parcerias,
                                    como a necessidade de agir com mais planejamento; de comprovar experiências prévias na
                                    atividade que pretendem realizar, de possuir capacidade técnica e operacional e comprovar sua
                                    regularidade jurídica e fiscal.
                                </p>

                                <p className="text-justify  mt-2">
                                    A exigência de reconhecimento no âmbito da política de assistência social se dará pela inscrição
                                    no conselho de assistência social, seguida pelo preenchimento do Cadastro Nacional de
                                    Entidades de Assistência Social – CNEAS, pelo órgão gestor da política no município. Nesse
                                    aspecto, a entidade deverá buscar o conselho para realizar a inscrição e receber o órgão gestor
                                    para a visita técnica e preenchimento dos questionários do CNEAS.
                                </p>

                                <p className="text-justify  mt-2">
                                    Ressalta-se que no âmbito da Lei nº 13.019, de 2014, as entidades e organizações da sociedade
                                    civil adquirem um papel de maior protagonismo e participação na política de assistência social,
                                    sobretudo com a possibilidade de utilização do instrumento termo de fomento, pelo qual as
                                    entidades podem apresentar propostas para a celebração de parcerias com o poder público.
                                    Permitindo com isso, a inovação das ofertas desenvolvidas, bem como a adoção de novas
                                    tecnologias sociais pela administração pública.
                                </p>

                                <p className="text-justify  mt-2">
                                    Nessa linha da inovação, a Lei traz o Procedimento de Manifestação de Interesse Social (PMIS)
                                    que permite a cidadãos, movimentos sociais e organizações a apresentação de propostas ao
                                    poder público, para que este avalie a possibilidade de realização de um chamamento público
                                    objetivando a parceria. A proposta encaminhada à administração pública deverá atentar para
                                    questões relevantes de interesse público, apresentar diagnóstico da realidade a ser modificada
                                    e, se possível, informações sobre custos de execução e viabilidade da proposta.
                                    É importante ressaltar que a realização do PMIS não é pré-requisito para o Termo de Fomento
                                    ou o Termo de Colaboração, e não implica, necessariamente, na realização de um chamamento
                                    público, nem na dispensa de um processo seletivo.
                                </p>

                                <p className="text-justify  mt-2">
                                    Outro importante ganho trazido com o texto da nova Lei é o fim do foco na prestação de contas
                                    de meios, assim há um deslocamento da execução financeira para a centralidade da execução
                                    do objeto.
                                </p>

                                <p className="text-justify  mt-2">
                                    Nessa perspectiva, quando da celebração da parceria é fundamental que o plano de trabalho
                                    seja construído utilizando indicadores que demonstrem concretamente a consecução das ações
                                    da política de assistência social e que assim possam ser mensurados quando da prestação de
                                    contas.
                                </p>

                                <p className="text-justify  mt-2">
                                    Outra grande novidade é possibilidade da atuação em rede, por duas ou mais organizações da
                                    sociedade civil devidamente constituídas e em regularidade jurídica e fiscal, tanto na modalidade
                                    de fomento, como na de colaboração. Nos respectivos projetos, é fundamental especificar as
                                    atribuições de cada uma, indicando a responsável pelo projeto como um todo.
                                </p>


                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="6"
                                    className="w-100 text-left"
                                >
                                    O que é o Chamamento Público e a sua obrigatoriedade?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    <p  className="text-justify  mt-2">
                                        O chamamento público é um processo de seleção, no qual se privilegia a observância de alguns
                                        dos princípios constitucionais como a impessoalidade, moralidade e publicidade e assim, garante
                                        a transparência e a isonomia na seleção e no acesso aos recursos públicos. De acordo com o
                                        art. 24 da Lei nº 13.019, de 2014, os entes governamentais, exceto nas hipóteses previstas na
                                        Lei, são obrigados a abrir processo de chamamento público, e as organizações devem
                                        apresentar propostas para execução do objeto da parceria, que serão julgadas e selecionadas,
                                        pondo fim a uma das principais polêmicas referentes às parcerias, a forma de seleção.
                                        As exceções ao chamamento público constam nos arts. 30 e 31, que reportam às hipóteses de
                                        dispensa de chamamento, quando se configuram situações nas quais, embora viável a
                                        competição entre os interessados, o legislador decidiu não torná-lo obrigatório e; quando o
                                        chamamento público é considerado inexigível, o que se caracteriza pela inviabilidade de
                                        competição entre as OSCs, em razão da natureza singular do objeto da parceria ou se as metas
                                        somente puderem ser atingidas por uma entidade específica.
                                    </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="7"
                                    className="w-100 text-left"
                                >
                                    O chamamento público deve ser nacional?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="7">
                                <Card.Body>

                                    <p  className="text-justify  mt-2">
                                    A Lei nº 13.019, de 2014, veda no §2º do art. 24 que o edital de chamamento público contenha
                                    cláusulas que comprometam, restrinjam ou frustrem o caráter competitivo do ato convocatório.
                                    Contudo, a fim de atender as especificidades das políticas setoriais o inciso II do §2º do mesmo
                                    art. 24 prevê a possibilidade do estabelecimento de cláusula que delimite o território ou
                                    abrangência da prestação de atividades ou da execução de projetos.
                                    </p>

                                    <p  className="text-justify  mt-2">
                                    Nessa perspectiva, o chamamento público nos termos da Lei pode ser nacional, mas em relação
                                    às parcerias no SUAS é imprescindível considerar a territorialidade como um critério relevante
                                    quando da elaboração do edital.
                                    </p>
                                    
                                    <p  className="text-justify  mt-2">
                                    À luz das normativas do SUAS, o território é a base de organização das ações ofertadas, e a
                                    territorialização é uma das diretrizes estruturantes da gestão do Sistema. Esse lugar estratégico
                                    do território reflete o entendimento de que, para a garantia da proteção social de assistência
                                    social, é necessário o conhecimento da realidade, a compreensão das dinâmicas socioespaciais
                                    e demográficas, a leitura da presença e incidência de situações de vulnerabilidades e riscos
                                    sociais, das vivências e das mediações políticas, sociais, culturais, econômicas e relacionais
                                    presentes nos lugares.
                                    </p>

                                    <p  className="text-justify  mt-2">
                                    Nesse sentido, considerando o melhor interesse do usuário das ofertas socioassistenciais, nos
                                    casos onde essa relação justificar, podemos afirmar que a diretriz da territorialização suplanta a
                                    garantia de competitividade. Assim, é facultada ao gestor da assistência social a delimitação ou
                                    não do território no edital de chamamento público, considerando o objetivo e as especificidades
                                    do que se pretende alcançar por meio da parceria.
                                    </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="8"
                                    className="w-100 text-left"
                                >
                                    Como se dará aplicação da dispensa de Chamamento Público prevista no inciso VI do
                                    art. 30 da Lei nº 13.019, de 2014?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="8">
                                <Card.Body>

                                <p className="text-justify  mt-2">    
                                    Especificamente para a política de assistência social aplica-se a hipótese prevista no inciso VI do
                                    art.30 <em>“no caso de atividades voltadas ou vinculadas a serviços de educação, saúde e
                                    assistência social, desde que executadas por organizações da sociedade civil, previamente
                                    credenciadas pelo órgão gestor da respectiva política”.</em>
                                </p>

                                <p className="text-justify  mt-2">
                                    O mencionado dispositivo fora regulamentado no âmbito do SUAS por meio da Resolução nº 21,
                                    de 24 de novembro de 2016, do CNAS, que definiram que a dispensa do chamamento público se
                                    aplicará às entidades ou organizações de assistência social que estejam inscritas nos
                                    respectivos conselhos municipais de assistência social ou no conselho de assistência social do
                                    Distrito Federal e cadastrada no Cadastro Nacional de Entidades de Assistência Social –
                                    CNEAS, quando: o objeto do plano de trabalho for a prestação de serviços socioassistenciais
                                    regulamentados e o dano a ser gerado ao usuário devido ao rompimento do vínculo for maior
                                    que a vantagem da realização de outro chamamento público.
                                </p>

                                <p className="text-justify  mt-2"> 
                                    Conforme a Resolução supracitada entende-se por credenciamento na política de assistência
                                    social a inscrição nos conselhos de assistência social e o cadastramento no CNEAS.
                                    Nesse sentido, destaca-se que é atribuição do órgão gestor municipal realizar o cadastramento
                                    de todas as entidades e organizações de assistência social (e também do conjunto de serviços,
                                    programas, projetos e benefícios ofertados por entidades que não são preponderantes da
                                    política de assistência social) no CNEAS, disponibilizado pelo Ministério do Desenvolvimento Social e 
                                    Agrário desde abril de 2014. Para acessar o CNEAS:{" "}
                                    < a href="http://aplicacoes.mds.gov.br/cneas" target="_blank"  rel="noopener noreferrer">http://aplicacoes.mds.gov.br/cneas</a>
                                </p>
                                <p className="text-justify  mt-2">
                                    Importante, ainda, destacar que a fundamentação do dano ao usuário deverá ser exarada por
                                    parecer de profissional de nível superior das categorias reconhecidas na Resolução nº 17, de 20
                                    de junho de 2011, do CNAS.   
                                </p>

                                <p className="text-justify  mt-2">
                                Quanto ao mérito do dano gerado ao usuário, verifica-se que a natureza de alguns serviços
                                socioassistenciais, especialmente os de alta complexidade, sobretudo do serviço historicamente
                                existente que oferta acolhimento de longa permanência, por exemplo, pode configurar hipótese
                                de dispensa do chamamento público, de forma a evitar que a possibilidade de transferência dos
                                usuários, em função de uma parceria com entidade distinta da que lhes presta o serviço,
                                contribua para um novo rompimento de vínculos, ensejando, assim, fator de risco à sua
                                integridade física e emocional.
                                </p>

                                <p className="text-justify  mt-2">
                                O serviço de acolhimento, em qualquer modalidade de oferta, pressupõe a construção de vínculo
                                de afeto e confiança entre os usuários e a equipe técnica, educadores/cuidadores e demais
                                profissionais. A execução desse serviço deve se dar em unidade de referência inserida na
                                comunidade com características residenciais, ambiente acolhedor e estrutura física adequada,
                                visando o desenvolvimento de relações mais próximas do ambiente familiar.
                                Assim, para a efetivação das parcerias, o gestor local deverá avaliar, além das condições
                                técnicas e de infraestrutura das entidades, se a possibilidade de rompimento do vínculo com os
                                demais usuários, a equipe e com o ambiente já familiar pode acarretar danos à integridade física
                                e emocional dos usuários.
                                </p>

                                <p className="text-justify  mt-2">
                                Ademais, cabe esclarecer que nos casos de ampliação da capacidade de oferta do órgão gestor
                                a realização do chamamento público é regra, não cabendo, assim, a realização da dispensa de
                                chamamento público mesmo para aquelas entidades ou organizações de assistência social que
                                possuam parcerias em vigor.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="9"
                                    className="w-100 text-left"
                                >
                                    A Certificação de Entidade Beneficente de Assistência Social (CEBAS) é requisito para a celebração de parceria?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="9">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        A Certificação de Entidades Beneficentes de Assistência Social - Cebas não constitui requisito
                                        para a celebração/formalização de parcerias, pois tem como principal objetivo a isenção das
                                        contribuições para a seguridade social, além de ser facultativa para as entidades.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Inclusive o Decreto Federal nº 8.726, de 27 de abril de 2016, que regulamentou a Lei n 13.019,
                                        de 2014, dispõe que o edital não exigirá, como condição para a celebração da parceria, que as
                                        organizações da sociedade civil possuam certificação ou titulação concedida pelo Estado, exceto
                                        quando a exigência decorrer de previsão na legislação específica da política setorial.
                                        Nessa mesma linha, destaca-se que a Resolução nº 21, de 2016, do CNAS, que estabelece
                                        requisitos para celebração de parcerias, conforme a Lei nº 13.019, de 31 de julho de 2014, entre
                                        o órgão gestor da assistência social e as entidades ou organizações de assistência social no
                                        SUAS, traz em seu §3º do art.2º vedação que para a formalização das parcerias que a entidade
                                        ou organização de assistência social possua Cebas, concedida nos termos da Lei nº 12.101, de 
                                        10 27 de novembro de 2009, de forma a não restringir o caráter competitivo da seleção, observado
                                        o § 2º do art. 24 da Lei nº 13.019, de 2014.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Faz-se, ainda, necessário esclarecer que a priorização de que trata o §4º do art.18 da Lei nº
                                        12.101, de 2009, àquelas entidades ou organizações de assistência social que possuem o
                                        CEBAS, deverão estar contempladas no edital de chamada pública também de forma a não
                                        restringir o caráter competitivo da seleção.
                                    </p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="10"
                                    className="w-100 text-left"
                                >
                                  Qual a diferença entre o CNEAS e o CEBAS?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="10">
                                <Card.Body>
                                <p  className="text-justify  mt-2">
                                    A Certificação de Entidades Beneficentes de Assistência Social (Cebas), regulada pela Lei nº
                                    12.101, de 2009, e o Decreto Federal nº 8.242, de 2014, é um certificado concedido pelo
                                    governo federal às entidades privadas sem fins lucrativos que atuam na área da assistência
                                    social, da educação e da saúde. Conforme mencionado no item 7 a Certificação tem como
                                    principal objetivo o acesso a isenção das contribuições para a seguridade social (parte patronal
                                    da contribuição previdenciária sobre a folha de pagamento, Contribuição Social sobre o Lucro
                                    Líquido – CSLL, Contribuição para o Financiamento da Seguridade Social – COFINS,
                                    Contribuição PIS/PASEP).
                                </p>

                                <p  className="text-justify  mt-2">
                                    Compete ao Ministério do Desenvolvimento Social e Agrário-MDSA conceder a Certificação às
                                    entidades e organizações de assistência social, sendo-lhes facultado requerê-la.
                                    Já o Cadastro Nacional de Entidades de Assistência Social-CNEAS, previsto na Lei Orgânica de
                                    Assistência Social-LOAS (1993), é um instrumento de reconhecimento e monitoramento das
                                    ofertas socioassistenciais prestadas por organizações da sociedade civil. Disponibilizado em
                                    abril de 2014, o CNEAS é gerenciado pelo MDSA e deve ser preenchido pelos órgãos gestores
                                    municipais, com o apoio dos conselhos de assistência social, das entidades e dos estados.
                                    Devem ser cadastradas no CNEAS as entidades que estão inscritas no conselho de assistência
                                    social e que executam ofertas tipificadas ou regulamentadas pela política de assistência social,
                                    nacionalmente ou em nível local.
                                </p>
                                
                                <p  className="text-justify  mt-2">
                                    Também se encontram cadastrados no CNEAS os serviços, programas e projetos
                                    socioassistenciais ofertados, de forma não preponderante, pelas organizações da sociedade
                                    civil.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="11"
                                    className="w-100 text-left"
                                >
                                    Como proceder com as emendas parlamentares do orçamento da união de acordo
                                    com a Lei nº13.019, de 2014?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="11">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                    No âmbito do SUAS, à luz do pacto federativo e da diretriz da descentralização políticoadministrativa e 
                                     único das ações em cada esfera de governo, há arranjo institucional e
                                    normativo de competências entre os entes federados (União, estados, municípios e Distrito
                                    Federal), em que estão estabelecidas as respectivas responsabilidades comuns e específicas.
                                    </p>

                                    <p className="text-justify  mt-2">
                                    Assim, nos termos da LOAS não compete à União a execução, direta ou indireta (por meio de
                                    parcerias com entidades de assistência social) de serviços, programas e projetos 
                                    socioassistenciais. Essa atribuição é exercida pelos estados, municípios e o Distrito Federal,
                                    conforme o inciso V dos arts. 13, 14 e 15, todos da Lei nº 8.742, de 1993.
                                    </p>

                                    <p className="text-justify  mt-2">
                                   
                                    <u> Portanto, seguindo a lógica, os princípios e normativos do SUAS, a União não repassará
                                        diretamente, por meio de transferências voluntárias, recursos para entidades ou organizações de
                                        assistência social para prestação de serviços, programas ou projetos socioassistenciais.</u> Nesse
                                        mesmo sentido, prevê o § 5º do art. 4º do Decreto nº 7.788, de 15 de agosto de 2012, que
                                        regulamenta o Fundo Nacional de Assistência Social, ao dispor que os recursos são transferidos
                                        aos estados, municípios e Distrito Federal – e não às entidades ou organizações de assistência
                                        social:
                                    </p>

                                    <p  className="text-justify  mt-2 ms-5">
                                       <p  className="text-justify  ms-5">Art. 4º [...]</p>
                                        <p className="text-justify  ms-5"> 
                                            <em>
                                                § 5º O FNAS poderá repassar recursos destinados à assistência
                                                social aos entes federados por meio de convênio, ajuste, acordo,
                                                contrato ou instrumento congênere, sendo vedado ao convenente
                                                transferir a terceiros a execução do objeto do instrumento(grifo nosso).
                                            </em>
                                        </p>
                                    </p>

                                    <p className="text-justify  mt-2">
                                        <u>Assim, à luz da legislação vigente no SUAS, nos casos de entidades beneficiadas por emenda
                                        parlamentar no orçamento da União, os procedimentos de celebração, execução e prestação de
                                        contas para as parcerias na área de assistência social permanecem realizados com o estado,
                                        município ou Distrito Federal.</u>
                                    </p>
                                    
                                    <p className="text-justify  mt-2">
                                        <u>Nos casos das emendas parlamentares, é importante verificar a regra do art. 29 da Lei nº
                                        13.019/2014:</u>
                                    </p>

                                    <p  className="text-justify  mt-2 ms-5">
                                        <p className="text-justify  ms-5"> 
                                            <em>
                                                Art. 29. Os termos de colaboração ou de fomento que envolvam
                                                recursos <u>decorrentes de emendas parlamentares às leis
                                                orçamentárias anuais e os acordos de cooperação serão
                                                celebrados sem chamamento público</u>, exceto, em relação aos
                                                acordos de cooperação, quando o objeto envolver a celebração de
                                                comodato, doação de bens ou outra forma de compartilhamento de
                                                recurso patrimonial, hipótese em que o respectivo chamamento público
                                                observará o disposto nesta Lei.
                                            </em>
                                        </p>
                                    </p>

                                    <p  className="text-justify  mt-2">
                                        Assim, as emendas parlamentares que são destinadas as entidades e organizações de 
                                        assistência social serão beneficiadas indiretamente por meio de convênio firmado entre a União 
                                        e o estado, Distrito Federal ou Munícipio (responsável pela celebração, execução e prestação de 
                                        contas) já que o FNAS não formaliza parcerias diretamente com OSCs, segundo o Decreto nº 
                                        7.788, de 15 de agosto de 2012. 
                                    </p>

                                    <p className="text-justify  mt-2">
                                        De forma geral os estados, Distrito Federal ou municípios executam o recurso oriundo das 
                                        emendas parlamentares a fim de beneficiar as entidades escolhidas, adquirindo, assim,
                                        materiais permanentes e de consumo, veículos e contratação de serviços de terceiros de curta 
                                        duração. 
                                    </p>
                                    
                                    <p className="text-justify  mt-2">
                                        Essas aquisições devem estar vinculadas à execução e ofertas das atividades de assistência 
                                        social, conforme previsto pela Tipificação Nacional de Serviços Socioassistenciais. Importante
                                        esclarecer que os itens proeminentes de outras áreas orçamentárias (saúde e educação e 
                                        trabalho, por exemplo) não são permitidos.
                                    </p>
                                    
                                    <p className="text-justify  mt-2">
                                        No que tange a aquisição de equipamentos, o bem deverá estar inventariado ao patrimônio do 
                                        município, estado ou Distrito Federal, devendo o ente realizar a cessão de uso a entidade
                                        beneficiada.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Nesse sentido, observa-se que os estados, municípios e Distrito Federal revertem os materiais e 
                                        bens adquiridos às entidades e organizações de assistência social por meio de outros 
                                        instrumentos jurídicos que não são os Termos de Fomento e Colaboração, tendo em vista que 
                                        nestes casos não envolvem a execução de serviços, programas ou projetos de assistência 
                                        social. 
                                    </p>
                                   

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="12"
                                    className="w-100 text-left"
                                >
                                   Com o recurso do repasse do cofinanciamento federal, é possível o pagamento dos 
                                    trabalhadores das entidades e organizações de assistência social, de encargos sociais e
                                    trabalhistas e verbas rescisórias no âmbito de um Termo de Colaboração?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="12">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        A Lei nº 13.019, de 2014, trouxe expressamente em seu art.46, colacionado abaixo, a 
                                        possibilidade de utilização dos recursos da parceria, entre outras despesas, para: a remuneração 
                                        da equipe encarregada da execução do plano de trabalho, inclusive de pessoal próprio da 
                                        organização, durante a vigência da parceria, incluídas as despesas com encargos sociais e 
                                        trabalhistas.
                                    </p>

                                    <p  className="text-justify  mt-4 ms-5">
                                        <p className="text-justify  ms-5"> 
                                            <em>
                                            Art. 46. Poderão ser pagas, entre outras despesas, com recursos vinculados à parceria: 
                                            </em>
                                        </p>
                                        <ol className="text-justify  ms-5" style={{ listStyleType: "upper-roman" }}>
                                            <li>
                                                 remuneração da equipe encarregada da execução do plano de 
                                                trabalho, inclusive de pessoal próprio da organização da sociedade 
                                                civil, durante a vigência da parceria, compreendendo as despesas com 
                                                pagamentos de impostos, contribuições sociais, Fundo de Garantia do 
                                                Tempo de Serviço - FGTS, férias, décimo terceiro salário, salários 
                                                proporcionais, verbas rescisórias e demais encargos sociais e 
                                                trabalhistas; (Redação dada pela Lei nº 13.204, de 2015).
                                            </li>
                                        </ol>
                                    </p>

                                    <p className="text-justify  mt-4">
                                        Ressalta-se que apesar de ser possível o pagamento da remuneração dos recursos humanos e 
                                        seus encargos, a equipe contratada pela entidade não tem vínculo trabalhista com o poder 
                                        público, conforme o disposto no § 3º do art. 46 da Lei nº 13.019, de 2014, “ § 3º O pagamento 
                                        de remuneração da equipe contratada pela organização da sociedade civil com recursos da 
                                        parceria não gera vínculo trabalhista com o poder público.”
                                    </p>

                                    <p className="text-justify  mt-2">
                                        É importante esclarecer acerca o pagamento das verbas rescisórias que poderão ser pagas 
                                        ainda que após o termino da parceria, desde que proporcional ao tempo em que o profissional 
                                        atuou na execução do objeto.
                                    </p>
                                   

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="13"
                                    className="w-100 text-left"
                                >
                                     Como irá funcionar a reprogramação de recursos do cofinanciamento federal quando 
                                    celebrados Termos de Fomento e de Colaboração? 
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="13">
                                <Card.Body>

                                    <p className="text-justify  mt-2">
                                        O cofinanciamento federal dos serviços, programas e projetos socioassistenciais é realizado na 
                                        modalidade fundo a fundo, possibilitando a reprogramação dos saldos no final do exercício, 
                                        conforme o disposto nos art. 30 e 31 da Portaria MDS nº 113, de 2015.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Nos casos de Termos de Fomento ou de Colaboração firmados em âmbito local, o recurso 
                                        transferido para a entidade poderá ser utilizado até a data final de vigência do respectivo 
                                        instrumento. Após o final da vigência, a parcela relativa ao recurso federal e a respectiva 
                                        aplicação financeira não utilizadas deverão ser devolvidas à conta vinculada de origem do 
                                        recurso.
                                    </p>

                                    <p className="text-justify  mt-2">
                                    Ressalva-se que poderá ocorrer, em casos excepcionais, pagamento em data posterior à 
                                    vigência do instrumento, devidamente justificado, se expressamente autorizada pelo gestor da 
                                    parceria e desde que o fato gerador da despesa tenha ocorrido durante a vigência do 
                                    instrumento pactuado.
                                    </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="14"
                                    className="w-100 text-left"
                                >
                                    Quais são os custos indiretos que poderão ser pagos com os recursos da parceria?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="14">
                                <Card.Body>

                                <p className="text-justify  mt-2">
                                        A Lei define custo indireto no âmbito de uma parceria. São exemplos as despesas com internet, 
                                        transporte, aluguel e telefone, bem como a remuneração de serviços contábeis e de assessoria 
                                        jurídica necessários para que a OSC cumpra a legislação de transparência e prestação de 
                                        contas do uso do recurso público. Tais custos devem estar previstos Plano de Trabalho.
                                    </p>
                                    

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="15"
                                    className="w-100 text-left"
                                >
                                    Em uma parceria com recursos federais, estaduais e municipais qual a regra que irá 
                                    prevalecer?         
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="15">
                                <Card.Body>

                                <p className="text-justify  mt-2">
                                    Para entendimento da questão temos que separar a resposta em dois tópicos. O primeiro refere-se à finalidade do recurso e o segundo as regras procedimentais relativas as etapas de 
                                    contratação execução e prestação de contas.
                                </p>

                                <p className="text-justify  mt-2">
                                    Com relação à finalidade, o gestor local e a entidade deverão respeitar as regras de utilização de 
                                    acordo com a fonte do recurso e com a sua origem. Sendo assim, a despesa a ser realizada com 
                                    recurso da União, fonte União, deverá ter como base as normas federais. Analogamente, por 
                                    exemplo, as despesas com recursos estaduais, fonte estadual, deverão seguir as normas 
                                    estaduais.
                                </p>

                                <p className="text-justify  mt-2">
                                    No que tange ao segundo ponto, o gestor local deverá observar os ditames da Lei nº 13.019, de 
                                    2014, as normas gerais do SUAS e os normativos municipais, como o Decreto de 
                                    regulamentação da Lei.
                                </p>

                                <p className="text-justify  mt-2">
                                    Em resumo, com relação aos procedimentos, a norma local terá de ser observada na 
                                    formalização da parceria com a entidade.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="16"
                                    className="w-100 text-left"
                                >
                                     Com o recurso do cofinanciamento federal na modalidade fundo a fundo será possível 
                                    aquisição de equipamentos e materiais permanentes ou realização de reformas e obras?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="16">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        Na execução dos recursos do cofinanciamento federal dos serviços, programas e projetos 
                                        socioassistenciais deve-se observar a natureza da despesa, pois estes são destinados 
                                        atualmente ao custeio das ações, não podendo ser realizadas despesas de capital, tais como: 
                                    </p>

                                <ul className="text-justify  ms-5"   style={{ listStyleType: " square" }}>
                                    <li>
                                        Aquisição de bens e materiais permanentes;
                                    </li>

                                    <li>
                                        Construção ou ampliação de imóveis;
                                    </li>

                                    <li>
                                        Reformas que modifiquem a estrutura da edificação; e
                                    </li>

                                    <li>
                                         Em obras públicas ou na constituição de capital público ou privado.
                                    </li>
                                </ul>

                                <p  className="text-justify  mt-2">
                                    A Portaria n° 448 de 13/09/2002 da Secretaria do Tesouro Nacional define a natureza das 
                                    despesas e especifica o que é considerado material de consumo e material permanente.
                                </p>

                                <p className="ms-5"> 
                                    <p className="ms-5"> Art. 2° [...]</p>
                                    <ol className="ms-5"  style={{ listStyleType: "upper-roman" }}>
                                         <li>
                                            Material de Consumo, aquele que, em razão de seu uso corrente e 
                                            da definição da Lei 4.320/64, perde normalmente sua identidade física 
                                            e/ou tem sua utilização limitada a dois anos.
                                        </li>

                                        <li>
                                            Material Permanente, aquele que, em razão de seu uso corrente, 
                                            não perde a sua identidade física, e/ou tem uma durabilidade superior 
                                            a dois anos.
                                        </li>
                                    </ol>
                                </p>
                                      
                                <p className="text-justify  mt-4">
                                    De outra forma, destaca-se que é possível a realização de obras de conservação, adaptação de 
                                    bens imóveis e reparos, considerando que tais despesas são classificadas como de custeio.   
                               </p>

                            <p className="ms-5 mt-5">
                                <p className="ms-5 "> Lei nº 4.320/64</p>
                                <p className="ms-5 ">Art. 12. A despesa será classificada nas seguintes categorias 
                                    econômicas:
                                </p>

                                <p className="ms-5 ">(...)</p>
                                <p className="ms-5 ">§ 1º Classificam-se como Despesas de Custeio as dotações para 
                                    manutenção de serviços anteriormente criados, inclusive as destinadas 
                                    a atender a obras de conservação e adaptação de bens imóveis.
                                </p>

                                <p className="ms-5 ">Portaria STN nº 448/2002</p>

                                <p className="ms-5 ">
                                    MANUTENÇÃO E CONSERVAÇÃO. DE BENS IMÓVEIS: Registra o 
                                    valor das despesas com serviços de reparos, consertos, revisões e 
                                    adaptações de bens imóveis, pintura, reparos e reformas de imóveis 
                                    em geral, reparos em instalações elétricas e hidráulicas, reparos, 
                                    recuperações e adaptações de biombos, carpetes, divisórias e lambris, 
                                    manutenção de elevadores, limpeza de fossa e afins.
                                    
                                </p>
                            </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="17"
                                    className="w-100 text-left"
                                >
                                     Com o recurso do cofinanciamento federal na modalidade fundo a fundo será possível 
                                    a celebração de termo de fomento com as entidades de assistência social?    
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="17">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        Sim. A Lei nº 13.019, de 2014, prevê que o regulamento poderá criar mecanismo de 
                                        procedimento simplificado de prestação de contas conforme definido no §3º do art. 63:
                                    </p>

                                    <p className="ms-5 mt-3">
                                        <p className="ms-5 "> 
                                            Art.63 A prestação de contas deverá ser feita observando-se as regras 
                                            previstas nesta Lei, além de prazos e normas de elaboração 
                                            constantes do instrumento de parceria e do plano de trabalho.
                                        </p>
                                        <p className="ms-5 "> [...] </p>

                                        <p className="ms-5 ">
                                            § 3º O regulamento estabelecerá procedimentos simplificados para 
                                            prestação de contas. (Redação dada pela Lei nº 13.204, de 2015).
                                        </p>
                                    </p>

                                <p  className="text-justify  mt-2">
                                    Vale lembrar que, mesmo com o procedimento simplificado, a entidade deverá manter todos os 
                                    documentos comprobatórios das despesas e da execução física e financeira da parceria, 
                                    observado as normas específicas por consequência o gestor da parceria deverá ter acesso à
                                    documentação e terá a responsabilidade perante o FNAS, de realizar a prestação de contas do 
                                    cofinanciamento federal.
                                </p>


                                <p  className="text-justify  mt-2">
                                    Acrescente-se, que a definição de procedimento simplificado deve observar o disposto no art. 2º, 
                                    XIV da Lei, de acordo com o qual, a análise e manifestação conclusiva das contas são 
                                    responsabilidades da administração pública, sem prejuízo da atuação dos órgãos de controle.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="18"
                                    className="w-100 text-left"
                                >
                                    É possível ter procedimento simplificado de prestação de contas para as parcerias no 
                                    âmbito do SUAS regulado por decreto estadual ou municipal?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="18">
                                <Card.Body>
                                <p className="text-justify  mt-2">
                                    Sim. A Lei nº 13.019, de 2014, prevê que o regulamento poderá criar mecanismo de 
                                    procedimento simplificado de prestação de contas conforme definido no §3º do art. 63:
                                </p>

                                <p className="text-justify  mt-2 ms-5">
                                    <p className=" mt-3 ms-5">
                                        Art.63 A prestação de contas deverá ser feita observando-se as regras 
                                        previstas nesta Lei, além de prazos e normas de elaboração 
                                        constantes do instrumento de parceria e do plano de trabalho.
                                    </p>

                                    <p  className=" mt-3 ms-5">
                                         [...]
                                    </p>

                                    <p  className=" mt-3 ms-5">
                                        § 3º O regulamento estabelecerá procedimentos simplificados para 
                                        prestação de contas. (Redação dada pela Lei nº 13.204, de 2015).
                                    </p>
                                </p>

                                <p className="text-justify  mt-3">
                                    Vale lembrar que, mesmo com o procedimento simplificado, a entidade deverá manter todos os 
                                    documentos comprobatórios das despesas e da execução física e financeira da parceria, 
                                    observado as normas específicas por consequência o gestor da parceria deverá ter acesso à 
                                    documentação e terá a responsabilidade perante o FNAS, de realizar a prestação de contas do 
                                    cofinanciamento federal.
                                </p>

                                <p className="text-justify  mt-2">
                                    Acrescente-se, que a definição de procedimento simplificado deve observar o disposto no art. 2º, 
                                    XIV da Lei, de acordo com o qual, a análise e manifestação conclusiva das contas são 
                                    responsabilidades da administração pública, sem prejuízo da atuação dos órgãos de controle.
                                </p>


                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="19"
                                    className="w-100 text-left"
                                >
                                    Como garantir publicidade das parcerias em locais visíveis das sedes sociais e dos 
                                    estabelecimentos em que as entidades e organizações de assistência social exerçam suas 
                                    atividades sem ferir a dignidade dos usuários dos serviços socioassistenciais?

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="19">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        O art. 11 da Lei nº 13.019, de 2014, prevê que “a organização da sociedade civil deverá divulgar 
                                        na internet e em locais visíveis de suas sedes sociais e dos estabelecimentos em que exerça 
                                        suas ações todas as parcerias celebradas com a administração pública”.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Isso significa que a celebração das parcerias deve ser visibilizada. No entanto, deve-se 
                                        assegurar que a privacidade da oferta dos serviços e integridade dos usuários sejam 
                                        preservadas, de forma a não estigmatizá-los.
                                    </p> 

                                    <p className="text-justify  mt-2">
                                        Recomenda-se que os serviços de acolhimento de alta complexidade, por serem ofertados em 
                                        unidades com características residenciais, não possuam identificação externa, de forma a evitar 
                                        a estigmatização e garantir privacidade e dignidade aos usuários. 
                                    </p>

                                    <p className="text-justify  mt-2">
                                        A exceção a essa recomendação se dá nos casos de serviço de acolhimento para pessoas 
                                        idosas, que deverão ter identificação externa visível, conforme disposto no art. 37, § 2º da Lei nº 
                                        10.741, de 1º de outubro de 2003 (Estatuto do Idoso).
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Assim, para realizar a publicidade das parcerias celebradas com a administração pública para a 
                                        prestação de serviço de acolhimento é recomendado que as informações sejam divulgadas nas 
                                        áreas internas da entidade, em locais de fácil visibilidade.
                                    </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="20"
                                    className="w-100 text-left"
                                >
                                    Quem será o responsável pela prestação de contas do recurso federal ao FNAS 
                                    (apresentação de documentos, justificativas e devoluções de recurso) e como o gestor 
                                    local deverá proceder em caso de irregularidade na parceria com recurso federal?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="20">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                        No caso da execução indireta dos serviços, programas ou projetos socioassistenciais por Termo 
                                        de Colaboração firmado com entidades e organizações de assistência social, o gestor estadual 
                                        ou municipal fica responsável por verificar a boa e regular utilização do recurso por parte da 
                                        instituição.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Neste aspecto, caso sejam apuradas impropriedades ou irregularidades na execução dos 
                                        recursos provenientes do erário federal por parte da entidade, o gestor local será instado a 
                                        encaminhar informações, documentos ou realizar devolução de recursos à União, conforme a 
                                        situação, de acordo com o disposto no art. 25 da Portaria MDS nº 113/2015:
                                    </p>

                                    <p  className="text-justify  mt-2 ms-5">

                                        <p className="text-justify  mt-2 ms-5">
                                            <em>
                                                Art. 25 Compete aos Estados, Municípios e o Distrito Federal zelar 
                                                pela boa e regular utilização dos recursos transferidos pela União 
                                                executados direta ou indiretamente por estes.
                                        
                                                Parágrafo único. Os entes serão responsáveis pela boa e regular 
                                                utilização do recurso, devendo, sempre quando solicitados, 
                                                encaminhar informações, documentos ou realizar devolução de 
                                                recursos à União, nos casos de comprovada irregularidade na 
                                                execução dos serviços, programas e projetos, inclusive por meio das 
                                                entidades e organizações de assistência social, ou de irregularidade 
                                                na apuração dos índices de gestão, conforme o caso.
                                            </em>
                                        </p>
                                    </p>
                                <p className="text-justify  mt-2">
                                    Se a identificação da irregularidade ou impropriedade partir de uma notificação, por exemplo, do 
                                    FNAS, o gestor local deverá encaminhar justificativas combinadas com documentação 
                                    comprobatória ou a devolução do recurso para a União, não impedindo as demais ações a 
                                    posteriori junto à entidade.
                                </p>

                                <p className="text-justify  mt-2">
                                    É importante destacar que toda documentação relativa à execução física e financeira da parceria 
                                    deverá estar à disposição tanto do Órgão Gestor da parceria, bem como dos Órgãos de controle 
                                    interno e externo federais, conforme disposto no inciso XV do art. 42 e do art. 68 da Lei nº 
                                    13.019, de 2014.
                                </p>

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card>
                                <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="22"
                                    className="w-100 text-left"
                                >
                                     Como se dará a transição para o novo modelo de parcerias?
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="22">
                                <Card.Body>
                                    <p className="text-justify  mt-2">
                                    A Lei de Fomento e de Colaboração institui normas gerais para as parcerias entre a
                                    Administração Pública – direta e indireta da União, dos estados, dos municípios e do Distrito
                                    Federal – e as OSCs. Embora tenha caráter geral, sua aplicação deve observar também as
                                    normas específicas das políticas públicas setoriais, a exemplo da política de assistência social,
                                    conforme prevê o art. 2º-A:{" "}
                                    </p>
                                    
                                    <p className="text-justify  mt-2">
                                        A Lei nº 13.019, de 2014, apesar de publicada em 1º de agosto de 2014, entrou em vigor para a 
                                        União e os estados em janeiro de 2016, após transcorridos 540 dias de sua publicação, e para 
                                        os municípios vigora desde 1º de janeiro de 2017.
                                    </p>

                                    <p className="text-justify  mt-2">
                                        Como regra geral a Lei disciplina que as parcerias já existentes no momento da entrada em vigor 
                                        permanecerão regidas pela legislação vigente ao tempo de sua celebração, sem prejuízo de 
                                        aplicação subsidiária (art. 83), desde que em benefício do alcance do objeto da parceria (art.91). 
                                        Sendo que os Convênios: 
                                    </p>
                                    <p className="text-justify  mt-2 ms-5">
                                        <ul className="text-justify  ms-5" style={{ listStyleType: "circle" }}>
                                            <li>
                                                prorrogáveis por igual período ou inferior podem ser prorrogados de ofício ou aditados (prazo e 
                                                valor), observada a legislação vigente ao tempo da sua celebração original e a aplicação 
                                                subsidiária da Lei nº 13.019, de 2014 - prestação de contas por resultados, despesas indiretas e 
                                                com equipes de trabalho.
                                            </li>
                                       
                                            <li>
                                                com prazo indeterminado ou prorrogáveis por período superior devem ser até 23/01/2017, para 
                                                estados, União e Distrito Federal, e 01/01/2018, para os municípios – a)substituídos por termo de 
                                                fomento, de colaboração ou por acordo de cooperação, para adaptação ao disposto na Lei nº 
                                                13.019, de 2014, no caso do gestor decidir pela continuidade da parceria; b) rescindidos de 
                                                forma justificada e unilateral pela Administração Pública, com notificação à organização da 
                                                sociedade civil parceira para as providências necessárias (art.83); 
                                            </li>
                                        </ul>
                                        
                                    
                                    </p>
                                    
                                    <p  className="text-justify  mt-2 ">
                                        Observa-se que os convênios substituídos por termos de fomento e colaboração deverão aplicar 
                                        subsidiariamente as regras de controle de resultados quando da prestação de contas. Também 
                                        poderá haver aplicação da regra de análise da prestação de contas focada no alcance de metas 
                                        para os convênios e instrumentos congêneres que estejam em fase de execução de seu objeto 
                                        ou que estejam em fase de análise de prestação de contas.
                                    </p> 

                                   
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>


                         </Accordion>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export default PaginaFaq;


