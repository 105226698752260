function validaCPF(s) {
  var c = s.substr(0, 9);
  var dv = s.substr(9, 2);
  var d1 = 0;
  for (var i = 0; i < 9; i++) {
    d1 += c.charAt(i) * (10 - i);
  }
  if (d1 == 0) return false;
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  if (dv.charAt(0) != d1) {
    return false;
  }
  d1 *= 2;
  for (var i = 0; i < 9; i++) {
    d1 += c.charAt(i) * (11 - i);
  }
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  if (dv.charAt(1) != d1) {
    return false;
  }
  return true;
}

//valida o CNPJ digitado
function validaCNPJ(CNPJ) {
  var a = new Array();
  var b = new Number();
  var c = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  for (var i = 0; i < 12; i++) {
    a[i] = CNPJ.charAt(i);
    b += a[i] * c[i + 1];
  }
  var x;
  if ((x = b % 11) < 2) {
    a[12] = 0;
  } else {
    a[12] = 11 - x;
  }
  b = 0;
  for (var y = 0; y < 13; y++) {
    b += a[y] * c[y];
  }
  if ((x = b % 11) < 2) {
    a[13] = 0;
  } else {
    a[13] = 11 - x;
  }
  if (CNPJ.charAt(12) != a[12] || CNPJ.charAt(13) != a[13]) {
    return false;
  }
  return true;
}

export const validCpf = {
  exclusive: false,
  message: "O CPF informado é inválido",
  test: (value) => {
    if (value == null) return false;

    return validaCPF(value);
  },
};

export const validCnpj = {
  exclusive: false,
  message: "O CNPJ informado é inválido",
  test: (value) => {
    if (value == null) return false;

    return validaCNPJ(value);
  },
};
