import React, { useContext, useEffect,  useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import * as yup from "yup";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import Table from "react-bootstrap/Table";
import VincularProponenteListagem from "./VincularProponenteListagem";


const validationSchema = yup.object().shape({
    cpf: yup
    .string()
    .matches("[0-9]{11}", "Somente números com 11 dígitos ")
    .required("Somente números com 8 dígitos "),
});

const initialValues = {
  cpf: "",
};

const VincularProponeteOSC = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
        request(apiRoutes.main.gestorOSC.PesquisarProponente, values, {
            requestId: "pesquisar",
          });

    },});

   
    
  const readOnly = loading || formik.isSubmitting;

 
  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Vincular proponente
            </h1>
          </Col>
        </Row>
      </Container>
      <div id="conteudo-pagina-w100" style={{ marginTop: 200 }}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="col-md-6 p-2">
                <Form onSubmit={formik.handleSubmit}>

                  <Col lg="12" md="12" sm="12" xs="12">
                    <p className="text-center">
                      Para vincular um proponente a sua OSC basta digitar o cpf do proponente no campo abaixo. Em seguida selecione o proponente na tabela e confirme. Lembre-se o proponete tem que estar cadastrado no portal OSC.
                    </p>
                  </Col>
                
                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      field="cpf"
                      label="CPF:"
                      placeholder="Informe o  cpf do proponente"
                      readOnly={readOnly}
                    />
                  </Col>
                
              
                  <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center mt-3" >
                    <Button
                      variant="primary"
                      onClick={formik.handleSubmit}
                      disabled={readOnly}
                    >
                      Pesquisar
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Row>

          <Row>
           < VincularProponenteListagem data={data}  loading={loading} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default VincularProponeteOSC;
