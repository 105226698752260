import { Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { FormatDate } from "../../../components/UI/Format/Format";

const PropostaListaPublica = (props) => {
  const { data, errors, loading } = props;
  
  if (errors)
    return (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">
            Desculpe, ocorreu um erro durante a pesquisa. Tente novamente mais
            tarde.
          </Alert>
        </Col>
      </Row>
    );

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="mb-4">
            {loading ? <Spinner animation="border" /> : data && "Resultado:"}
          </h5>
        </Col>
      </Row>
      {!loading && data && data.length === 0 && (
        <Alert variant="primary">Nenhum registro encontrado</Alert>
      )}
      {!loading &&
        data &&
        data.map((item) => (
          <Row className="mt-2">
            <Col>
              <Card>
                <Card.Img variant="top" />
                <Card.Body>
                  <Card.Title>{item.tx_titulo}</Card.Title>
                  <Card.Text>
                    <b>ÁREA:</b> {item.tx_area}
                  </Card.Text>
                  <Card.Text>DESCRIÇÃO RESUMIDA: {item.tx_descricao}</Card.Text>
                  <Card.Text>
                    SITUAÇÃO: {item.tx_status}
                  </Card.Text>
                  <Card.Text>
                    CRIAÇÃO: {FormatDate(item.tm_inclusao, "dd/MM/yyyy")}
                  </Card.Text>
                  <Card.Text>PROTOCOLO: {item.tx_protocolo}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default PropostaListaPublica;
