import React, {useContext, useEffect, useState, useCallback} from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Pagination from 'react-bootstrap/Pagination'
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { RiDeleteBin2Fill, RiPencilFill} from "react-icons/ri";
import moment from 'moment';
const initialValues = {
  idOrgao: "",
  chamamento: "S",
};

const ListaChamamentosPublicos = () => {
  const { request:requestchamamento, data:datachamamento } = useApiClient();
const { request, data, errors, loading, requestId } = useApiClient();
const [termosAcordos, setTermosAcordos] = useState();

useEffect(() => {
  requestchamamento(apiRoutes.main.chamamentoPublico.listarChamamento);
}, [requestchamamento]);

useEffect(() => {
  if (datachamamento) setTermosAcordos(datachamamento);
}, [datachamamento]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      
    },
  });
  
  const formatarData = (valor)=>{
    const dataFormatada = moment(valor).format('DD/MM/YYYY');
    return dataFormatada
  }
  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <Row>
          <Col>
            <h2 className="mt-5">Chamamentos públicos</h2>
          </Col>
        </Row>
            <Row>
              <Col>
                <p>
                  Aqui são listados todos os  chamamentos públicos lançados e Dispensado/inexigíveis.
                </p>
              </Col>
            </Row>

            <Row className="bg-light rounded p-2 mt-5 mt-lg-3 mt-md-3">
              <Form>
                <Row>
                    <Col md="6" sm="6" xs="12">
                      <SelectInputCached
                        formik={formik}
                        field="idOrgao"
                        label="Buscar por Secretaria ou Autarquia"
                        cacheKey="orgaos"
                        allowEmpty
                      />
                    </Col>
                    <Col md="3" sm="6" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label className="me-3">Status:</Form.Label>
                          <br />
                          <Form.Check
                            className="me-2"
                            inline
                            type="radio"
                            name="radio-perfil"
                            id="radio-dispensado"
                            checked={formik.values.chamamento === "S"}
                            label="Lançados"
                            onChange={() => formik.setFieldValue("chamamento", "S")}
                          />
                          <Form.Check
                            className="me-2"
                            inline
                            type="radio"
                            name="radio-perfil"
                            id="radio-pf"
                            checked={formik.values.chamamento === "N"}
                            label="Dispensado/inexigíveis"
                            onChange={() => formik.setFieldValue("chamamento", "N")}
                          />
                        </Form.Group>
                    
                    </Col>
                    <Col md="2" sm="6" xs="12">
                      <Button
                        variant="primary"
                        className="p-2 py-2 mt-3 w-100"
                        role="button"
                      >
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </Form> 
              </Row>

            <Row>
              <Col>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Protocolo</th>
                      <th>Data</th>
                      <th>Título</th>
                      <th>Objeto de parceiria</th>
                      <th>ação</th>
                    </tr>
                  </thead>
              
                  <tbody>
                {(datachamamento || {}).length &&
                  datachamamento.map((itemData) => (
          
                    <tr
                    key={itemData.id_chamamento_publico} style={{ cursor: "pointer" }} >
                      <td  > {itemData.id_chamamento_publico}</td>
                      <td> {formatarData(itemData.tm_inclusao)}</td>
                      <td> {itemData.tx_titulo}</td>
                      <td> {itemData.tx_objeto}</td>
                      <td className="d-flex flex-row justify-content-around">
                     
                      <RiDeleteBin2Fill
                      
                          size={25}
                          color="red"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                "Você deseja realmente excluir esse chamamento publico?"
                              )
                            ) {
                              var idChamamento = itemData.id_chamamento_publico;
                              request(apiRoutes.main.chamamentoPublico.removerChamamento, {
                                routeParams: { idChamamento },
                              });
                              setTimeout(window.location.reload(), 800);
                            }
                          }}
                        />

                      
                        
                      </td>
                    </tr>
                  ))}
              </tbody>  
                    
                  </Table>
              </Col>
            </Row>
      </Container>
    </>
  );
};

export default ListaChamamentosPublicos;
