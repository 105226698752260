import SelectInput from "./SelectInput";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import { useSelector, useDispatch } from "react-redux";
import { cacheActions } from "../../../../redux/cache";

const SelectInputCached = (props) => {
  const { formik, field, label, placeholder, readOnly, cacheKey, defaultText, allowEmpty } =
    props;
  const cache = useSelector((state) => state.cache);
  const [options, setOptions] = useState([]);
  const { request, data, loading } = useApiClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cache[cacheKey].length) setOptions([...cache[cacheKey]]);
    else request(apiRoutes.main.cache[cacheKey]);
  }, [cache, cacheKey, request]);

  useEffect(() => {
    if (data) {
      dispatch(cacheActions.updateCache({ cacheKey, items: data.value }));
    }
  }, [data, cacheKey, dispatch]);

  if (loading) return <Spinner animation="border" size="sm" />;

  let optionsEl = [];

  if (!formik.values[field] || allowEmpty)
    optionsEl.push(
      <option key={-1} value="">
        {defaultText || "Todos"}
      </option>
    );

  optionsEl.push(
    options.map((item) => (
      <option key={item.id} value={item.id}>
        {item.descricao}
      </option>
    ))
  );

  return (
    <SelectInput
      formik={formik}
      field={field}
      label={label}
      placeholder={placeholder}
      readOnly={readOnly}
    >
      {optionsEl}
    </SelectInput>
  );
};

export default SelectInputCached;
