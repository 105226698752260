import { createContext, useEffect, useState } from "react";
import _ from "lodash";

const AppContext = createContext({
  backgroundClassList: ["background-1"],
  setBackgroundClassList: (className) => {
    console.log(className);
  },
});

const AppContextProvider = ({ children }) => {
  const [backgroundClassList, setContextBackgroundClassList] = useState([
    "background-1",
  ]);

  useEffect(() => {
    document.body.className = backgroundClassList.join(" ");
  }, [backgroundClassList]);

  return (
    <AppContext.Provider
      value={{
        backgroundClassList: backgroundClassList,
        setBackgroundClassList: (classList) => {
          if (!_.isEqual(classList, backgroundClassList))
            setContextBackgroundClassList(classList);
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
