import React, { useEffect } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router";
import { FormatDate } from "../../../components/UI/Format/Format";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useSelector}  from "react-redux";

const ListagemPropostasOsc  = () => {
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const id = auth.user.osc;

  useEffect(() => {

    request(apiRoutes.main.gestorOSC.ListarPropostasDaOsc, { routeParams: {id} })

  }, [request, id]);

    


  return (
    <>
      <Container className="conteudo-pagina">
        <Row>
          <Col>
            <h2 className="mt-4">Propostas vinculadas à Osc</h2>
            {errors && (
              <Alert variant="danger">
                Desculpe, ocorreu um erro durante a recuperação das propostas.
                Tente novamente mais tarde.
              </Alert>
            )}
            {loading && (
              <span>
                <Spinner animation="border" />
              </span>
            )}
          </Col>
        </Row>
        {!errors && !loading && (
          <>
            <Row>
              <Col>
                <p>
                  Aqui são listadas todas as propostas que foram vinculadas à sua OSC.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      
                      <th>Título</th>
                      <th>Área</th>
                      <th>Autor</th>
                      <th>Secretaria</th>
                      <th>Data</th>
                      <th>Situação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data || {}).length &&
                      data.map((item) => (
                        <tr>
                          <td>{item.tx_titulo}</td>
                          <td>{item.tx_area}</td>
                          <td>{item.tx_nome}</td>
                          <td>{item.tx_orgao}</td>
                          <td>{item.data}</td>
                          <td>{item.tx_status}</td>
                        </tr>
                      ))}
                      
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default ListagemPropostasOsc;
