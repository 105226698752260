import React, { useEffect, useState  } from "react";
import axios from "axios";
import { Button, Col, Container, Form, Row} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { RiDeleteBin2Fill  } from "react-icons/ri";
import { useSelector}  from "react-redux";
import { daysToWeeks } from "date-fns/esm";




const validationSchema = yup.object().shape({
  nome: yup.string().matches(""),
  cpf: yup.string().matches("[0-9]{11}", "Somente números. 11 dígitos."),

});

const initialValues = {
    nome: " ",
    cpf: " ",
 
};



const ListagemServidores = () => {
  const { request, data } = useApiClient();
  const auth = useSelector((state) =>  JSON.parse(sessionStorage.getItem("user"))|| state.auth);
  const idsecretaria = auth.user.secretaria
  
 




    const formik = useFormik({
      initialValues ,
      onSubmit: (values) => { },
    });


    useEffect(() => {
      request(apiRoutes.main.coordenador.listarServidor, { routeParams:  {idsecretaria}  })
  
    }, [request, idsecretaria]);

  return (
    <>
      <Container className="conteudo-pagina mt-5 ">
        <Row>
          <Col>
            <h2 className="mt-4">Servidores Cadastrados</h2>
         
          </Col>
        </Row>
       
         
            <Row>
              <Col>
                <p>
                  Aqui são listados  todos os Servidores cadastrados.
                </p>
              </Col>
            </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome"
                  />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    maxLength={11}
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link"className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
                <Table striped bordered hover className="mt-4">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Nome</th>
                          <th>CPF</th>
                          <th>Secretaria</th>
                          <th>Remover</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(data || {}).length &&
                           data.map((itemData) => (
                          <tr  
                          key={itemData.id_usuario}
                          style={{ cursor: "pointer" }}>
                            <td> {itemData.id_usuario}</td>
                            <td> {itemData.tx_nome}</td>
                            <td> {itemData.tx_cpf}</td>
                            <td> {itemData.tx_orgao} </td>
                            <td>
                                <RiDeleteBin2Fill size={20} color="red"
                                onClick={(e) => {    
                                  e.preventDefault();
                                  if(window.confirm("Você deseja realmente excluir esse usuário?")){
                                    var id = itemData.id_usuario;
                                    request(apiRoutes.main.coordenador.removerServidor, { routeParams:  {id}  });
                                }}}/>
                            </td>
                          </tr>))}
                          </tbody>
                  </Table>
              </Col>
            </Row>
      </Container>
    </>
  );
};

export default ListagemServidores;