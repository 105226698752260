import { Form, useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Container, Row, Alert, Button } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../components/UI/Formik/SelectInput/SelectInput";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../../components/UI/Formik/SelectInput/SelectInput.options";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import { validCnpj } from "../../components/UI/Formik/Validations/Validations";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../components/UI/ViaCepLoader/ViaCepLoader";
import { AppContext } from "../../context/AppContext";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";

const validationSchema = yup.object().shape({
  tx_cnpj: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup
      .string()
      .matches("[0-9]{14}", "Somente números. 14 dígitos.")
      .required("Informe o CNPJ da OSC")
      .test(validCnpj),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_razao_social: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup.string().required("Informe a Razão Social da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_logradouro: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup.string().required("Informe o logradouro da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_numero: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup.string().required("Informe o número do logradouro da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_cidade: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup.string().required("Selecione a Cidade do endereço da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_bairro: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup.string().required("Selecione o Bairro do endereço da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
  tx_cep: yup.string().when("tx_tipo", {
    is: "osc",
    then: yup
      .string()
      .matches("[0-9]{8}", "Somente números. 8 dígitos.")
      .required("Informe o CEP do endereço da OSC"),
    otherwise: yup.string().nullable().strip(),
  }),
});

const initialValues = {
  tx_tipo: "osc",
  tx_cnpj: "",
  tx_razao_social: "",
  tx_logradouro: "",
  tx_numero: "",
  tx_complemento: "",
  tx_bairro: "",
  tx_cidade: "",
  tx_uf: "RN",
  tx_cep: "",
};

const PaginaCadastroOsc = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading, requestId } = useApiClient();
  const numeroInputRef = useRef(null);
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {},
  });

  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Credeciamento da Organização da Sociedade Civil
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3">Dados da OSC</h2>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="tx_cnpj"
              label="CNPJ:"
              readOnly={readOnly}
              maxLength={14}
            />
          </Col>

          <Col lg="9" md="9" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="tx_razao_social"
              label="Razão Social:"
              readOnly={readOnly}
            />
          </Col>

          <Col lg="3" md="3" sm="11" xs="11">
            <TextInput
              formik={formik}
              field="tx_cep"
              label="CEP:"
              readOnly={readOnly}
              maxLength={8}
            />
          </Col>

          {!readOnly && (
            <Col lg="3" md="3" sm="1" xs="1" style={{ alignSelf: "end" }}>
              <ViaCepLoader
                disabled={!formik.values.tx_cep || !!formik.errors.tx_cep}
                cep={formik.values.tx_cep}
                onFound={(data) => {
                  const { logradouro, bairro, ibge } = data;
                  formik.setValues({
                    ...formik.values,
                    tx_logradouro: logradouro,
                    tx_bairro: bairro,
                    tx_cidade: MunicipiosDoRN[ibge],
                  });
                  numeroInputRef.current.focus();
                }}
              />
            </Col>
          )}

          <Col lg="10" md="10" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="tx_logradouro"
              label="Logradouro:"
              maxLength={120}
              readOnly={readOnly}
            />
          </Col>

          <Col lg="2" md="2" sm="12" xs="12">
            <TextInput
              formik={formik}
              ref={numeroInputRef}
              field="tx_numero"
              label="Número:"
              readOnly={readOnly}
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="tx_complemento"
              label="Complemento:"
              readOnly={readOnly}
            />
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="tx_bairro"
              label="Bairro:"
              readOnly={readOnly}
            />
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <SelectInput
              formik={formik}
              field="tx_cidade"
              label="Cidade:"
              readOnly={readOnly}
            >
              <MunicipiosAsOptions />
            </SelectInput>
          </Col>
          <Row>
            <Col className="d-flex justify-content-center mt-5">
              <Button
                className="mt-3"
                disabled={readOnly}
                onClick={formik.handleSubmit}
              >
                <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
              </Button>
              &nbsp;&nbsp;
              <Button className="mt-3" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default PaginaCadastroOsc;
