import React, { useRef, useState, useEffect } from "react";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { Button, Form, Spinner } from "react-bootstrap";

const SelectFileInputGeneral = (props) => {
  const {
    accept,
    show,
    error,
    defaultPayload,
    onFinishUpload,
    field,
    handleArquivo,
  } = props;
  const fileInput = useRef();
  const [uploadPayload, setUploadPayload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const { request, data, errors, requestId } = useApiClient();

  useEffect(() => {
    if (uploadPayload) handleArquivo(uploadPayload);
  }, [request, uploadPayload]);

  useEffect(() => {
    if (data) {
      onFinishUpload();
    }
    if (errors) setUploadError(errors.message);
    if (data || errors) setUploading(false);
  }, [data, errors, requestId, onFinishUpload]);

  const onFileChange = (event) => {
    event.preventDefault();
    let payload = {};

    const fileProcessed = () => {
      setUploadPayload(payload);
      event.target.files = new DataTransfer().files;
    };

    let file_reader = new FileReader();
    let file = event.target.files[0];

    if (!file) return;
    setUploading(true);

    file_reader.onload = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        arquivo: file_reader.result.substring(
          file_reader.result.indexOf(",") + 1
        ),
      };

      fileProcessed();
    };

    file_reader.onerror = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        error: file_reader.error,
      };

      fileProcessed();
    };

    file_reader.readAsDataURL(file);
  };

  //   if (uploading) return <Spinner animation="border" />;

  //   if (!show) return null;

  return (
    <>
      <div>{uploadError}</div>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => {
          setUploadError(null);
          fileInput.current.click();
        }}
      >
        Selecionar Arquivo
      </Button>
      <Form.Control
        type="file"
        id={field}
        name={field}
        style={{ display: "none" }}
        ref={fileInput}
        accept={accept}
        onChange={(event) => onFileChange(event)}
        isInvalid={error}
      />
      {uploadPayload ? (
        <span className="p-2">
          <strong>{uploadPayload.arquivoNome}</strong>
        </span>
      ) : (
        ""
      )}
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};

export default SelectFileInputGeneral;
