import { useReducer, useCallback } from "react";
import axios from "axios";
import routes from "./apiClient.routes";
import common from "../config/config.common";
import { TOKEN_KEY,authActions  } from "../redux/auth";

import { useHistory } from "react-router";

const initialState = {
  loading: false,
  errors: null,
  data: null,
  requestId: null,
  requestExtra: null,
};

const defaultHeaders = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  "Access-Control-Allow-Origin": "*",
};

const apiClientReducer = (currentState, action) => {
  switch (action.type) {
    case "SEND":
      return {
        loading: true,
        errors: null,
        data: null,
        requestId: action.requestId,
        requestExtra: null,
      };
    case "RESPONSE":
      return {
        ...currentState,
        loading: false,
        data: action.responseData,
        requestExtra: action.requestExtra,
        requestId: action.requestId,
      };
    case "ERROR":
      return {
        loading: false,
        errors: action.errors,
        requestExtra: action.requestExtra,
        requestId: action.requestId,
      };
    case "CLEAR":
      return initialState;
    default:
      throw new Error("Desculpe, alguma coisa deu errado!");
  }
};

const applyRouteParams = (apiRoute, params) => {
  if (!params || !params.routeParams) return apiRoute.url;

  let url = apiRoute.url;

  Object.keys(params.routeParams).forEach((key) => {
    url = url.replace(`:${key}`, params.routeParams[key]);
  });

  return url;
};

const useApiClient = (handleSecurityIssue = true) => {
  const [httpState, dispatch] = useReducer(apiClientReducer, initialState);
  const history = useHistory();

  const clear = useCallback(() => dispatch({ type: "CLEAR" }), []);

  const request = useCallback(
    (apiRoute, params, { headers, requestId, requestExtra } = {}) => {
      dispatch({ type: "SEND", requestId });

      const successFn = (response) => {
        dispatch({
          type: "RESPONSE",
          responseData: response.data || {},
          requestExtra,
          requestId,
        });
      };

      const failureFn = (error) => {
        let withMessages = { message: "" };
        if (error.response) {
          /*
           * Qualquer status code diferente de 2xx;
           */

          if (error.response.status === 401 && handleSecurityIssue) {
            alert("Seu tempo de sessão expirou. Por favor, autentique-se novamente. ");
            const sair= () => dispatch(authActions.signout());
            history.replace("/");

            withMessages = null;
          }

          console.error(error.response);
          if (typeof error.response.data === "string")
            withMessages.message = error.response.data;
          else {
            withMessages.message = "Verifique as informações submetidas e tente novamente. ";
            withMessages.messages = error.response.data.errors;
          }
        } else if (error.request) {
          /**
           * Erro de comunicação com o servidor.
           * A requisição foi realizada, mas sem resposta.
           */
          withMessages = {
            message: "Ocorreu um erro de comunicação com o servidor. ",
          };
        } else {
          // Something happened in setting up the request and triggered an Error
          withMessages = {
            message: "Ocorreu um erro ao preparar essa requisição. ",
          };
        }

        dispatch({
          type: "ERROR",
          errors: withMessages,
          requestExtra,
          requestId,
        });
      };

      let authHeaders = {
        client_id: common.clientId,
      };

      const storedToken = localStorage.getItem(TOKEN_KEY);

      if (storedToken) {
        authHeaders.Authorization = `Bearer ${storedToken}`;
      }

      axios
        .request({
          url: applyRouteParams(apiRoute, params),
          method: apiRoute.method,
          params:
            apiRoute.method === "GET" && !(params || {}).routeParams
              ? params
              : null,
          data: apiRoute.method === "POST" ? params : null,
          withCredentials: true, // authHeaders.Authorization !== undefined,
          headers: {
            ...defaultHeaders,
            ...authHeaders,
            ...headers,
          },
          responseType: apiRoute.responseType
        })
        .then(successFn)
        .catch(failureFn);
    },
    [handleSecurityIssue, history]
  );

  return {
    request: request,
    clear: clear,
    loading: httpState.loading,
    data: httpState.data,
    errors: httpState.errors,
    requestId: httpState.requestId,
    requestExtra: httpState.requestExtra,
  };
};

export const apiRoutes = routes;

export default useApiClient;
