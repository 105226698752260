import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdLogOut } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import ImgDemoFotoPerfil from "../../../assets/img/foto-perfil-placeholder.png";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import ListaPropostasRecebidas from "../ListaPropostasRecebidas/ListaPropostasRecebidas";
import ListaChamamentosPublicos from "../ListaChamamentosPublicos/ListaChamamentosPublicos";
import ListaTermosAcordos from "../ListaTermosAcordos/ListaTermosAcordos";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/auth";
import { BiUpload } from "react-icons/bi";
import { GiBugleCall } from "react-icons/gi";

const MinhaAreaServidor = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const auth2 = useSelector((state) => state.auth);
  const auth = JSON.parse(sessionStorage.getItem("user")).user;
  const dispatch = useDispatch();

  
  
  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
    
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Minha Área</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column mt-5">
            <Row className="g-4 g-lg-4">
              <Col md="6" className="me-auto ms-auto text-center">
                <Link to="/minha-area/usuario">
                  <img
                    src={ImgDemoFotoPerfil}
                    alt="Foto do usuário"
                    className="img rounded-pill w-25 box-shadow"
                  />
                </Link>
                <h3>{auth.nome}</h3>
                <p className="mt-2 fw-bolder title-uppercase text-gray">
                  {auth.email}
                </p>
              </Col>
            </Row>

            <Row className="g-4 g-lg-4">
              <Col md="12" className="me-auto ms-auto text-center">
                <ul id="profile-menu" className="mt-md-3 mt-lg-3">
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/upload/chamamento"
                    >
                      <GiBugleCall />{" "}
                      <span className="d-md-inline">Chamamentos públicos</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/update/user/gov"
                    >
                      <BsFillPersonFill />{" "}
                      <span className="d-md-inline">Seus dados</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/upload/termos-acordos"
                    >
                      <BiUpload />{" "}
                      <span className="d-md-inline">Termos e Acordos</span>
                    </Link>
                  </li>
                  <li>
                    <a
                      className="btn btn-outline  btn-outline-danger  btn-sm rounded-pill"
                      href="/"
                      onClick={() => dispatch(authActions.signout())}
                    >
                      <IoMdLogOut /> Sair
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ListaPropostasRecebidas />
      <ListaChamamentosPublicos />
      <ListaTermosAcordos />
    </>
  );
};

export default MinhaAreaServidor;
