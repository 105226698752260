//import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
//import { LinkContainer } from "react-router-bootstrap";
import ImgBrasao from "../assets/img/marca-brasao-rn.png";

const Footer = () => {
  //const year = new Date().getFullYear()
  return (
    <>
      <footer className="footer conteudo-pagina-w100 bg-light mt-5">
        {/* <nav className="footer-nav text-center w-100">
                <ul>
                    <li><a href="#">Ouvidoria</a></li>
                    <li><a href="#">Transparência</a></li>
                    <li><a href="#">SIC</a></li>
                </ul>
            </nav> */}

        {/* <Navbar variant="light" expand="lg" className="justify-content-center">
          <Nav id="navbar-menu-nav">
            <Container>
              <Row>
                <Col>
                  <LinkContainer to="/" exact>
                    <Nav.Link>
                      <span>Início</span>
                    </Nav.Link>
                  </LinkContainer>
                </Col>

                <Col>
                  <LinkContainer to="/legislacao">
                    <Nav.Link>
                      {" "}
                      <span>Legislação</span>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/publicacoes/chamamentos">
                    <Nav.Link>
                      {" "}
                      <span>Chamamentos Públicos</span>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/publicacoes/interesse-social">
                    <Nav.Link>
                      {" "}
                      <span>Manifestacoes de Interesse Sociais</span>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/parcerias">
                    <Nav.Link>
                      <span>Tipos de Parcerias</span>
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/none1">
                    <Nav.Link>
                      <span>OSC credenciadas</span>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/none2">
                    <Nav.Link>
                      <span>Parcerias firmadas</span>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/contato">
                    <Nav.Link>
                      {" "}
                      <span>Contato</span>
                    </Nav.Link>
                  </LinkContainer>
                </Col>
              </Row>
            </Container>
          </Nav>
        </Navbar> */}

        <p className="text-center small mt-3">
          <img src={ImgBrasao} className="me-3" alt="Brasão RN" />
          <span className="text-small">
            Centro Administrativo do Estado - Av. Senador Salgado Filho, s/n,
            Lagoa Nova - Natal/RN. CEP: 59064-901
          </span>
          <span className="fs-6 px-3 text-muted">|</span>
          <span className="text-small">(84) 3190-0730</span>
          <span className="fs-6 px-3 text-muted">|</span>
          <span className="text-small">
            <a href="mailto:sethas.imprensa@gmail.com">
              sethas.imprensa@gmail.com
            </a>
          </span>
          <span className="fs-6 px-3 text-muted">|</span>
          <span className="text-small">Agradecimentos</span>
        </p>
      </footer>
    </>
  );
};

export default Footer;
