import React, { useEffect, useState } from "react";
import { IoDocumentText } from "react-icons/io5";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import appConfig from "../config/app.config";

const PublicacoesLista = () => {
  const [publicacoes, setPublicacoes] = useState([]);
  const { request, data, errors, loading } = useApiClient();

  useEffect(() => {
    request(apiRoutes.main.publicacoes.recentes, { quantidadeMaxima: 5 });
  }, [request]);

  useEffect(() => {
    if (data) setPublicacoes(data.value);
  }, [data]);

  return (
    <>
      <Container id="conteudo-pagina-interna">
        <h2 className="titulo-pagina-half">Publicações Cadastradas</h2>
        {errors && "Ocorreu um erro carregando as publicações. Por favor, tente novamente mais tarde. "}
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <Row className="mt-4">
            {publicacoes.map((publicacao) => (
              <Col className="mt-4" key={publicacao.id_publicacao}>
                <Card>
                  <Card.Img
                    variant="top"
                    style={{ width: "100%", height: "350px" }}
                    src={`${appConfig.host}/api/publicacao/img/${publicacao.id_publicacao}`}
                  />
                  <Card.Body>
                    <Card.Title>{publicacao.tt_publicacao}</Card.Title>
                    <Card.Text>{publicacao.tx_conteudo}</Card.Text>
                    <Card.Text>
                      Início da publicação: {publicacao.tm_vigencia_ini}
                    </Card.Text>
                    <Card.Text>
                      Fim da publicação:{" "}
                      {publicacao.tm_vigencia_fim ||
                        "Não informado. (Publicação fixada)"}
                    </Card.Text>
                    <Card.Text>
                      Arquivos:{" "}
                      <IoDocumentText className="text-primary display-5" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};

export default PublicacoesLista;
