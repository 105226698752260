import React from "react";
import { Col, Row,  Alert,  } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory } from "react-router";





const VincularProponenteListagem = (props) => {
    const {data, loading} = props;
    const {request, errors,} = useApiClient();
    const history = useHistory();



 

    if (errors)
    return (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">Desculpe, ocorreu um erro durante a pesquisa. Tente novamente mais tarde.</Alert>
        </Col>
      </Row>
    );
   

  return (
    <>
        
        {!loading && data && data.value.length === 0 && <Alert variant="primary">Nenhum registro encontrado</Alert>}
        {!loading &&
        data &&
        <Row>
            <Col>
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>E-mail</th>
                            <th>Logradouro</th>
                            <th>Bairro</th>
                            <th>Cidade</th>
                            <th>Telefone principal</th>
                            <th>Telefone secundário</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr
                          onClick={(e) => {    
                            e.preventDefault();
                            if(window.confirm("Você deseja realmente vincular esse usuário?")){
                              const id = data.value.id;
                              request(apiRoutes.main.gestorOSC.VincularProponente, { routeParams:  {id}  });
                            }
                            if(!errors){
                              alert("Proponente Vinculado com Sucesso!!!");
                              history.push("/minha-area-gestor");
                            }}}>
                            <td> {data.value.nome}</td>
                            <td> {data.value.cpf}</td>
                            <td> {data.value.email}</td>
                            <td> {data.value.logradouro}</td>
                            <td> {data.value.bairro}</td>
                            <td> {data.value.cidade}</td>
                            <td> {data.value.telefone1}</td>
                            <td> {data.value.telefone2}</td>
                        </tr>
                    </tbody>
                    </Table>
                </Col>                      
          </Row>}
    </>
  );
};

export default VincularProponenteListagem;
