import React, { useContext, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import * as yup from "yup";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import SpinnerLabel from "../components/UI/SpinnerLabel";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido, verifique seu email.")
    .required("Informe um e-mail válido."),
});

const initialValues = {
  email: "",
};

const PaginaEnvioLinkCadastroUsuario = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.usuario.criar, values);
    },
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    if (data) {
      alert(
        "Um e-mail contendo as instruções para criar seu usuário foi enviado. Por favor, verifique seu e-mail. "
      );
      history.push("/login");
    }

    if (errors) {
      alert(
        errors.message ||
          "Desculpe, algo deu errado. Tente novamente mais tarde."
      );
    }

    setSubmitting(false);
  }, [data, errors, setSubmitting, history]);

  const readOnly = loading || formik.isSubmitting;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Primeiro Acesso
            </h1>
          </Col>
        </Row>
      </Container>
      <div id="conteudo-pagina-w100" style={{ marginTop: 200 }}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="col-md-6 p-2">
                <Form onSubmit={formik.handleSubmit}>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <p className="text-center">
                      Para criar um novo usuário digite seu e-mail e depois
                      selecione o tipo de cadastro. Enviaremos um link com o
                      próximo passo para criar seu usuário.
                    </p>
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      field="email"
                      label="E-mail:"
                      placeholder="Informe o seu e-mail"
                      readOnly={readOnly}
                    />
                  </Col>

                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="d-flex justify-content-center mt-5"
                  >
                    <Button
                      variant="primary"
                      onClick={formik.handleSubmit}
                      disabled={readOnly}
                    >
                      <SpinnerLabel label="Enviar" loading={readOnly} />
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaginaEnvioLinkCadastroUsuario;
