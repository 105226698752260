import { Row, Col, Card, Button, Spinner, Alert } from "react-bootstrap";
import { FormatDate } from "../../../components/UI/Format/Format";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import appConfig from "../../../config/app.config"; 

// import { useHistory } from "react-router-dom";

const ChamamentoLista = (props) => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [chamamento, setChamamento] = useState();

  const onButtonClick = (item) => {
    fetch(
      `${appConfig.host}/api/chamamentopublico/baixar/${item.id_chamamento_publico}`
    )
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = item.tx_edital_nome;
        a.click();
      });
  };
  useEffect(() => {
    request(apiRoutes.main.chamamentoPublico.listarChamamento);
  }, [request]);

  useEffect(() => {
    if (data) setChamamento(data);
  }, [data]);

  const formik = useFormik({
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if ((data || errors) && !loading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }

    if (request === "load" && data && !formik.values.loaded) {
      formik.setValues({ ...data.result, loaded: true });
    }

    if (errors && !loading) formik.setErrors({ ...formik.errors, ...errors });
  }, [formik, data, errors, request, loading]);

  // const history = useHistory();

  // const lancarPropostaHandler = () => {
  //   history.push("/minha-area/proposta");
  // };

  if (errors)
    return (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">
            Desculpe, ocorreu um erro durante a pesquisa. Tente novamente mais
            tarde.
          </Alert>
        </Col>
      </Row>
    );

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="mb-4">
            {loading ? <Spinner animation="border" /> : data && "Resultado:"}
          </h5>
        </Col>
      </Row>
      {!loading && data && data === 0 && (
        <Alert variant="primary">Nenhum registro encontrado</Alert>
      )}
      {!loading &&
        data &&
        data.map((item) => (
          <Row className="mt-2" key={item.id_chamamento_publico}>
            <Col>
              <Card>
                <Card.Img variant="top" />
                <Card.Body>
                  <Card.Title>{item.tx_orgao}</Card.Title>
                  <div className="card-info">
                    <div className="card-info-item">
                      <Card.Text>
                        <b>TÍTULO:</b> {item.tx_titulo}
                      </Card.Text>
                    </div>
                    <div class="card-info-item">
                      <Card.Text>
                        PUBLICAÇÃO DO EDITAL:{" "}
                        {FormatDate(item.tm_inclusao, "dd/MM/yyyy")}
                      </Card.Text>
                    </div>
                    <div class="card-info-item">
                      <Card.Text>
                        PROTOCOLO: {item.id_chamamento_publico}
                      </Card.Text>
                    </div>
                  </div>
                  <Card.Text>OBJETO DA PARCERIA: {item.tx_objeto}</Card.Text>
                  {/* {item.fg_chamamento === "S" ? (
                    <Button variant="success" onClick={lancarPropostaHandler}>Faça Sua Proposta</Button>
                  ) : (
                    <Button variant="danger" onClick={lancarPropostaHandler}>Dispensado/inexigível</Button>
                  )} */}
                  <Button variant="link" onClick={() => onButtonClick(item)}>
                    Visualizar o Edital
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default ChamamentoLista;
