import React from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BsFillPlayFill } from "react-icons/bs";

const PaginaModelo = () => {

  return (

    <>

      {/* //Cabeçalho da página  */}
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center">
            <h1 className="fw-100 text-white">O que é a OSC?</h1>
            <h3 className="text-white">Saiba mais sobre as parcerias Organização da Sociedade Civil</h3>
            <p className="mt-5">
              <Button className="btn btn-outline btn-light btn-md rounded-pill me-2"><BsFillPlayFill />Ver um vídeo</Button>
              <Button className="btn btn-outline btn-light btn-md rounded-pill ms-2">Download</Button>
            </p>
          </Col>

        </Row>
      </Container>

      {/* //Conteúdo da página  */}
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Row className="g-4 g-lg-4 mt-4">
              <Col md="8" className="me-auto ms-auto">
                <h2 className="mt-4 text-center">Organizações da Sociedade Civil</h2>
                <h5 className="mt-4 text-center fs-6">Em conformidade com o estabelecido no Art. 2°, I, da Lei 13.019/2014, uma
                                                      Organização da Sociedade Civil (OSC) pode ser definida como uma pessoa jurídica de direito
                                                      privado sem fins lucrativos que, consequentemente, não irá distribuir seus ganhos para
                                                      nenhuma das pessoas que a compõem, sejam elas sócios, associados, conselheiros, diretores,

                                                      dentre outros, cujo caixa é todo destinado para a efetivação do seu objeto social, de forma
                                                      imediata ou por meio da constituição de fundo patrimonial ou fundo de reserva.
                                                      Ressalta-se que a expressão “Organizações da Sociedade Civil (OSC)” faz referência,
                                                      na realidade prática, às atuações de Organizações Não Governamentais (ONGs), por exemplo,
                                                      embora na legislação seja utilizado esse primeiro termo.</h5>
                <p className="mt-4 text-center"><Button href="" className="btn btn-primary rounded-pill">Ver detalhes</Button></p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
            
    </>


        )}

        export default PaginaModelo