import React, { useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../../components/UI/ViaCepLoader/ViaCepLoader";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../../../components/UI/Formik/SelectInput/SelectInput.options";
import { TOKEN_KEY } from "../../../redux/auth";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/auth";
import appConfig from "../../../config/app.config";
import { validCpf } from "../../../components/UI/Formik/Validations/Validations";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";

const validationSchema = yup.object().shape({
  nome: yup.string().required("Informe seu nome completo "),
  login: yup.string().required("Informe o email do coordenador "),
  cpf: yup
    .string()
    .matches("[0-9]{11}", "Somente números com 11 dígitos ")
    .required("Somente números com 8 dígitos ")
    .test(validCpf),
  senha: yup
    .string()
    .min(6, "Informe a senha com no mínimo 6 caracteres")
    .required("Informe a senha"),
  confirmeSenha: yup
    .string()
    .oneOf(
      [yup.ref("senha"), null],
      "As senhas não coincidem, digite novamente."
    )
    .required("Digite a sua senha novamente."),
  logradouro: yup.string().required("Informe o logradouro de seu endereço "),
  numero: yup.string().required("Informe o número de seu endereço "),
  cidade: yup.string().required("Informe a cidade de seu endereço "),
  bairro: yup.string().required("Informe o bairro de seu endereço "),
  cep: yup
    .string()
    .matches("[0-9]{8}", "Somente números com 8 dígitos ")
    .required("Somente números com 8 dígitos "),
  telefone1: yup
    .string()
    .matches("[0-9]{10}", "Informe DDD e telefone com no mínimo 10 dígitos ")
    .required("Informe DDD e telefone com no mínimo 10 dígitos "),
    Id_Secretaria: yup.string().required("Informe o nome da secretria do coordenador"),
    matricula: yup.string().matches("[0-9]{7}", "a matricula deve possuir 7 digitos.").required("Informe a matricula do cordenador"),
});

const initialValues = {
  nome: "",
  login:"",
  cpf: "",
  senha: "",
  confirmeSenha: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "RN",
  cep: "",
  telefone1: "",
  telefone2: "",
  Id_Secretaria: "",
  matricula:"",
};


const  FormCriacaoCoordenador = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const params = useParams();
  const numeroInputRef = useRef(null);
  const dispatch = useDispatch();

  const { request, data, errors, loading } = useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.admin.criarCoordenador, values)
     
    },
  });

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    if (data) {
      alert("Coordenador regitrado com sucesso!!!!");
      history.push("/minha-area-admin");
    }

    if (errors) {
      alert(
        errors.message ||
          "Desculpe, algo deu errado. Tente novamente mais tarde."
      );
    }

    setSubmitting(false);
  }, [data, errors, setSubmitting, history]);

 

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Cadastro de Coordenadores</h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados Pessoais</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome:"
                    placeholder="Informe o seu nome completo"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="login"
                    label="login:"
                    placeholder="Informe o e-mail do coordenador."
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    placeholder="Informe o seu CPF"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="3" md="3" sm="11" xs="11">
                  <TextInput
                    formik={formik}
                    field="cep"
                    label="CEP:"
                    readOnly={readOnly}
                    maxLength={8}
                  />
                </Col>

                {!readOnly && (
                  <Col lg="3" md="3" sm="1" xs="1" style={{ alignSelf: "end" }}>
                    <ViaCepLoader
                      disabled={!formik.values.cep || !!formik.errors.cep}
                      cep={formik.values.cep}
                      onFound={(data) => {
                        const { logradouro, bairro, ibge } = data;
                        formik.setValues({
                          ...formik.values,
                          logradouro: logradouro,
                          bairro: bairro,
                          cidade: MunicipiosDoRN[ibge],
                        });
                        numeroInputRef.current.focus();
                      }}
                    />
                  </Col>
                )}

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouro"
                    label="Logradouro:"
                    maxLength={250}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numero"
                    label="Número:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="complemento"
                    label="Complemento:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="bairro"
                    label="Bairro:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Cidade:"
                    readOnly={readOnly}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="senha"
                    label="Senha:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="confirmeSenha"
                    label="Confirme sua senha:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone1"
                    label="Telefone 1:"
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone2"
                    label="Telefone 2:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="6" xs="6">
                  <SelectInputCached
                    formik={formik}
                    field="Id_Secretaria"
                    label="Buscar por Secretaria ou Autarquia"
                    cacheKey="orgaos"
                    readOnly={readOnly}
                    allowEmpty
                  />
                </Col>
                
                  <Col lg="6" md="6" sm="6" xs="6">
                      <TextInput
                        formik={formik}
                        field="matricula"
                        label="Matricula:"
                        readOnly={readOnly}
                      />
                  </Col>

              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={formik.handleSubmit}
                  >
                    <SpinnerLabel
                      loading={formik.isSubmitting}
                      label="Salvar"
                    />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="mt-3"
                    onClick={() => history.goBack()}
                  >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormCriacaoCoordenador;
