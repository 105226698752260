const termosEAcordos = [
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "Justificativa_setur_ESPAÇO_CULTURAL_CASA_DA_RIBEIRA.pdf",
    descricao:
      "O presente Acordo de Cooperação por objeto a implementação do “Complexo Cultural da Rampa” a ser executado na Rua Cel. Flamínio, 1 - Santos Reis, Natal/RN, 59037-155 pela OSC, mediante disponibilização do espaço pela SEEC, conforme especificações estabelecidas no Plano de Trabalho que encontra-se anexo ao termo.",
    entidade: "ESPAÇO CULTURAL CASA DA RIBEIRA",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "acordo_de_cooperacao_com_assurn-11_2021.pdf",
    descricao:
      'Campanha de sub-ação do Programa Estadual Emergencial de Assistência Social RN Chega Junto, denominada "RN Chega Junto no combate a fome", que envolve o recebimento de doações pela sociedade civil e também por empresas, nos supermercados associados à ASSURN, em 68 (sessenta e oito) municípios potiguares, durante o período de 26 de abril de 2021 a 26 de julho de 2021 e doações de 2 (duas) mil cestas básicas por parte da própria ASSURN, através das redes de supermercado associados.',
    entidade: "Associação dos Supermercados do Rio Grande do Norte - ASSURN",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "associação_renascer_construindo_o_futuro-206_2020.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Associação Renascer Construindo o Futuro",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "obras_sociais_dom_bosco-01_2021.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Obras Sociais Dom Bosco",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "associação_comunitaria_caridade_e_luz-200-2020.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Associação Comunitária Caridade e Luz",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "fundação_afonso_lemos-07_2021.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Fundação Afonso Lemos",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "associação_marisqueiras_do_porto-04_2021.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Associação Marisqueiras do Porto",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo:
      "associação_de_proteção_aos_necessitados_de_areia_branca_rn-178_2020.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Associação de Proteção aos Necessitados de Areia Branca/RN",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "casa_de_caridade_adolfo_bezerra_de_menezes-178_2020.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Casa de Caridade Adolfo Bezerra de Menezes",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "comissao_de_justiça_e_paz-10_2021.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Comissão de Justiça e Paz",
  },
  {
    grupo: "acordos-de-cooperacao",
    arquivo: "associação_comunitária_quilombola_de_coqueiros-216_2020.pdf",
    descricao:
      "Estabelecer mútua cooperação entre a SETHAS e a ORGANIZAÇÃO, conforme previsto no art. 4º, inciso IV, art. 33 do Decreto 25.447/2015, Lei nº 13.019/2014 e no Edital de Chamamento Público 03/2019-SETHAS, visando a implementação, no âmbito do território municipal, do Programa Leite Potiguar (PLP). ",
    entidade: "Associação Comunitária Quilombola de Coqueiros",
  },

  {
    grupo: "termos-de-colaboracao",
    arquivo: "centro_feminista_8_de_março-01_2020.pdf",
    descricao:
      "Acolhimento de até 20 (vinte) mulheres vítimas de violência doméstica/familiar, durante o contexto da pandemia.",
    entidade: "Centro Feminista 8 de Março",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "cáritas_diocesana_caicó-01_2020.pdf",
    descricao:
      "Viabilizar o acesso de 130 (cento e trinta) famílias em situação de vulnerabilidade social, quer seja, em situação de rua, refugiadas, apátridas e migrantes, ao benefício do aluguel social durante o período da pandemia do coronavírus (covid-19).",
    entidade: "Cáritas Diocesana Caicó",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "cruz_vermelha_brasileira-01_2020.pdf",
    descricao:
      "Consecução das ações voltadas à garantia da proteção das seguranças socioassistencias das populações vulneráveis no Estado do Rio Grande do Norte no contexto da pandemia do novo Coronavírus (COVID-19), com vistas à consecução de finalidades públicas, objetivando estabelecer uma rede de logística necessária para prover a execução do programa RN Chega Junto e o gerenciamento de suas ações.",
    entidade: "Cruz Vermelha Brasileira Filial no Rio Grande do Norte - CVBRN",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "associação_filantrópica_jorge_gurgel_amaral-17_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade:
      "Associação Filantrópica Jorge Gurgel do Amaral - Las dos Mestres da Vida",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "abrigo_deus_e_caridade-18_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade: "Abrigo Deus e Caridade",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "centro_socio_pastoral_n_senhora_da_conceiçao-19_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade: "Centro Sócio Pastoral Nossa Senhora da Conceição",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "associação_casa_da_boa_ação-20_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade: "Associação Casa da Boa Ação",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "ciade-21_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade:
      "Centro Integrado de Assistência Social da Igreja Evangélica Assembleia de Deus no Rio Grande do Norte - CIADE/RN",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "associação_casa_amparo_proteção_idosos_josé_marimba-22_2020.pdf",
    descricao:
      "Cofinanciamento de benfeitorias na infraestrutura da Instituição de Longa Permanência para Idosos (IPLIs), no intuito de contribuir com a melhoria das condições de vida de seus usuários, em razão dos efeitos sociais decorrentes da pandemia do Coronavírus, causador da Covid-19.",
    entidade: "Associação Casa de Amparo e Proteção de Idosos José Marimba",
  },
  {
    grupo: "termos-de-colaboracao",
    arquivo: "aldeias_infantis_sos_brasil-01_2021.pdf",
    descricao:
      "Implantação do serviço de acolhimento na modalidade Casa Lar, no total de 10 (dez) vagas para crianças e adolescentes, reguladas a partir dos territórios: Seridó, Trairi e Potengi do Rio Grande do Norte.",
    entidade: "Aldeias Infantis SOS Brasil - Caicó/RN",
  },

  {
    grupo: "termos-de-fomento",
    arquivo: "abvip-19_2020.pdf",
    descricao: "Instalação de painés fotovoltaicos de geração de energia. ",
    entidade: "Associação Beneficiente Virgem dos Pobres - ABVIP",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "amai-16_2020.pdf",
    descricao:
      "Adesão de móveis, eletrodomésticos e utilidades domésticas de uso coletivo.",
    entidade: "Associação Macaibense de Acolhimento Institucional - AMAI",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "amico-21_2020.pdf",
    descricao:
      "Contratação de serviços comuns para a Associação Amigos do Coração da Criança, casa de apoio a criança cardiopata do Rio Grande do Norte.",
    entidade: "Associação Amigos do Coração da Criança - AMICO",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "associaçao_renascer_construindo_o_futuro-13_2020.pdf",
    descricao:
      "Construção de sede da associação com o objetivo de criar um espaço para crianças e adolescentes, para incentivar a cultura e o esporte na cidade de Nísia Floresta/RN.",
    entidade: "Associação Renscer Construindo o Furturo - ARCF",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "casa_durval_paiva-18_2020.pdf",
    descricao:
      "Aquisição de gêneros alimentícios, para doação as 257 famílias atendidas pela instituição Casa Durval Paiva.",
    entidade: "Casa Durval Paiva",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "ciade-04_2020.pdf",
    descricao:
      "Garantir a população idosa o acesso ao mundo tecnológico e da comunicação, de forma a mitigar o isolamento, a solidão e maximizando as oportunidades na melhoria da qualidade de vida.",
    entidade:
      "Centro de Assistência Social da Assembleia de Deus do Rio Grande do Norte - CIADE/RN",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "ciijud-12_2020.pdf",
    descricao:
      "Custear o projeto reiventar: técnicas e estratégicas para empreender com sucesso pós-pandemia, gerenciado pelo CIIJUD.",
    entidade:
      "Centro de Intercâmbio Internacional da Juventude e Desenvolvimento - CIIJUD",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "lar_do_anciao_evangelico-22_2020.pdf",
    descricao:
      "Contratar profissionais para garantir o bom desempenho das funções da OSC Convenente",
    entidade: "Lar do Ancião Evangélico - LAE",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "ciade-11_2020.pdf",
    descricao:
      "Garantir alimentação de qualidade com quantidade satisfatória ao atendimento continuado de assistência às idosas acolhidas na instituição de longa permanência.",
    entidade:
      "Centro de Assistência Social da Assembleia de Deus do Rio Grande do Norte - CIADE/RN",
  },
  {
    grupo: "termos-de-fomento",
    arquivo: "obras_sociais_dom_bosco-08_2020.pdf",
    descricao:
      "Proporcionar um auxílio de custo ao projeto oficina escola, uma iniciativa que visa garantir o financiamento para ações de educação profissional desenvolvida na oficina dom bosco e possibilitar melhorias na infraestrutura das salas de aula, banheiros e espaços de tornearia e solda da oficina, no município de Areia Branca/RN.",
    entidade: "Obras Sociais Dom Bosco",
  },
];

export default termosEAcordos;
