import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { FaCertificate } from "react-icons/fa";
import { BiBulb } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import ImgDemoFotoPerfil from "../assets/img/foto-perfil-placeholder.png";
import { AppContext } from "../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux/auth";
import PropostaLista from "./Proposta/PropostaLista/PropostaLista";
import { Link } from "react-router-dom";
// import { BiNews } from "react-icons/bi";
// import { FaCog } from "react-icons/fa";
// import { MdEmail } from "react-icons/md";
// import { RiGitPullRequestFill } from "react-icons/ri";
// import { Link } from "react-router-dom";

const MinhaArea = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const auth2 = useSelector((state) => state.auth);
  const auth = JSON.parse(sessionStorage.getItem("user")).user;
  const dispatch = useDispatch();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>

<Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Minha Área</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column mt-5">
            <Row className="g-4 g-lg-4">
              <Col md="6" className="me-auto ms-auto text-center">
                <Link to="/minha-area/usuario">
                  <img
                    src={ImgDemoFotoPerfil}
                    alt="Foto do usuário"
                    className="img rounded-pill w-25 box-shadow"
                  />
                </Link>
                <h3>{auth.nome}</h3>
                <p className="mt-2 fw-bolder title-uppercase text-gray">
                  {auth.email}
                </p>
              </Col>
            </Row>

            <Row className="g-4 g-lg-4">
              <Col md="12" className="me-auto ms-auto text-center">
                <ul id="profile-menu" className="mt-md-3 mt-lg-3">
                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/minha-area/usuario"
                    >
                      <BsFillPersonFill />{" "}
                      <span className="d-md-inline">Seus dados</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/minha-area/proposta"
                    >
                      <BiBulb />{" "}
                      <span className="d-md-inline">Nova Proposta</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to="/cadastro-oscs"
                    >
                      < FaCertificate />{" "}
                      <span className="d-md-inline">Certificar Osc</span>
                      
                    </Link>
                  </li>

                  

                  {/*<li>
                    <a
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      href="#perfil"
                    >
                      <RiGitPullRequestFill /> Suas Solicitações
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      href="#perfil"
                    >
                      <MdEmail /> Comunicações
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      href="#perfil"
                    >
                      <FaCog /> Configurações
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/publicacoes-lista"
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                    >
                      <BiNews /> Publicações
                    </Link>
                  </li> */}
                  <li>
                    <a
                      className="btn btn-outline  btn-outline-danger  btn-sm rounded-pill"
                      href="#none"
                      onClick={() => dispatch(authActions.signout())}
                    >
                      <IoMdLogOut /> Sair
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PropostaLista />
    </>
  );
};

export default MinhaArea;
