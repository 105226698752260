import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { MunicipiosAsOptions, MunicipiosDoRN } from "../../../components/UI/Formik/SelectInput/SelectInput.options";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { validCnpj } from "../../../components/UI/Formik/Validations/Validations";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../../components/UI/ViaCepLoader/ViaCepLoader";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import PropostaDetalheArquivos from "./PropostaDetalheArquivos";
import PropostaDarAndamento from "../PropostaDarAndamento/PropostaDarAndamento"

const validationSchema = yup.object().shape({
  tx_titulo: yup.string().required("Informe um título para a proposta "),
  tx_descricao: yup.string().required("Informe uma descrição para a proposta "),
  id_area: yup.string().required("Selecione uma área para a proposta "),
  tx_interesse: yup.string().required("Descreva o interesse social na proposta "),
  tx_beneficios: yup.string().required("Descreva os benefícios sociais trazidos pela implementação da proposta "),
  tx_diagnostico: yup.string().required("Descreva a realidade que a implementação da proposta irá modificar "),
  tx_viabilidade: yup.string().required("Descreva aspectos que favorecem a viabilidade da proposta"),

  tx_area: yup.string().when("id_area", {
    is: "16",
    then: yup.string().required("Informe a área social relativa à proposta"),
    otherwise: yup.string().nullable().strip(),
  }),
});

const initialValues = {
  id_proposta: null,
  Id_secretaria : "",
  tx_titulo: "",
  tx_descricao: "",
  id_area: "",
  tx_area: "",
  tx_interesse: "",
  tx_diagnostico: "",
  tx_viabilidade: "",
  tx_beneficios: "",
  tx_tipo: "osc",
};


const PropostaDetalhe = (props) => {
  const numeroInputRef = useRef(null);
  const { setBackgroundClassList } = useContext(AppContext);
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const { request, data, errors, loading, requestId } = useApiClient();
  const [isRevisor, setIsRevisor] = useState(false);
  const Osc = auth.user.osc;

  

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      request(apiRoutes.main.propostas.porIdAuth, { routeParams: { id } });
    }
  }, [request, id]);

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      let payload = {
        id: values.id_proposta,
        IdOsc : Osc,
        IdSecretaria: values.Id_secretaria,
        titulo: values.tx_titulo,
        descricao: values.tx_descricao,
        idArea: values.id_area,
        area: values.id_area !== "16" ? "" : values.tx_area,
        tipo: values.tx_tipo,
        interesseSocial: values.tx_interesse,
        diagnostico: values.tx_diagnostico,
        viabilidade: values.tx_viabilidade,
        beneficios: values.tx_beneficios,
      };
      actions.setStatus(null);
      request(apiRoutes.main.propostas.salvar, payload, {
        requestId: "salvar",
      });
    },
  });

  const { setValues, setSubmitting, setStatus, setFieldValue } = formik;

  useEffect(() => {
    
    if (!requestId && data) setValues(data);
  }, [data, requestId, setValues]);

  useEffect(() => {
    if (requestId !== "salvar" && requestId !== "submeter") return;

    if (data && requestId === "salvar") {
      alert(
        "Alterações no rascunho salvas com sucesso. IMPORTANTE: Somente após a submissão clicando em 'Submeter Proposta' sua proposta será encaminhada para validação."
      );
      
      setFieldValue("id_proposta", `${data}`);
    }

    if (data && requestId === "submeter") {
      alert("Proposta submetida com sucesso. Você será informado por e-mail a cada mudança de status. ");
      history.goBack();
    }

    if (errors) {
      console.error(errors);
      setStatus(errors.message || "Desculpe, ocorreu um erro durante essa operação. Tente novamente mais tarde.");
    }

    setSubmitting(false);
  }, [data, errors, requestId, history, setSubmitting, setStatus, setFieldValue]);

  useEffect(() => {
    if(auth.user.papeis[0]=== "SU" || auth.user.papeis[0] === "REVISOR") {
      setIsRevisor(true);
    }
  },[auth.user.papeis, isRevisor ])

  const perfilOsc = formik.values.tx_tipo === "osc";
  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Manifestação de interesse Social</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form onSubmit={formik.handleSubmit}>
    
              <Row className="d-flex g-4">
                <Col md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados da proposta</h3>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="tx_titulo" label="Título da Proposta" readOnly={readOnly} />
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="tx_descricao"
                    label="Descrição Resumida da Proposta"
                    rows={4}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="id_area"
                    label="Área de Atuação"
                    defaultText="Selecione uma área de atuação"
                    cacheKey="areas"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="Id_secretaria"
                    label="Selecione a Secretaria ou Autarquia que irá receber a proposta."
                    cacheKey="orgaos"
                    readOnly={readOnly}
                    allowEmpty
                  />
                </Col>

                {formik.values.id_area === "16" && (
                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      field="tx_area"
                      label="Especifique a Área de Atuação:"
                      readOnly={readOnly}
                      maxLength={250}
                    />
                  </Col>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="tx_interesse"
                    label="Indicação do interesse público envolvido"
                    placeholder="Descreva aqui"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="tx_diagnostico"
                    label="Diagnóstico da realidade que se quer modificar, aprimorar
                      ou desenvolver"
                    placeholder="Descreva aqui"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="tx_viabilidade"
                    label="Indicação da viablidade"
                    placeholder="Descreva aqui"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="tx_beneficios"
                    label="Indicação dos beneficios"
                    placeholder="Descreva aqui"
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
               {formik.values.tx_tipo === "osc" && <PropostaDetalheArquivos id={id || formik.values.id_proposta} readOnly={readOnly} />}
              {!!formik.status && (
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <Alert variant="danger">{formik.status}</Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
            
            
                {/**{!isRevisor && (
                  <PropostaDarAndamento id = {id}  id_status={formik.values.id_status} />
                )}**/}
             

                
                  <Button className="mt-3" onClick={() => history.goBack()}>
                    Voltar
                  </Button>

                  &nbsp;&nbsp;
                  {!readOnly && (
                    <Button
                      onClick={() => {
                        
                        formik.handleSubmit();
                      }}
                      className="mt-3 ms-3"
                    >
                      <SpinnerLabel loading={formik.isSubmitting} label="Salvar Rascunho" />
                    </Button>
                  )}
                  
                  &nbsp;&nbsp;
                  {!readOnly && (id || formik.values.id_proposta) && (
                    <Button
                      className="mt-3 ms-3"
                      onClick={() => {
                        
                        formik.setSubmitting(true);
                        request(
                          apiRoutes.main.propostas.submeter,
                          { id: id || formik.values.id_proposta },
                          { requestId: "submeter" }
                        );
                      }}
                    >
                      Submeter Proposta
                    </Button>
                  )}
                
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropostaDetalhe;


