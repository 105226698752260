import { Form } from "react-bootstrap";

const SelectInput = (props) => {
  const { formik, field, label, placeholder, readOnly } = props;
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        id={field}
        name={field}
        placeholder={placeholder}
        value={formik.values[field]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors[field] && formik.touched[field]}
        readOnly={readOnly}
      >
          {props.children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectInput;
