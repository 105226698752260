import { forwardRef } from "react";
import { Form } from "react-bootstrap";

const TextInput = (props, ref) => {
  const { formik, field, label, placeholder, readOnly, maxLength, type } = props;
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={field}
        type={type || "text"}
        ref={ref}
        name={field}
        placeholder={placeholder}
        value={formik.values[field]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors[field] && formik.touched[field]}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default forwardRef(TextInput);
