import React, { useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap';

const Sobre = () => {

  useEffect(() => {

    //background personalizado
    document.body.classList.remove('bg-cover');
    document.body.classList.add('background-2');

  });

  return (

    <>

      {/* //Cabeçalho da página  */}
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center">
            <h1 className="fw-100 text-white mt-5">O que é a OSC?</h1>

          </Col>

        </Row>
      </Container>

      {/* //Conteúdo da página  */}

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Row className="g-4 g-lg-4">
              <Col md="8" className="me-auto ms-auto">
                <br />
                <h2 className="mt-5 text-center">Organizações da Sociedade Civil</h2>
                <h5 className="mt-5 text-center fs-6">Em conformidade com o estabelecido no Art. 2°, I, da Lei 13.019/2014, uma
                  Organização da Sociedade Civil (OSC) pode ser definida como uma pessoa jurídica de direito
                  privado sem fins lucrativos que, consequentemente, não irá distribuir seus ganhos para
                  nenhuma das pessoas que a compõem, sejam elas sócios, associados, conselheiros, diretores,

                  dentre outros, cujo caixa é todo destinado para a efetivação do seu objeto social, de forma
                  imediata ou por meio da constituição de fundo patrimonial ou fundo de reserva.
                  Ressalta-se que a expressão “Organizações da Sociedade Civil (OSC)” faz referência,
                  na realidade prática, às atuações de Organizações Não Governamentais (ONGs), por exemplo,
                  embora na legislação seja utilizado esse primeiro termo.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="me-auto ms-auto">
                <h2 className="mt-2 text-center">Quais os requisios para que as Organizações da Sociedade Civil possam celebrar
                  parcerias com a Administração pública?</h2>
                <div>
                  <ul>
                    <li>A. Sobre as questões formais inerentes às Organizações da Sociedade Civil (OSC)
                      deverá ser apresentado:
                      <ul>
                        <li>A.1 Certidão de regularidade fiscal;</li>
                        <li>A.2 Certidão previdenciária e tributária;</li>
                        <li>A.3 Certidão de contribuições e de dívida ativa;</li>
                        <li>A.4 Certidão de existência jurídica expedida pelo cartório de registro civil ou cópia do
                          estatuto da entidade;</li>
                        <li>A.5 Ata de eleição do quadro dirigente;</li>
                        <li>A.6 Comprovante de endereço da OSC;</li>
                        <li>A.7 Relação atualizada dos dirigentes (contendo nome, endereço, RG e CPF).</li>
                      </ul>
                    </li>
                    <li>B. Ademais, o Estatuto da OSC deverá conter:
                      <ul>
                        <li>B.1 Objetivos voltados para a promoção de atividades e finalidades de relevância
                          pública e social.</li>
                        <li>B.2 Cláusula de transferência do patrimônio líquido, em caso de dissolução, a outra
                          pessoa jurídica de igual natureza e preferencialmente com igual objeto social.</li>
                        <li>B.3 Cláusula prevendo a escrituração de acordo com as Normas Brasileiras de
                          Contabilidade.</li>
                      </ul>
                    </li>
                    <li>C. Sobre o tempo de existência mínimo da OSC (desde a aquisição do seu CNPJ):
                      <ul>
                        <li>C.1 3 anos para parcerias com a União.</li>
                        <li>C.2 2 anos para parcerias com o Estado e o Distrito Federal.</li>
                        <li>C.3 1 ano para parcerias com Municípios.</li>
                      </ul>
                    </li>
                    <li>D. Da atuação da OSC:
                      <ul>
                        <li>D.1 Experiência prévia na realização, com efetividade, do objeto da parceria ou de
                          natureza semelhante.</li>
                        <li>D.2 Instalações, condições materiais e capacidade técnica e operacional para o
                          desenvolvimento das atividades ou projetos previstos na parceria e o cumprimento das metas
                          estabelecida.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="me-auto ms-auto">
                <h2 className="mt-2 text-center">Prestação de Contas das OSC</h2>
                <p className="text-justify">A Organização da Sociedade Civil (OSC) deve dar um retorno á Administração Pública
                  acerca da atividade ou projeto desenvolvido. Neste sentido, a Lei 13.019/2014 implementa o
                  instituto da prestação de contas, a qual significa que a OSC deverá fornecer elementos, para
                  a Administração Pública, que permitam a identificação do cumprimento das metas e objetivos,
                  assim como que a finalidade tenha sido alcançada.</p>
                <p className="text-justify">Quando a OSC não comprovar o cumprimento das metas e objetivos, haverá a
                  solicitação de documentos complementares para avaliar a sua atuação. Ademais, a prestação
                  de contas deve ser prestada eletronicamente.</p>
                <p className="text-justify">Acerca dos prazos, a OSC deverá prestar contas até 90 dias após a finalização da
                  atividade/projeto, contados a partir da data de finalização da parceria, sendo possível a sua
                  prorrogação em mais 30 dias, caso seja apresentada justificativa para tanto. Caso a parceria
                  tenha uma duração maior do que o prazo de um ano, a prestação de contas deverá ser
                  realizada anualmente.</p>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="me-auto ms-auto">
                <h2 className="mt-2 text-center">Sobre a responsabilidade das organizações da Sociedade Civil e as sanções previstas</h2>
                <p className="text-justify">Quando verificado, pela Administração Pública, que a execução da parceria com a
                  Organização da Sociedade Civil (OSC) ocorreu de forma distinta daquela prevista no Plano de
                  Trabalho, além das demais normas da Lei 13.019/2014 e eventuais normas específicas, a
                  Administração Pública poderá aplicar algumas sanções em face da OSC, as quais podem ser,
                  em conformidade com o art. 75 da referida legislação:</p>
                <ul>
                  <li>Art. 75.
                    <ul>
                      <li>I - advertência;</li>
                      <li>II - suspensão temporária da participação em chamamento público e impedimento
                        de celebrar parceria ou contrato com órgãos e entidades da esfera de governo da
                        administração pública sancionadora, por prazo não superior a dois anos;</li>
                      <li>III - declaração de inidoneidade para participar de chamamento público ou celebrar
                        parceria ou contrato com órgãos e entidades de todas as esferas de governo,
                        enquanto perdurarem os motivos determinantes da punição ou até que seja
                        promovida a reabilitação perante a própria autoridade que aplicou a penalidade, que
                        será concedida sempre que a organização da sociedade civil ressarcir a
                        administração pública pelos prejuízos resultantes e após decorrido o prazo da sanção
                        aplicada com base no inciso II.</li>
                    </ul>
                  </li>
                </ul>
                <p className="text-justify">Ressalta-se que as sanções previstas nos incisos II e III são de competência exclusiva
                  de Ministro de Estado ou de Secretário Estadual, Distrital ou Municipal.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>


  )
}

export default Sobre