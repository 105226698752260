import { useFormik } from "formik";
import React, { useContext, useEffect, useRef,useState } from "react";
import { Col, Container, Row, Alert,  Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";



const validationSchema = yup.object().shape({
  tx_status: yup.string().required("Informe um status para a proposta."),
  tx_justificativa: yup.string().required("Informe uma justificativa de acordo com o andamento escolhido."),
  
 
});

const initialValues = {
  id_proposta:"",
  tx_status :"",
  tx_justificativa: ""

};



const ModalProposta = (props) => {
  const history = useHistory();
  const { request, data, errors, loading, requestExtra } = useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      
      request(apiRoutes.main.propostas.propostaDarAndamento, values, {
        requestId: "salvar",
      });
    },
  });

  
  /**
    1  "Rascunho"	"-"
    2  "Submetida"	"Proposta recebida. A validação das informações deverá ocorrer em até 20 dias úteis."
    3	"Criticada"	"Existem correções pendentes na proposta. Faça as correções necessárias e submeta sua proposta novamente."
    4	"Validada"	"Proposta validada e na fila para análise."
    5	"Em análise""Proposta em processo de análise."


    6	"Aceita"	"Parabéns, proposta aceita! É hora de arregaçar as mangas!"
    7	"Rejeitada"	"Lamentamos informar que a proposta não irá seguir para a próxima etapa do processo. "
    8	"Arquivada"	"Proposta a---rquivada. " ocultar barra ferramentas do proponente/criar uma para o revisor. 
*/
  
const readOnly = loading;
  return (
    <>
    <Form>
        <Row className="d-flex g-4">
                  <Row>
                      <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                     
                          <SelectInput
                          formik={formik}
                          field="tx_status"
                          label="Andamento:"
                          >
                           { (props.id_status === 2 || props.id_status === 3 || props.id_status === 4 || props.id_status === 5 )   && 
                           <>
                            <option value="Criticada">Criticada</option>
                            <option value="Validada">Validada</option>
                            <option value="Em análise">Em análise</option>
                          </>}

                          {props.id_status === 5 &&
                          <>
                            <option value="aceita"> aceitar</option>
                            <option value="rejeitada">rejeitar</option>
                            <option value="arquivada">arquivar</option>
                          </>}
                     
                          </SelectInput>
                      </Col>
                  </Row>
                    
            
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
                      <TextareaInput
                        formik={formik}
                        field="tx_justificativa"
                        label="Justificativa do andamento:"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col  md="12" className="me-auto ms-auto text-center mt-5 p-2">

                          {/**<Button  disabled={readOnly} onClick={formik.handleSubmit}>
                             <SpinnerLabel loading={formik.isSubmitting}  label="Salvar"/>
                          </Button>
                          

                          <Button  onClick={() => history.goBack()}>
                             Voltar
                          </Button>**/}
                        
                    </Col>
                  </Row>
            </Row>
      </Form>
    </>
  );
};

export default ModalProposta;


