import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/core.scss";
import { AppContextProvider } from "./context/AppContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import appConfig from "./config/app.config";

console.log(`Portal OSC v.${appConfig.clientVersion}`);
console.log(`Release ${appConfig.clientRelease}`);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
