import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgaos: [],
  areas: [],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    updateCache(state, action) {
      state[action.payload.cacheKey] = action.payload.items;
    }
  },
});

export const cacheActions = cacheSlice.actions;

export default cacheSlice.reducer;
