import React, { useContext, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { Col, Container, Row, Button, Toast } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import DateInput from "../../../components/UI/Formik/DateInput/DateInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import SelectFileInputGeneral from "../../../components/UI/SelectFileInput/SelectFileInputGeneral";
import { useState } from "react";
import { toInteger } from "lodash";


const validationSchema = yup.object().shape({
  RazaoSocialEntidade: yup.string().required("Informe a Razão Social"),
  descricao: yup
    .string()
    .required("Informe uma descrição resumida do objetivo do acordo."),
  idModalidadeTermosAcordos: yup.string().required("Escolha uma Modalidade"),
  arquivo: yup.string().required("Escolha um arquivo"),
  arquivoNome: yup.string().required("Escolha um arquivo"),
  Nprocesso: yup.string().required("Informe o Numero do processo SEI"),
  Tm_inicio_vigencia: yup.date().required("Informe a data inicial da Vigência"),
  Tm_fim_vigencia: yup.date().required("Informe a data final da Vigência"),
  
});

const initialValues = {
  RazaoSocialEntidade: "",
  descricao: "",
  idModalidadeTermosAcordos: "",
  arquivo: "",
  arquivoNome: "",
  Nprocesso:"",
  motivo:"",
  idAndamento:0,
  Tm_inicio_vigencia: new Date(),
  Tm_fim_vigencia: new Date(),
};

const LançamentoTermosAcordos = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const { request, data, errors, loading, requestId } = useApiClient();
  const { request: requestAndamento, data: dataAndamento, errors:errorsAndamento, loading:loadingAndamento, requestId:requestIdAndamento  } = useApiClient();
  const [termosAcordos, setTermosAcordos] = useState();
  
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });


  const handleSubmit = useCallback(
    
    (values) => {
      request(apiRoutes.main.servidor.uploadTermosAcordos, values);
    },
    [request]
  );
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit,
  });
  

  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      arquivo: uploadPayload.arquivo,
      arquivoNome: uploadPayload.arquivoNome,
    }));
  };
  

  useEffect(() => {
    requestAndamento(apiRoutes.main.servidor.listarAndamento);
  }, [requestAndamento]);

  useEffect(() => {
    if (dataAndamento) setTermosAcordos(dataAndamento);
  }, [dataAndamento]);

  useEffect(() => {
    if ((data || errors) && !loading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }

    if (data && !loading && !errors) {
      setShow(true);
      setTimeout(window.location.reload(), 80000);
    }

    if (requestId === "load" && data && !formik.values.loaded) {
      formik.setValues({ ...data.result, loaded: true });
    }

    if (errors && !loading) formik.setErrors({ ...formik.errors, ...errors });
  }, [formik, data, errors, requestId, loading]);

  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");


  return (
    <>
      <Container id="cabecalho-pagina" fluid className="">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Lançamento dos termos e acordos
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: "relative",
            minHeight: "100px",
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={80000}
            autohide
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast.Header>
              <strong className="me-auto">Sucesso</strong>
            </Toast.Header>
            <Toast.Body>Termo salvo com sucesso!</Toast.Body>
          </Toast>
        </div>

        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3">Dados dos termos</h2>
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="RazaoSocialEntidade"
              label="Titulo:"
              readOnly={readOnly}

            />
          </Col>
          <Col lg="5" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="Nprocesso"
              label="Nº processo SEI:"
              readOnly={readOnly}
            />
          </Col>
          <Col lg="3" md="5" sm="12" xs="12">
          <DateInput
              formik={formik}
              type="date"
              field="Tm_inicio_vigencia"
              label="Inicio da vigência:"
              readOnly={readOnly}
            /></Col>
            <Col lg="3" md="5" sm="12" xs="12">
            <DateInput
            formik={formik}
            type="date"
            field="Tm_fim_vigencia"
            label="Fim da vigência:"
            readOnly={readOnly}
          />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <TextareaInput
              formik={formik}
              field="descricao"
              label="Descrição"
              placeholder="Descreva resumidamente o objetivo do acordo"
              readOnly={readOnly}
            />
          </Col>
          
         
            <Col lg="3" md="5" sm="12" xs="12">
            <SelectInput
              formik={formik}
              field="idModalidadeTermosAcordos"
              label="Modalidade:"
              readOnly={readOnly}
            >
              <option value="#">Selecione</option>
              <option value="1">Termo de Colaboração</option>
              <option value="2">Termo de Fomento</option>
              <option value="3">Acordo de Cooperação</option>
            </SelectInput>
            </Col>
            
            <Col lg="3" md="5" sm="12" xs="12">
            
              <SelectInput
                formik={formik}
                field="idAndamento"
                label="Andamento:"
                readOnly={readOnly}
                

              >
                <option value="#" >Selecione</option>
                {(dataAndamento || {}).length &&
                  dataAndamento.map((itemDataAndamento) => (
                    <option value={toInteger( itemDataAndamento.id_andamento_termos_acordos)}>
                      {itemDataAndamento.tx_nome}
                    </option>
                  ))}
              </SelectInput> 
              </Col>  
              
              {[3,4].includes(toInteger( formik.values.idAndamento)) && (
            <Col lg="3" md="5" sm="12" xs="12">
            
          <TextInput
            formik={formik}
            field="motivo"
            label="Motivo:"
            readOnly={readOnly}

          />
          </Col>
        )}
          

          <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
            <SelectFileInputGeneral
              disabled={loading}
              show={!readOnly}
              field="arquivo"
              handleArquivo={handleArquivo}
            />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <Button
              className="mt-3"
              disabled={readOnly}
              // onClick={() =>}
             onClick={()=>{if ([1,2].includes(toInteger( formik.values.idAndamento))){
              formik.setFieldValue('motivo', "#");
              
            }; formik.handleSubmit()}}
            >
              <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
            </Button>
            &nbsp;&nbsp;
            <Button className="mt-3" onClick={() => history.goBack()}>
              Voltar
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LançamentoTermosAcordos;
