import {
  OverlayTrigger,
  Tooltip,
  Button,
  Card,
  Col,
  Row,
  Toast,
} from "react-bootstrap";
import moment from 'moment';

import {
  AiOutlineDownload,
  AiOutlineExclamationCircle,
  AiOutlineCopy,
} from "react-icons/ai";
import appConfig from "../../config/app.config";
import { apiRoutes } from "../../hooks/apiClient";
import { method } from "lodash";
import axios from "axios";
import { useState } from "react";

const TermosEAcordosLista = (props) => {
  const { itens, titulo } = props;
  const cores_andamento = [
    "btn-andamento",
    "btn-encerrado",
    "btn-suspenso",
    "btn-analise",
  ];
  

  
  const [copiado, setCopiado] = useState(false);
  const [show, setShow] = useState(false);
  const onButtonClick = (item) => {
    fetch(
      `https://api.portalosc.sethas.rn.gov.br/api/termosacordos/baixar/termo/${item.id_termo}`
    )
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = item.nome;
        a.click();
      });
  };
  // fetch(`http://localhost:5000/api/termosacordos/baixar/termos/1`).then(res =>
  // {
  //   console.log(res);
  //   const link = document.createElement("a");
  //   res.blob().then(blob =>
  //   {
  //     link.href = URL.createObjectURL(blob);
  //     link.download = item.nome;
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //     //console.log(res)
  //   })
  // })
  const processo_copiado = () => {
    clearTimeout(processo_copiado);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1000);
    return () => clearTimeout(processo_copiado);
  };

  const formatarData = (valor)=>{
    const dataFormatada = moment(valor).format('DD/MM/YYYY');
    return dataFormatada
  }
  return (
    <>
      {/* <h1>hello world</h1>
    {itens?.map((item)=>
      <p>{item.modalidade} </p>
      )
      
    } */}

      <Row className="mt-2">
        <Col>
          <h4 className="mt-4 mb-3 text-primary">{titulo} </h4>
        </Col>
      </Row>

      { itens?.map((item) => (
          
        <Row key={item.nome} className="mt-2 d-flex">
               

          <Col>
          <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 9999  
          }}
      >
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={80000}
          autohide
          className="toast-top"
        >
          <Toast.Header>
            <strong className="me-auto">Sucesso</strong>
          </Toast.Header>
          <Toast.Body>Copiado</Toast.Body>
        </Toast>
      </div>
            <Card>
              <Card.Img variant="top" />
              <Card.Body>
                <Row className="justify-content-between">
                  <Col md="12" lg="8">
                    <Card.Title className="h4">{item.entidade}</Card.Title>
                    <Card.Text>{item.descricao}</Card.Text>
                    <Card.Text className="card-grupo ">
                      GRUPO: {item.modalidade.toUpperCase()}
                    </Card.Text>
                  </Col>

                  <Col
                    md="3"
                    sm="6"
                    xs="12"
                    className=" d-flex flex-column align-items-center"
                  >
                    <Col className="d-flex flex-column align-items-center justify-content-start">
                      <Card.Subtitle>Vigência</Card.Subtitle>
                      <Card.Text className="description mb-1 text-center">
                        Inicio: {formatarData(item.data_inicio)}
                      </Card.Text>
                      <Card.Text className="description text-center ">
                        Fim: {formatarData(item.data_fim)}
                      </Card.Text>
                      <Card.Subtitle>processo SEI: </Card.Subtitle>
                      <Card.Text
                        className="medium"
                        onClick={() => {
                          navigator.clipboard.writeText(item.processo);
                          setCopiado(true);
                          processo_copiado();
                        }}
                      >
                        <AiOutlineCopy />
                        {item.processo}
                      </Card.Text>
                      <Card.Text className="mt-2 d-flex">
                        <OverlayTrigger 
                          placement="bottom"
                          overlay={<Tooltip className="medium">{item.tx_motivo}</Tooltip>}
                        >
                          <span
                            className={
                              cores_andamento[item.andamento - 1] + " btn-geral"
                            }
                          >
                            
                            {item.andamento_nome+" "}
                            {item.andamento !== 1 && item.andamento !== 2 ? (
                            <AiOutlineExclamationCircle />
                            ) : (
                              ""
                            )}
                          </span>
                        </OverlayTrigger>
                      </Card.Text>
                    </Col>
                  </Col>
                  <Card.Text>
                <Button
                  variant="link"
                  onClick={() => onButtonClick(item)}

                >
                  <AiOutlineDownload /> {item.nome}
                </Button>
              </Card.Text>
                </Row>
                
              </Card.Body>
             
            </Card>
          </Col>
          
        </Row>
      ))}
    </>
  );
};

export default TermosEAcordosLista;
