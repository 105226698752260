import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Accordion,
} from "react-bootstrap";
import { useHistory } from "react-router";
import SelectInputCached from "../components/UI/Formik/SelectInput/SelectInput.cached";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import { AppContext } from "../context/AppContext";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import * as yup from "yup";
import PropostaListaPublica from "./Proposta/PropostaListaPublica/PropostaListaPublica";

const validationSchema = yup.object().shape({
  protocolo: yup.string().matches(/^\d{1,}\/\d{4}/g, "Formato inválido."),
  ano: yup.string().matches("[0-9]{4}", "Somente números. 4 dígitos."),
});

const initialValues = {
  protocolo: "",
  ano: "",
  idArea: "",
};

const ManifestacoesInteresseSociais = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const { request, data, errors, loading } = useApiClient();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.propostas.pesquisar, values);
    },
  });

  useEffect(() => {
    setBackgroundClassList(["background-3"]);
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    setSubmitting(false);
  }, [data, errors, setSubmitting]);

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Manifestações de Interesses Sociais
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Accordion className="mt-5">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="w-100 text-left"
                  >
                    O que é um procedimento de Manifestação de Interesse Social?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p className="text-justify  mt-2">
                      O Procedimento de Manifestação de Interesse Social (PMIS)
                      é o instrumento pelo qual as organizações da sociedade
                      civil, movimentos sociais e cidadãos poderão apresentar
                      propostas à Administração Pública, para que esta avalie a
                      possibilidade de realização de um chamamento público com o
                      objetivo de eventualmente celebrar parceria.
                    </p>

                    <p className="text-justify  mt-1">
                      É uma iniciativa presente na Lei Federal nº 13.019/14,
                      incentivando a presença da sociedade civil no
                      desenvolvimento de políticas públicas e de parcerias
                      administrativas, fortalecendo os meios de participação
                      direta da população e das organizações civis.
                    </p>

                    <p className="text-justify  mt-1">
                      A Manifestação de Interesse Social (MIS) deve estar
                      relacionada com ações de interesses públicos e coletivos,
                      sendo incabível para proposição de demandas individuais,
                      promovendo, especialmente, os direitos sociais, como
                      aqueles expostos no art.6º da Constituição Federal de
                      1988:{" "}
                      <em>
                        “a educação, a saúde, a alimentação, o trabalho, a
                        moradia, o transporte, o lazer, a segurança, a
                        previdência social, a proteção à maternidade e à
                        infância, a assistência aos desamparados”
                      </em>
                      , entre outros.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="w-100 text-left"
                  >
                    Como uma OSC pode submeter propostas de manifestação de
                    interesse social?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p className="text-justify  mt-2">
                      Os requisitos para elaboração das propostas e seu
                      encaminhamento para a Administração Pública estão
                      presentes no art. 19 da Lei Federal nº 13.019/2014, assim,
                      havendo interesse, qualquer cidadão, movimento ou
                      organização da sociedade civil poderá enviar a proposta em
                      plataforma disponibilizada pela Administração Pública
                      Estadual, devendo conter:
                    </p>

                    <ul>
                      <li className="mt-3">
                        I - identificação do subscritor da proposta;{" "}
                      </li>

                      <li className="mt-3">
                        II - indicação do interesse público envolvido;
                      </li>

                      <li className="mt-3">
                        III - diagnóstico da realidade que se quer modificar,
                        aprimorar ou desenvolver e, quando possível, indicação
                        da viabilidade, dos custos, dos benefícios e dos prazos
                        de execução da ação pretendida.
                      </li>
                    </ul>

                    <p className="text-justify  mt-1">
                      Recebida a(s) proposta(s) a Administração Pública Estadual
                      irá publicá-la(s) em seu sítio eletrônico e analisará a{" "}
                      <b>conveniência</b> e <b>oportunidade</b> para realização
                      do Procedimento de Manifestação de Interesse Social.
                    </p>

                    <p className="text-justify  mt-1">
                      Se a(s) proposta(s) forem analisadas favoravelmente, a
                      Administração Pública Estadual dará início ao Procedimento
                      de Manifestação de Interesse Social e realizará a oitiva
                      da sociedade sobre o tema proposto, através de audiências
                      públicas, consultas públicas, seminários, e outros
                      mecanismos de participação.
                    </p>

                    <p className="text-justify  mt-1">
                      A Organização da Sociedade Civil (OSC) que apresentar
                      proposta ou participar do Procedimento de Manifestação de
                      Interesse Social{" "}
                      <b>
                        não terá sua participação impedida em eventual
                        chamamento público decorrente do PMIS
                      </b>
                      .
                    </p>

                    <p className="text-justify  mt-1">
                      É ainda importante lembrar que:
                    </p>

                    <ul>
                      <li className="mt-3">
                        (a) A realização do Procedimento de Manifestação de
                        Interesse Social <b>não</b> implicará necessariamente na
                        execução do chamamento público, que acontecerá de acordo
                        com os interesses da administração.{" "}
                      </li>

                      <li className="mt-3">
                        (b) A realização do Procedimento de Manifestação de
                        Interesse Social <b>não</b> poderá ser utilizada para
                        dispensar o chamamento público na celebração de
                        parceria.
                      </li>

                      <li className="mt-3">
                        (c) <b>É vedado</b> condicionar a realização de
                        chamamento público ou a celebração de parceria à prévia
                        realização de Procedimento de Manifestação de Interesse
                        Social.
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col className="text-center my-4 mb-5">
            <Button
              variant="primary"
              onClick={() => history.push("/osc/manuntencao")}
            >
              Faça sua proposta
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light  p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="protocolo"
                    label="Protocolo (Número/Ano):"
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="ano"
                    label="Ano:"
                    maxLength={4}
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="6" md="6" sm="6" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="idArea"
                    label="Área de Atuação:"
                    cacheKey="areas"
                    readOnly={readOnly}
                    allowEmpty
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button
                    variant="primary"
                    className="me-3"
                    onClick={formik.handleSubmit}
                  >
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <PropostaListaPublica data={data} errors={errors} loading={loading} />
      </Container>
    </>
  );
};

export default ManifestacoesInteresseSociais;
