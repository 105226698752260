export const MunicipiosAsOptions = () => {
  return (
    <>
      <option value="">Selecione a cidade</option>
      <option value="Acari">Acari</option>
      <option value="Açu">Açu</option>
      <option value="Afonso Bezerra">Afonso Bezerra</option>
      <option value="Água Nova">Água Nova</option>
      <option value="Alexandria">Alexandria</option>
      <option value="Almino Afonso">Almino Afonso</option>
      <option value="Alto do Rodrigues">Alto do Rodrigues</option>
      <option value="Angicos">Angicos</option>
      <option value="Antônio Martins">Antônio Martins</option>
      <option value="Apodi">Apodi</option>
      <option value="Areia Branca">Areia Branca</option>
      <option value="Arês">Arês</option>
      <option value="Campo Grande">Campo Grande</option>
      <option value="Baía Formosa">Baía Formosa</option>
      <option value="Baraúna">Baraúna</option>
      <option value="Barcelona">Barcelona</option>
      <option value="Bento Fernandes">Bento Fernandes</option>
      <option value="Bodó">Bodó</option>
      <option value="Bom Jesus">Bom Jesus</option>
      <option value="Brejinho">Brejinho</option>
      <option value="Caiçara do Norte">Caiçara do Norte</option>
      <option value="Caiçara do Rio do Vento">Caiçara do Rio do Vento</option>
      <option value="Caicó">Caicó</option>
      <option value="Campo Redondo">Campo Redondo</option>
      <option value="Canguaretama">Canguaretama</option>
      <option value="Caraúbas">Caraúbas</option>
      <option value="Carnaúba dos Dantas">Carnaúba dos Dantas</option>
      <option value="Carnaubais">Carnaubais</option>
      <option value="Ceará-Mirim">Ceará-Mirim</option>
      <option value="Cerro Corá">Cerro Corá</option>
      <option value="Coronel Ezequiel">Coronel Ezequiel</option>
      <option value="Coronel João Pessoa">Coronel João Pessoa</option>
      <option value="Cruzeta">Cruzeta</option>
      <option value="Currais Novos">Currais Novos</option>
      <option value="Doutor Severiano">Doutor Severiano</option>
      <option value="Parnamirim">Parnamirim</option>
      <option value="Encanto">Encanto</option>
      <option value="Equador">Equador</option>
      <option value="Espírito Santo">Espírito Santo</option>
      <option value="Extremoz">Extremoz</option>
      <option value="Felipe Guerra">Felipe Guerra</option>
      <option value="Fernando Pedroza">Fernando Pedroza</option>
      <option value="Florânia">Florânia</option>
      <option value="Francisco Dantas">Francisco Dantas</option>
      <option value="Frutuoso Gomes">Frutuoso Gomes</option>
      <option value="Galinhos">Galinhos</option>
      <option value="Goianinha">Goianinha</option>
      <option value="Governador Dix-Sept Rosado">
        Governador Dix-Sept Rosado
      </option>
      <option value="Grossos">Grossos</option>
      <option value="Guamaré">Guamaré</option>
      <option value="Ielmo Marinho">Ielmo Marinho</option>
      <option value="Ipanguaçu">Ipanguaçu</option>
      <option value="Ipueira">Ipueira</option>
      <option value="Itajá">Itajá</option>
      <option value="Itaú">Itaú</option>
      <option value="Jaçanã">Jaçanã</option>
      <option value="Jandaíra">Jandaíra</option>
      <option value="Janduís">Janduís</option>
      <option value="Januário Cicco">Januário Cicco</option>
      <option value="Japi">Japi</option>
      <option value="Jardim de Angicos">Jardim de Angicos</option>
      <option value="Jardim de Piranhas">Jardim de Piranhas</option>
      <option value="Jardim do Seridó">Jardim do Seridó</option>
      <option value="João Câmara">João Câmara</option>
      <option value="João Dias">João Dias</option>
      <option value="José da Penha">José da Penha</option>
      <option value="Jucurutu">Jucurutu</option>
      <option value="Jundiá">Jundiá</option>
      <option value="Lagoa d'Anta">Lagoa d'Anta</option>
      <option value="Lagoa de Pedras">Lagoa de Pedras</option>
      <option value="Lagoa de Velhos">Lagoa de Velhos</option>
      <option value="Lagoa Nova">Lagoa Nova</option>
      <option value="Lagoa Salgada">Lagoa Salgada</option>
      <option value="Lajes">Lajes</option>
      <option value="Lajes Pintadas">Lajes Pintadas</option>
      <option value="Lucrécia">Lucrécia</option>
      <option value="Luís Gomes">Luís Gomes</option>
      <option value="Macaíba">Macaíba</option>
      <option value="Macau">Macau</option>
      <option value="Major Sales">Major Sales</option>
      <option value="Marcelino Vieira">Marcelino Vieira</option>
      <option value="Martins">Martins</option>
      <option value="Maxaranguape">Maxaranguape</option>
      <option value="Messias Targino">Messias Targino</option>
      <option value="Montanhas">Montanhas</option>
      <option value="Monte Alegre">Monte Alegre</option>
      <option value="Monte das Gameleiras">Monte das Gameleiras</option>
      <option value="Mossoró">Mossoró</option>
      <option value="Natal">Natal</option>
      <option value="Nísia Floresta">Nísia Floresta</option>
      <option value="Nova Cruz">Nova Cruz</option>
      <option value="Olho d'Água do Borges">Olho d'Água do Borges</option>
      <option value="Ouro Branco">Ouro Branco</option>
      <option value="Paraná">Paraná</option>
      <option value="Paraú">Paraú</option>
      <option value="Parazinho">Parazinho</option>
      <option value="Parelhas">Parelhas</option>
      <option value="Rio do Fogo">Rio do Fogo</option>
      <option value="Passa e Fica">Passa e Fica</option>
      <option value="Passagem">Passagem</option>
      <option value="Patu">Patu</option>
      <option value="Santa Maria">Santa Maria</option>
      <option value="Pau dos Ferros">Pau dos Ferros</option>
      <option value="Pedra Grande">Pedra Grande</option>
      <option value="Pedra Preta">Pedra Preta</option>
      <option value="Pedro Avelino">Pedro Avelino</option>
      <option value="Pedro Velho">Pedro Velho</option>
      <option value="Pendências">Pendências</option>
      <option value="Pilões">Pilões</option>
      <option value="Poço Branco">Poço Branco</option>
      <option value="Portalegre">Portalegre</option>
      <option value="Porto do Mangue">Porto do Mangue</option>
      <option value="Serra Caiada">Serra Caiada</option>
      <option value="Pureza">Pureza</option>
      <option value="Rafael Fernandes">Rafael Fernandes</option>
      <option value="Rafael Godeiro">Rafael Godeiro</option>
      <option value="Riacho da Cruz">Riacho da Cruz</option>
      <option value="Riacho de Santana">Riacho de Santana</option>
      <option value="Riachuelo">Riachuelo</option>
      <option value="Rodolfo Fernandes">Rodolfo Fernandes</option>
      <option value="Tibau">Tibau</option>
      <option value="Ruy Barbosa">Ruy Barbosa</option>
      <option value="Santa Cruz">Santa Cruz</option>
      <option value="Santana do Matos">Santana do Matos</option>
      <option value="Santana do Seridó">Santana do Seridó</option>
      <option value="Santo Antônio">Santo Antônio</option>
      <option value="São Bento do Norte">São Bento do Norte</option>
      <option value="São Bento do Trairí">São Bento do Trairí</option>
      <option value="São Fernando">São Fernando</option>
      <option value="São Francisco do Oeste">São Francisco do Oeste</option>
      <option value="São Gonçalo do Amarante">São Gonçalo do Amarante</option>
      <option value="São João do Sabugi">São João do Sabugi</option>
      <option value="São José de Mipibu">São José de Mipibu</option>
      <option value="São José do Campestre">São José do Campestre</option>
      <option value="São José do Seridó">São José do Seridó</option>
      <option value="São Miguel">São Miguel</option>
      <option value="São Miguel do Gostoso">São Miguel do Gostoso</option>
      <option value="São Paulo do Potengi">São Paulo do Potengi</option>
      <option value="São Pedro">São Pedro</option>
      <option value="São Rafael">São Rafael</option>
      <option value="São Tomé">São Tomé</option>
      <option value="São Vicente">São Vicente</option>
      <option value="Senador Elói de Souza">Senador Elói de Souza</option>
      <option value="Senador Georgino Avelino">Senador Georgino Avelino</option>
      <option value="Serra de São Bento">Serra de São Bento</option>
      <option value="Serra do Mel">Serra do Mel</option>
      <option value="Serra Negra do Norte">Serra Negra do Norte</option>
      <option value="Serrinha">Serrinha</option>
      <option value="Serrinha dos Pintos">Serrinha dos Pintos</option>
      <option value="Severiano Melo">Severiano Melo</option>
      <option value="Sítio Novo">Sítio Novo</option>
      <option value="Taboleiro Grande">Taboleiro Grande</option>
      <option value="Taipu">Taipu</option>
      <option value="Tangará">Tangará</option>
      <option value="Tenente Ananias">Tenente Ananias</option>
      <option value="Tenente Laurentino Cruz">Tenente Laurentino Cruz</option>
      <option value="Tibau do Sul">Tibau do Sul</option>
      <option value="Timbaúba dos Batistas">Timbaúba dos Batistas</option>
      <option value="Touros">Touros</option>
      <option value="Triunfo Potiguar">Triunfo Potiguar</option>
      <option value="Umarizal">Umarizal</option>
      <option value="Upanema">Upanema</option>
      <option value="Várzea">Várzea</option>
      <option value="Venha-Ver">Venha-Ver</option>
      <option value="Vera Cruz">Vera Cruz</option>
      <option value="Viçosa">Viçosa</option>
      <option value="Vila Flor">Vila Flor</option>
    </>
  );
};

export const MunicipiosDoRN = {
  2400109: "Acari",
  2400208: "Açu",
  2400307: "Afonso Bezerra",
  2400406: "Água Nova",
  2400505: "Alexandria",
  2400604: "Almino Afonso",
  2400703: "Alto do Rodrigues",
  2400802: "Angicos",
  2400901: "Antônio Martins",
  2401008: "Apodi",
  2401107: "Areia Branca",
  2401206: "Arês",
  2401305: "Campo Grande",
  2401404: "Baía Formosa",
  2401453: "Baraúna",
  2401503: "Barcelona",
  2401602: "Bento Fernandes",
  2401651: "Bodó",
  2401701: "Bom Jesus",
  2401800: "Brejinho",
  2401859: "Caiçara do Norte",
  2401909: "Caiçara do Rio do Vento",
  2402006: "Caicó",
  2402105: "Campo Redondo",
  2402204: "Canguaretama",
  2402303: "Caraúbas",
  2402402: "Carnaúba dos Dantas",
  2402501: "Carnaubais",
  2402600: "Ceará-Mirim",
  2402709: "Cerro Corá",
  2402808: "Coronel Ezequiel",
  2402907: "Coronel João Pessoa",
  2403004: "Cruzeta",
  2403103: "Currais Novos",
  2403202: "Doutor Severiano",
  2403251: "Parnamirim",
  2403301: "Encanto",
  2403400: "Equador",
  2403509: "Espírito Santo",
  2403608: "Extremoz",
  2403707: "Felipe Guerra",
  2403756: "Fernando Pedroza",
  2403806: "Florânia",
  2403905: "Francisco Dantas",
  2404002: "Frutuoso Gomes",
  2404101: "Galinhos",
  2404200: "Goianinha",
  2404309: "Governador Dix-Sept Rosado",
  2404408: "Grossos",
  2404507: "Guamaré",
  2404606: "Ielmo Marinho",
  2404705: "Ipanguaçu",
  2404804: "Ipueira",
  2404853: "Itajá",
  2404903: "Itaú",
  2405009: "Jaçanã",
  2405108: "Jandaíra",
  2405207: "Janduís",
  2405306: "Januário Cicco",
  2405405: "Japi",
  2405504: "Jardim de Angicos",
  2405603: "Jardim de Piranhas",
  2405702: "Jardim do Seridó",
  2405801: "João Câmara",
  2405900: "João Dias",
  2406007: "José da Penha",
  2406106: "Jucurutu",
  2406155: "Jundiá",
  2406205: "Lagoa d'Anta",
  2406304: "Lagoa de Pedras",
  2406403: "Lagoa de Velhos",
  2406502: "Lagoa Nova",
  2406601: "Lagoa Salgada",
  2406700: "Lajes",
  2406809: "Lajes Pintadas",
  2406908: "Lucrécia",
  2407005: "Luís Gomes",
  2407104: "Macaíba",
  2407203: "Macau",
  2407252: "Major Sales",
  2407302: "Marcelino Vieira",
  2407401: "Martins",
  2407500: "Maxaranguape",
  2407609: "Messias Targino",
  2407708: "Montanhas",
  2407807: "Monte Alegre",
  2407906: "Monte das Gameleiras",
  2408003: "Mossoró",
  2408102: "Natal",
  2408201: "Nísia Floresta",
  2408300: "Nova Cruz",
  2408409: "Olho d'Água do Borges",
  2408508: "Ouro Branco",
  2408607: "Paraná",
  2408706: "Paraú",
  2408805: "Parazinho",
  2408904: "Parelhas",
  2408953: "Rio do Fogo",
  2409100: "Passa e Fica",
  2409209: "Passagem",
  2409308: "Patu",
  2409332: "Santa Maria",
  2409407: "Pau dos Ferros",
  2409506: "Pedra Grande",
  2409605: "Pedra Preta",
  2409704: "Pedro Avelino",
  2409803: "Pedro Velho",
  2409902: "Pendências",
  2410009: "Pilões",
  2410108: "Poço Branco",
  2410207: "Portalegre",
  2410256: "Porto do Mangue",
  2410306: "Serra Caiada",
  2410405: "Pureza",
  2410504: "Rafael Fernandes",
  2410603: "Rafael Godeiro",
  2410702: "Riacho da Cruz",
  2410801: "Riacho de Santana",
  2410900: "Riachuelo",
  2411007: "Rodolfo Fernandes",
  2411056: "Tibau",
  2411106: "Ruy Barbosa",
  2411205: "Santa Cruz",
  2411403: "Santana do Matos",
  2411429: "Santana do Seridó",
  2411502: "Santo Antônio",
  2411601: "São Bento do Norte",
  2411700: "São Bento do Trairí",
  2411809: "São Fernando",
  2411908: "São Francisco do Oeste",
  2412005: "São Gonçalo do Amarante",
  2412104: "São João do Sabugi",
  2412203: "São José de Mipibu",
  2412302: "São José do Campestre",
  2412401: "São José do Seridó",
  2412500: "São Miguel",
  2412559: "São Miguel do Gostoso",
  2412609: "São Paulo do Potengi",
  2412708: "São Pedro",
  2412807: "São Rafael",
  2412906: "São Tomé",
  2413003: "São Vicente",
  2413102: "Senador Elói de Souza",
  2413201: "Senador Georgino Avelino",
  2413300: "Serra de São Bento",
  2413359: "Serra do Mel",
  2413409: "Serra Negra do Norte",
  2413508: "Serrinha",
  2413557: "Serrinha dos Pintos",
  2413607: "Severiano Melo",
  2413706: "Sítio Novo",
  2413805: "Taboleiro Grande",
  2413904: "Taipu",
  2414001: "Tangará",
  2414100: "Tenente Ananias",
  2414159: "Tenente Laurentino Cruz",
  2414209: "Tibau do Sul",
  2414308: "Timbaúba dos Batistas",
  2414407: "Touros",
  2414456: "Triunfo Potiguar",
  2414506: "Umarizal",
  2414605: "Upanema",
  2414704: "Várzea",
  2414753: "Venha-Ver",
  2414803: "Vera Cruz",
  2414902: "Viçosa",
  2415008: "Vila Flor",
};
