import React, {useContext, useEffect, useState, useCallback} from "react";
import { Link } from "react-router-dom";

import { Button, Col, Container, Form, Row,Toast } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { RiDeleteBin2Fill, RiPencilFill} from "react-icons/ri";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";

import { useSelector } from "react-redux";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router";
import { toInteger } from "lodash";

const validationSchema = yup.object().shape({
  nome: yup.string().matches(""),
  cpf: yup.string().matches("[0-9]{11}", "Somente números. 11 dígitos."),
});

const initialValues = {
  nome: " ",
  cpf: " ",
};

const initialValuesAndamento = {
  idTermo:0,
  idAndamento:0,
  motivo:"",
};





const ListaTermosAcordos = () => {
  const motivos=[3,4]
  const { request:requesttermos, data:datatermo } = useApiClient();
  const { request: requestAndamento, data: dataAndamento, errors:errorsAndamento, loading:loadingAndamento, requestId:requestIdAndamento  } = useApiClient();

  const { request, data, errors, loading, requestId } = useApiClient();
 

  const auth = useSelector((state) => state.auth);
  const [termosAcordos, setTermosAcordos] = useState();
  const [show, setShow] = useState(false);

 useEffect(() => {
  requesttermos(apiRoutes.main.termosAcordos.listarTermosAcordos);
}, [requesttermos]);
useEffect(() => {
  requestAndamento(apiRoutes.main.servidor.listarAndamento);
}, [requestAndamento]);
useEffect(() => {
  if (dataAndamento) setTermosAcordos(dataAndamento);
}, [dataAndamento]);
useEffect(() => {
  if (datatermo) setTermosAcordos(datatermo);
}, [datatermo]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  const handleSubmit = useCallback(
    (values) => {
      requestAndamento(apiRoutes.main.servidor.updateAndamento, values);
    },
    [requestAndamento]
  );
  const formikAndamento = useFormik({
    initialValues: initialValuesAndamento,
    onSubmit: handleSubmit,
  });
  // useEffect(() => {
  //   request(apiRoutes.main.coordenador.listarServidor, {
  //     routeParams: { idsecretaria },
  //   });
  // }, [request, idsecretaria]);
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    if ((data || errors) && !loading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }

    if (data && !loading && !errors) {
      setShow(true);
      setTimeout(window.location.reload(), 80000);
    }

    if (requestId === "load" && data && !formik.values.loaded) {
      formik.setValues({ ...data.result, loaded: true });
    }

    if (errors && !loading) formik.setErrors({ ...formik.errors, ...errors });
  }, [formik, data, errors, requestId, loading]);
  


  useEffect(() => {
    if ((dataAndamento || errorsAndamento) && !loadingAndamento && formikAndamento.isSubmitting) {
      formikAndamento.setSubmitting(false);
    }

    if (requestIdAndamento === "load" && dataAndamento && !formikAndamento.values.loaded) {
      formikAndamento.setValues({ ...dataAndamento.result, loaded: true });
    }

    if (errorsAndamento && !loadingAndamento) formikAndamento.setErrors({ ...formikAndamento.errors, ...errorsAndamento });
  }, [formikAndamento, dataAndamento, errorsAndamento, requestIdAndamento, loadingAndamento]);
  

  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");
  const readOnlyAndamento = loadingAndamento || !!((dataAndamento || {}).fg_somente_leitura === "S");

  return (
    <>
      



      <Container className="conteudo-pagina mt-5 ">
        <Row>
          <Col>
            <h2 className="mt-4">Termos e Acordos</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>Aqui são listados todos os termos e acordos.</p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput formik={formik} field="nome" label="Nome" />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    maxLength={11}
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link" className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Modalidade</th>
                  <th>Entidade</th>
                  <th>Andamento</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {(datatermo || {}).length &&
                  datatermo.map((itemData) => (
          
                    <tr
                    key={itemData.id_termo} style={{ cursor: "pointer" }} >
                      <td  > {itemData.id_termo}</td>
                      <td> {itemData.nome}</td>
                      <td> {itemData.modalidade}</td>
                      <td> {itemData.entidade}</td>
                      <td >{itemData.andamento_nome}  </td>
                     
                      <td className="d-flex flex-row justify-content-around">
                      <Link
                      className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                      to={`/update/termos-acordos/${itemData.id_termo}`}
                    ><RiPencilFill size={15}/></Link>
                      
                      <Link className="btn btn-outline btn-outline-primary btn-sm rounded-pill">
                      <RiDeleteBin2Fill
                      
                          size={15}
                          color="red"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                "Você deseja realmente excluir esse termo?"
                              )
                            ) {
                              var idTermo = itemData.id_termo;
                              request(apiRoutes.main.servidor.removerTermo, {
                                routeParams: { idTermo },
                              });
                            }
                          }}
                        />

                      </Link>
                        
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListaTermosAcordos;
