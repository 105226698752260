import React, { useEffect } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router";
import { FormatDate } from "../../../components/UI/Format/Format";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";

const PropostaLista = () => {
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();

  useEffect(() => {
    request(apiRoutes.main.propostas.listarDoUsuario);
  }, [request]);


  return (
    <>
      <Container className="conteudo-pagina">
        <Row>
          <Col>
            <h2 className="mt-4">Minhas Propostas</h2>
            {errors && (
              <Alert variant="danger">
                Desculpe, ocorreu um erro durante a recuperação das propostas.
                Tente novamente mais tarde.
              </Alert>
            )}
            {loading && (
              <span>
                <Spinner animation="border" />
              </span>
            )}
          </Col>
        </Row>
        {!errors && !loading && (
          <>
            <Row>
              <Col>
                <p>
                  Aqui são listadas suas propostas, antes de submeter você
                  poderá editá-las
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Protocolo</th>
                      <th>Data</th>
                      <th>Título</th>
                      <th>Área</th>
                      <th>Situação</th>
                      {/* <th style={{ textAlign: 'center' }}>Arquivar</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {(data || {}).length &&
                      data.map((item) => (
                        <tr
                          key={item.id_proposta}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/minha-area/proposta/${item.id_proposta}`
                            );
                          }}
                        >
                          <td>{item.tx_protocolo}</td>
                          <td>{FormatDate(item.tm_inclusao)}</td>
                          <td>{item.tx_titulo}</td>
                          <td>{item.tx_area}</td>
                          <td>{item.tx_status}</td>
                          {/* <td style={{ textAlign: 'center' }}>
                        <RiDeleteBin5Fill />
                      </td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default PropostaLista;
