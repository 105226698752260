import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ChamamentosPublicos from "./pages/ChamamentosPublicos";
import Contato from "./pages/Contato";
import Home from "./pages/Home";
import Login from "./pages/Login";
import LoginGov from "./pages/LoginGov";
import PaginaEnvioLinkCadastroUsuario from "./pages/PaginaEnvioLinkCadastroUsuario";
import PaginaCadastroUsuario from "./pages/PaginaCadastroUsuario";
import PaginaEnvioLinkRedefinirSenha from "./pages/PaginaEnvioLinkRedefinirSenha";
import RedefinirSenhaUsuario from "./pages/RedefinirSenhaUsuario";
import ManifestacoesInteresseSociais from "./pages/ManifestacoesInteresseSociais";
import MinhaArea from "./pages/MinhaArea";
import MisConsultaPublica from "./pages/MisConsultaPublica";
import NotFound from "./pages/NotFound";
import PaginaLegislacao from "./pages/PaginaDeLegislacao";
import PaginaModelo from "./pages/PaginaModelo";
import PublicacoesLista from "./pages/PublicacoesLista";
import Sobre from "./pages/Sobre";
import FazerPropostaMis from "./pages/FazerPropostaMis";
import { useSelector } from "react-redux";
import MinhaAreaLayout from "./pages/MinhaAreaLayout";
import MinhaAreaCoordenador from "./pages/Coordenador/MinhaAreaCoordenador/MinhaAreaCoordenador";
import FormCriacaoServidor from "./pages/Coordenador/FormCriacaoServidor/FormCriacaoServidor";
import CredenciamentoOSC from "./pages/Coordenador/CredenciamentoOsc/PaginaCredenciamentoOsc";
import FormCriacaoCoordenador from "./pages/Admin/FormCriacaoCoordenador/FormCriacaoCoordenador";
import MinhaAreaServidor from "./pages/Servidor/MinhaArea/MinhaAreaServidor";
import MinhaAreaAdmin from "./pages/Admin/MinhaAreaAdmin/MinhaAreaAdmin";
import TermosEAcordos from "./pages/TermosEAcordos/TermosEAcordos";
import PaginaFaq from "./pages/PaginaFaq";
import OscCredenciadas from "./pages/Osc/OscCredenciadas/OscCredenciadasLista";
import PaginaCadastroOsc from "./pages/Osc/PaginaCadastroOsc";
import AtualizarDadosUserGov from "./pages/AtualizarDadosUserGov/AtualizarDadosUserGov";
import MinhaAreaGestor from "./pages/Gestor/MinhaAreaGestor";
import LançamentoTermosAcordos from "./pages/Servidor/LançamentoTermosAcordos/LançamentoTermosAcordos";
import LançamentoChamamentoPublico from "./pages/Servidor/LançamentoChamamentoPublico/LançamentoChamamentoPublico";
import AtualizarDadosOsc from "./pages/Gestor/AtualizarDadosOsc";
import VincularProponeteOSC from "./pages/Gestor/VincularProponeteOSC";
import UpdateTermosAcordos from "./pages/Servidor/UpdateTermosAcordos/UpdateTermosAcordos";
import PaginaDeManuntencao from  "./pages/PaginaDeManuntencao";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) =>  JSON.stringify(sessionStorage.getItem("user")).auth|| state.auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  return (
    <Router>
      <header>
        <Header />
      </header>
      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/paginamodelo" component={PaginaModelo} />
          <PrivateRoute
            path="/publicacoes-lista"
            component={PublicacoesLista}
          />
          <Route path="/sobre" component={Sobre} />
          <Route path="/contato" component={Contato} />
          <Route path="/legislacao" component={PaginaLegislacao} />
          <Route
            path="/publicacoes/chamamentos"
            component={ChamamentosPublicos}
          />
          <Route
            path="/publicacoes/interesse-social"
            component={ManifestacoesInteresseSociais}
          />
          
          <Route path="/mis-consulta-publica" component={MisConsultaPublica} />

          <Route path="/login" component={Login} />
          <Route path="/gov/login" component={LoginGov} />

          <Route
            path="/interesse-social/fazer-proposta"
            component={FazerPropostaMis}
          />
          <Route
            path="/link-cadastro"
            component={PaginaEnvioLinkCadastroUsuario}
          />
          <Route
            path="/novo-usuario/:token"
            component={PaginaCadastroUsuario}
          />
          <Route
            path="/link-redefinir-senha"
            component={PaginaEnvioLinkRedefinirSenha}
          />
          <Route
            path="/redefinir-senha/:token"
            component={RedefinirSenhaUsuario}
        />
          <Route
            path="/parcerias/termos-e-acordos"
            component={TermosEAcordos}
          />
          <Route path="/perguntas-frequentes" component={PaginaFaq} />

     
          <Route path="/oscs-credenciadas" component={OscCredenciadas} />
          <Route path="/cadastro-oscs" component={PaginaCadastroOsc} />
          <PrivateRoute path="/minha-area" exact component={MinhaArea} />
          <PrivateRoute
            path="/minha-area-coordenador"
            exact
            component={MinhaAreaCoordenador}
          />
         <PrivateRoute path="/minha-area-admin" exact component={MinhaAreaAdmin} />
          <Route path="/novo-servidor" exact component={FormCriacaoServidor} />
          <Route
            path="/cadastro-coordenador"
            exact
            component={FormCriacaoCoordenador}
          />
          <Route path="/minha-area-gestor" exact component={MinhaAreaGestor} />
          <PrivateRoute 
            path="/upload/termos-acordos"
            exact
            component={LançamentoTermosAcordos}
          />
         <Route
            path="/update/termos-acordos/:id"
            exact
            component={UpdateTermosAcordos}
          />
          <Route
            path="/upload/chamamento"
            exact
            component={LançamentoChamamentoPublico}
          />

          <PrivateRoute
            path="/gestor/update/osc"
            exact
            component={AtualizarDadosOsc}
          />
        <PrivateRoute
            path="/gestor/vincular/proponente"
            exact
            component={VincularProponeteOSC}/>

          <PrivateRoute path="/minha-area" component={MinhaAreaLayout} />
          <PrivateRoute
            path="/minha-area-servidor"
            component={MinhaAreaServidor}
            />

          <PrivateRoute
            path="/update/user/gov"
            component={AtualizarDadosUserGov}
          />

         <Route
            path="/coordenador/credenciamento/osc/:idCredenciamento"
            component={CredenciamentoOSC}
          />

          <Route
            path="/osc/manuntencao"
            component={PaginaDeManuntencao}
          />
          {/**  <Route path='/sobre' component={Sobre} LançamentoTermosAcordos />*/}
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};

export default App;

