const TextInput = (props) => {
  return (
    <>
      <input
        className="form-control"
        id={props.field}
        name={props.field}
        maxLength={props.maxLength}
        type={props.type || "text"}
        {...props}
        disabled={props.formik.isSubmitting}
        value={props.formik.values[props.field]}
        onChange={props.formik.handleChange}
        // isInvalid={ //TODO aguardando definição da class por Henrique
        //   props.formik.touched[props.field] && props.formik.errors[props.field]
        // }
      />
      {props.formik.touched[props.field] &&
        props.formik.errors[props.field] && (
          <span className="invalid">{props.formik.errors[props.field]}</span>
        )}
    </>
  );
};

export default TextInput;
