import { Spinner } from "react-bootstrap";

const SpinnerLabel = (props) => {
  return props.loading ? (
    <>
      <Spinner animation="border" size="sm" />
      <span>&nbsp;</span>&nbsp;
      {props.label}
    </>
  ) : (
    props.label
  );
};

export default SpinnerLabel;
