import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../../../components/UI/Formik/SelectInput/SelectInput.options";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../../components/UI/ViaCepLoader/ViaCepLoader";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { authActions } from "../../../redux/auth";

const validationSchema = yup.object().shape({
  nome: yup.string().required("Informe seu nome completo "),
  cpf: yup
    .string()
    .matches("[0-9]{11}", "Somente números com 11 dígitos ")
    .required("Somente números com 8 dígitos "),
  logradouro: yup.string().required("Informe o logradouro de seu endereço "),
  numero: yup.string().required("Informe o número de seu endereço "),
  cidade: yup.string().required("Informe a cidade de seu endereço "),
  bairro: yup.string().required("Informe o bairro de seu endereço "),
  cep: yup
    .string()
    .matches("[0-9]{8}", "Somente números com 8 dígitos ")
    .required("Somente números com 8 dígitos "),
  telefone1: yup
    .string()
    .matches("[0-9]{10}", "Informe DDD e telefone com no mínimo 10 dígitos ")
    .required("Informe DDD e telefone com no mínimo 10 dígitos "),
  telefone2: yup
    .string()
    .matches("[0-9]{10}", "Informe DDD e telefone com no mínimo 10 dígitos "),
});

const initialValues = {
  id: null,
  nome: "",
  cpf: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "",
  cep: "",
  telefone1: "",
  telefone2: "",
};

const UsuarioDados = () => {
  const history = useHistory();
  const numeroInputRef = useRef(null);
  const { setBackgroundClassList } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const { request, data, errors, loading, requestId, requestExtra } =
    useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      actions.setStatus(null);
      request(apiRoutes.main.usuario.salvar, values, {
        requestId: "salvar",
        requestExtra: { nome: values.nome },
      });
    },
  });

  const { setValues, setSubmitting, setStatus } = formik;

  useEffect(() => {
    request(apiRoutes.main.usuario.usuarioLogado, null, {
      requestId: "carregar",
    });
  }, [request]);

  useEffect(() => {
    if (data && requestId === "carregar") setValues(data.value);
    if (requestId === "salvar") {
      setSubmitting(false);
      if (data) {
        dispatch(authActions.updateUser(requestExtra));
        history.goBack();
      }
      if (errors) setStatus("Verifique as informações e tente novamente. ");
    }
  }, [
    data,
    errors,
    requestId,
    requestExtra,
    history,
    dispatch,
    setValues,
    setSubmitting,
    setStatus,
  ]);

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Dados do Usuário</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h2 className="my-3">Dados Pessoais</h2>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome:"
                    placeholder="Informe o seu nome completo"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    placeholder="Informe o seu CPF"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone1"
                    label="Telefone Principal:"
                    placeholder="Informe o número de seu principal telefone"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone2"
                    label="Outro Telefone:"
                    placeholder="Informe o número de um telefone secundário"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="3" md="3" sm="11" xs="11">
                  <TextInput
                    formik={formik}
                    field="cep"
                    label="CEP:"
                    readOnly={readOnly}
                    maxLength={8}
                  />
                </Col>
                {!readOnly && (
                  <Col lg="3" md="3" sm="1" xs="1" style={{ alignSelf: "end" }}>
                    <ViaCepLoader
                      disabled={!formik.values.cep || !!formik.errors.cep}
                      cep={formik.values.cep}
                      onFound={(data) => {
                        const { logradouro, bairro, ibge } = data;
                        formik.setValues({
                          ...formik.values,
                          logradouro: logradouro,
                          bairro: bairro,
                          cidade: MunicipiosDoRN[ibge],
                        });
                        numeroInputRef.current.focus();
                      }}
                    />
                  </Col>
                )}

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouro"
                    label="Logradouro:"
                    maxLength={250}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numero"
                    label="Número:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="complemento"
                    label="Complemento:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="5" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="bairro"
                    label="Bairro:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Cidade:"
                    readOnly={readOnly}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="1" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="Uf"
                    label="UF:"
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button className="mt-3" onClick={() => history.goBack()}>
                    Voltar
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={formik.handleSubmit}
                  >
                    <SpinnerLabel
                      loading={formik.isSubmitting}
                      label="Salvar"
                    />
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsuarioDados;
