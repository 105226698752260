import { Form, useFormik } from "formik";
import React, { useContext, useEffect, useCallback } from "react";
import { Col, Container, Row, Button,Toast } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import SelectFileInputGeneral from "../../../components/UI/SelectFileInput/SelectFileInputGeneral";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useState } from "react";

const validationSchema = yup.object().shape({
  titulo: yup.string().required("Informe o titulo do chamamento."),
  objeto: yup.string().required("Informe uma descrição resumida do objeto."),
  area: yup.string().required("Informe uma área de acordo com o chamamento."),

  });

  const initialValues = {
    titulo: "",
    objeto: "",
    autor: "",
    area: "",
    edital: "",
    editalNome : "",
  };

  


const LançamentoChamamentoPublico = () => {
    const { setBackgroundClassList } = useContext(AppContext);
    const { request:requestUpload, data:dataUpload, errors:errorsUpload, loading:loadingUpload, requestId:requestIdUpload } = useApiClient();
    const history = useHistory();
    const [show, setShow] = useState(false);

    const {id} = useParams();

  
    useEffect(() => {
        setBackgroundClassList(["background-1"]);
      });

      const handleSubmit = useCallback(
        (values) => {
          requestUpload(apiRoutes.main.chamamentoPublico.uploadChamamentoPublico, values);

        },
        [requestUpload]
      );
    
      const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: handleSubmit,
      });
    
      const handleArquivo = (uploadPayload) => {
        formik.setValues((curr) => ({
          ...curr,
          edital: uploadPayload.arquivo,
          editalNome: uploadPayload.arquivoNome,
        }));
      };
    
      useEffect(() => {
        if ((dataUpload || errorsUpload) && !loadingUpload && formik.isSubmitting) {
          formik.setSubmitting(false);
        }
    
        if (dataUpload && !loadingUpload && !errorsUpload) {
          setShow(true);
          setTimeout(window.location.reload(), 80000);
        }
    
        if (requestIdUpload === "load" && dataUpload && !formik.values.loaded) {
          formik.setValues({ ...dataUpload.result, loaded: true });
        }
    
        if (errorsUpload && !loadingUpload) formik.setErrors({ ...formik.errors, ...errorsUpload });
      }, [formik, dataUpload, errorsUpload, requestIdUpload, loadingUpload]);
    
      const readOnly = loadingUpload || !!((dataUpload || {}).fg_somente_leitura === "S");
    
    return(
        <>
        <Container id="cabecalho-pagina" fluid>
            <Row>
            <Col className="d-flex flex-column text-center mt-5">
                <h1 className="text-white text-shadow mt-5">Lançamento dos chamentos públicos</h1>
            </Col>
            </Row>
        </Container>

        <Container id="conteudo-pagina-interna">
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: "relative",
            minHeight: "100px",
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={80000}
            autohide
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast.Header>
              <strong className="me-auto">Sucesso</strong>
            </Toast.Header>
            <Toast.Body>Termo salvo com sucesso!</Toast.Body>
          </Toast>
        </div>

            <Row className="d-flex g-4">
              <Col md="12" sm="12" xs="12">
                <h2 className="my-3">Dados do chamamento</h2>
              </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="titulo" label="Titulo:" readOnly={readOnly} />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="objeto" label="Objeto da parceria:" readOnly={readOnly} />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="autor" label="Autor:" readOnly={readOnly} />
                </Col>
                <Col md="12" sm="12" xs="12">
                <SelectInputCached
                    formik={formik}
                    field="area"
                    label="Área de Atuação:"
                    cacheKey="areas"
                    readOnly={readOnly}
                    allowEmpty
                  />
                </Col>
                <Col  className = "mt-5" lg="4" md="12" sm="12" xs="12">
                <SelectFileInputGeneral
                  disabled={loadingUpload}
                  show={!readOnly}
                  field="edital"
                  handleArquivo={handleArquivo}
                />
                </Col>           
            </Row>

            <Row>
                <Col className="d-flex justify-content-center mt-5">
                <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={() => formik.handleSubmit()}
                >
                    <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
                </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="mt-3"
                    onClick={() => history.goBack()}
                  >
                    Voltar
                  </Button>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default LançamentoChamamentoPublico;
