import { useFormik } from "formik";
import React, { useContext, useEffect, useRef,useState } from "react";
import { Col, Container, Row, Alert,  Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";



const validationSchema = yup.object().shape({
  tx_status: yup.string().required("Informe um status para a proposta."),
  tx_justificativa: yup.string().required("Informe uma justificativa de acordo com o andamento escolhido."),
  
 
});

const initialValues = {
  id_proposta: null,
  tx_titulo: "",
  tx_descricao: "",
  tx_area: "",
  tx_interesse: "",
  tx_diagnostico: "",
  tx_viabilidade: "",
  tx_beneficios: "",
  tx_status :"",
  tx_justificativa: ""

};

const ModalProposta = (props) => {
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
    },
  });

  const { setValues } = formik;
  
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data,  setValues]);

 
  return (
    <>
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Titulo da proposta
            </Modal.Title>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex g-4">
                <Col md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados da proposta</h3>
                </Col>
                <Form >
                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_descricao"
                      label="Descrição Resumida da Proposta"
                      disabled="disabled"
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextInput
                      formik={formik}
                      field="tx_area"
                      label="Área de Atuação"
                      
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_interesse"
                      label="Indicação do interesse público envolvido"
                      
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_diagnostico"
                      label="Diagnóstico da realidade que se quer modificar, aprimorar
                        ou desenvolver"
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_viabilidade"
                      label="Indicação da viablidade"
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_beneficios"
                      label="Indicação dos beneficios"
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12"  className="mt-4">
                    <SelectInput
                      formik={formik}
                      field="tx_status"
                      label="Andamento:"
                    >
                      <option value="aceita">Aceitar </option>
                      <option value="rejeitada">Rejeitar </option>
                      
                    </SelectInput>
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
                    <TextareaInput
                      formik={formik}
                      field="tx_justificativa"
                      label="Justificativa do andamento:"
                    />
                  </Col>
                </Form>
              </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Salvar</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ModalProposta;
