import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import ModalProposta from "../ModalFormPropostaAndamento/ModalProposta";
import * as yup from "yup";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory } from "react-router";
import { FormatDate } from "../../../components/UI/Format/Format";




const validationSchema = yup.object().shape({
  protocolo: yup.string().matches(/^\d{1,}\/\d{4}/g, "Formato inválido."),
  ano: yup.string().matches("[0-9]{4}", "Somente números. 4 dígitos."),
});

const initialValues = {
  protocolo: "",
  ano: "",
  idArea: "",
};



const ListaPropostasRecebidas = () => {
    const [modalShow, setModalShow] = useState(false);
    const [item, setItem ] = useState();
    const { request, data, errors, loading } = useApiClient();
    const history = useHistory();

  useEffect(() => {
    request(apiRoutes.main.propostas.listarDoUsuario);
  }, [request]);

  

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
       
      },
    });


  return (
    <>
      <Container className="conteudo-pagina ">
        <Row>
          <Col>
            <h2 className="mt-4">Propostas Recebidas</h2>
         
          </Col>
        </Row>
       
         
            <Row>
              <Col>
                <p>
                  Aqui são listadas as propostas recebidas, você pode clicar na proposta para fazer a análise, e de acordo com sua análise aceitar ou rejeitar a proposta.
                </p>
              </Col>
              
            </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="protocolo"
                    label="Protocolo (Número/Ano):"
                    
                  />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="ano"
                    label="Ano:"
                    maxLength={4}
                    
                  />
                </Col>
                <Col lg="6" md="6" sm="6" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="idArea"
                    label="Área de Atuação"
                    cacheKey="areas"
                    allowEmpty
                  />
                </Col>


                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link"className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
          {loading && (
              <span>
                <Spinner animation="border" />
              </span>
            )}
            <Table striped bordered hover className="mt-4">
              
                <thead>
                  <tr>
                    <th>Protocolo</th>
                    <th>Data</th>
                    <th>Título</th>
                    <th>Área</th>
                    <th>Andamento</th>
                  </tr>
                  </thead>
                  <tbody>
                  {(data || {}).length &&
                      data.map((itemData) => (
                    <tr  
                    key={itemData.id_proposta}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                            history.push(
                              `/minha-area/proposta/${itemData.id_proposta}`
                            );
                      
                    }}>
                      <td> {itemData.tx_protocolo}</td>
                      <td> {FormatDate(itemData.tm_inclusao)}</td>
                      <td> {itemData.tx_titulo} </td>
                      <td> {itemData.tx_area} </td>
                      <td> {itemData.tx_status}</td>
                    </tr>))}
                    
                    </tbody>
                  </Table>
              </Col>
            </Row>
            <ModalProposta show={modalShow} data={item} onHide={() => setModalShow(false)}/>
      </Container>
    </>
  );
};

export default ListaPropostasRecebidas;
