import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Accordion,
} from "react-bootstrap";
import SelectInputCached from "../components/UI/Formik/SelectInput/SelectInput.cached";
import { AppContext } from "../context/AppContext";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import ChamamentoLista from "./Chamamento/ChamamentoLista/ChamamentoLista";

const initialValues = {
  idOrgao: "",
  chamamento: "S",
};

const ChamamentosPublicos = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const [chamamento, setChamamento] = useState();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.chamamentoPublico.listarChamamento);
    },
  });

  useEffect(() => {
    request(apiRoutes.main.chamamentoPublico.listarChamamento);
  }, [request]);

  useEffect(() => {
    if (data) setChamamento(data);
  }, [data]);

  useEffect(() => {
    setBackgroundClassList(["background-2"]);
  });

  useEffect(() => {
    if ((data || errors) && !loading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }

    

    if (request === "load" && data && !formik.values.loaded) {
      formik.setValues({ ...data.result, loaded: true });
    }

    if (errors && !loading) formik.setErrors({ ...formik.errors, ...errors });
  }, [formik, data, errors, request, loading]);
  
  const readOnly = loading;

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Chamamentos Públicos
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Row>
              <Col>
                <Accordion className="my-5">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        className="w-100 text-left"
                      >
                        O que é um Chamamento Público?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          O chamamento público é a denominação do instrumento de
                          seleção da Organização da Sociedade Civil (OSC) que
                          irá celebrar a parceria com a Administração Pública.
                          Tal procedimento irá se pautar, primordialmente, em
                          garantir uma igualdade competitiva entre as OSC, ao
                          mesmo tempo em que averigua a melhor proposta. Devem
                          ser observados critérios claros e objetivos, a serem
                          definidos em edital, observando os princípios
                          norteadores da Administração Pública, tais como a
                          isonomia, moralidade e impessoalidade, por exemplo.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        className="w-100 text-left"
                      >
                        Quando não será necessário haver Chamamento Público?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          A Lei 13.019/2014 estabelece algumas hipóteses nas
                          quais o chamamento público poderá ser dispensado.
                          Dentre elas, haverá três grandes hipóteses, cada uma
                          com as suas especificidades. As hipóteses serão de não
                          aplicabilidade, dispensa e inexigibilidade. A não
                          aplicabilidade do chamamento público é aplicável nas
                          situações de: (I) Termos de Fomento e Termos de
                          Colaboração envolvendo o repasse de recursos de
                          emendas parlamentares às leis orçamentárias anuais; e
                          (II) Acordos de Cooperação que não envolver o
                          compartilhamento de bem patrimoniado.
                        </p>
                        <p className="text-justify ">
                          A dispensa do chamamento público ocorre nas seguintes
                          situações: (I) no caso de urgência decorrente de
                          paralisação ou iminência de paralisação das atividades
                          de relevante interesse público; (II) nos casos de
                          guerra, calamidade pública, grave perturbação da ordem
                          pública ou ameaça à paz social ; (III) nos casos de
                          programas de proteção a pessoas ameaçadas ou em
                          situação que possa comprometer sua segurança; e (IV)
                          no caso de atividades voltadas ou vinculadas a
                          serviços de saúde, educação e assistência social,
                          desde que a OSC da parceria esteja previamente
                          credenciadas pelo órgão gestor.
                        </p>

                        <p className="text-justify ">
                          A inexigibilidade de chamamento público ocorre nas
                          seguintes situações: (I) objeto da parceria é
                          singular; e (II) quando as metas só puderem ser
                          atingidas por uma OSC específica, especialmente quando
                          a OSC beneficiada estiver identificada em acordo
                          internacional ou em lei (inclusive subvenção social).
                        </p>
                        <p className="text-justify ">
                          Para os casos de dispensa e inexigibilidade de
                          chamamento público, a ausência da sua realização deve
                          ser justificada. Tal explicação pode ser questionada
                          por qualquer OSC ou interessado.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
            <Form>
              <Row className="bg-light p-2 mt-5 mt-lg-3 mt-md-3">
                <Col md="7" sm="6" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="idOrgao"
                    label="Buscar por Secretaria ou Autarquia"
                    cacheKey="orgaos"
                    readOnly={readOnly}
                    allowEmpty
                  />
                </Col>
                <Col md="3" sm="6" xs="12">
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label className="me-3">Status:</Form.Label>
                      <br />
                      <Form.Check
                        className="me-2"
                        inline
                        type="radio"
                        name="radio-perfil"
                        id="radio-dispensado"
                        checked={formik.values.chamamento === "S"}
                        label="Lançados"
                        onChange={() => formik.setFieldValue("chamamento", "S")}
                        readOnly={readOnly}
                      />
                      <Form.Check
                        className="me-2"
                        inline
                        type="radio"
                        name="radio-perfil"
                        id="radio-pf"
                        checked={formik.values.chamamento === "N"}
                        label="Dispensado/inexigíveis"
                        onChange={() => formik.setFieldValue("chamamento", "N")}
                        readOnly={readOnly}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md="2" sm="6" xs="12">
                  <Button
                    variant="primary"
                    className="p-2 py-2 mt-3 w-100"
                    role="button"
                    onClick={formik.handleSubmit}
                  >
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </Form>
            <ChamamentoLista data={data} errors={errors} loading={loading} />
          </Col>
        </Row>
      </Container>
      
    </>
  );
};
export default ChamamentosPublicos;
